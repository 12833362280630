import React,{useState, useEffect} from 'react';
import '../ContentCareNavigator.css';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import DataTableComponent from './CapacityManagementTable';
import CareNavigatorListTable from './CareNavigatorListTable';
import TransferListTable from './TransferListTable';
import { updateWebexSetting,getCareNavigators, careNavigatorList } from '../../../services/medengage/Service';
import { useNavigate, useLocation } from 'react-router-dom';
import { CustomLoader } from '../../utils/CommonLibrary';
import CareNavigatorAppointmentsTable from '../CareNavigatorAppointments/CareNavigatorAppointmentsTable';

function ContentCareNavigator() {

    const navigate = useNavigate();
    const location = useLocation();
    const [data, setData] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [dataTransfer, setDataTransfer] = useState(false);


    useEffect(() => {
        const performFilterEffect = async () => {
          setLoading(true);
          fetchAllDataService();
        };
    
        performFilterEffect();
    }, [selectedIndex]);


    
    const fetchAllDataService = async () => {
        setLoading(true);
        fetchData();
        fetchDataTransfer();
    };

    const fetchData = async () => {
        await getCareNavigators()
            .then((response) => {
                setData(response.data.data.users);
                setLoading(false);
            });
    };

    const fetchDataTransfer = async () => {
        await careNavigatorList()
            .then((response) => {
                setDataTransfer(response.data.data.users);
                setLoading(false);
            });
    };

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const tab = params.get('tab');
        setSelectedIndex(tab ? parseInt(tab, 10) : 0);
    }, [location.search]);

    const handleTabSelect = (index) => {
        setSelectedIndex(index);
        navigate(`${location.pathname}?tab=${index}`, { replace: true });
    };

    
  return (
    <>
        <section className='ContentCareNavigator'>
            <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md">
                    <h1 className="main-title">CareNavigator Management</h1>
                </div>
                <div className="col-md text-end">
                    
                </div>
            </div>

            <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/f1rhk/yh4of8cegs.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-3">Empowers admins to efficiently manage CareNavigator accounts, ensuring accurate and up-to-date access control.</p>
                        </div>
                    </div>
                </div>
            
            <div className="container-fluid">
                <div className="row align-items-center pb-3">
                    <div className='comntent-CareNavigator mb-3'>
                        <div className="row">
                            <div className="col-md-12">
                                <Tabs selectedIndex={selectedIndex} onSelect={index => handleTabSelect(index)}>
                                    <TabList>
                                        <Tab>Account Management</Tab>
                                        <Tab>Capacity & Assignment</Tab>
                                        <Tab>Appointments</Tab>
                                        <Tab>Transfer List</Tab>
                                    </TabList>
                                    
                                    <TabPanel>
                                        <div className="table-responsive CareNavigatorListTable">
                                            {data && <CareNavigatorListTable data={data} setData={setData} updateWebexSetting={updateWebexSetting} /> }
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="table-responsive CapacityAssignmentTable">
                                            {data && <DataTableComponent data={data} reload={fetchData} key={`dataTable-${selectedIndex}-${Date.now()}`} /> }
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="table-responsive CareNavigatorAppointmentsTable">
                                            <CareNavigatorAppointmentsTable />
                                        </div>
                                    </TabPanel>
                                    <TabPanel>
                                        <div className="table-responsive">
                                            {dataTransfer && <TransferListTable data={dataTransfer} reload={fetchDataTransfer} key={`dataTable-${selectedIndex}-${Date.now()}`} /> }
                                        </div>
                                    </TabPanel>
                                </Tabs>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {loading && <CustomLoader />}
        </section>



        <div className="modal fade" id="NotificationModal" tabIndex="1" aria-labelledby="NotificationModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <div className="modal-content">
                        <div className="modal-header text-center">
                            <h4 className="modal-subtitle px-3">{100} Members will be transferred back to Care Navigator 1 tomorrow at 1 PM.</h4>
                                <h1 className="modal-title mt-3">Temporary Transfer</h1>
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/x1z8f/vsw4zufe51.png" style={{height:"52px"}} alt="" />
                        </div>
                        <div className="modal-body text-center mb-2">
                        <button type="button" className="btn btn-secondary ok-btn me-2" data-bs-dismiss="modal">OK</button>
                        <button type="button" className="btn btn-primary Modify-btn">Modify</button>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
}

export default ContentCareNavigator;
