import React from 'react'
import { Outlet, useNavigate } from 'react-router-dom'; 
import ScrollToTop from '../../Observations/components/ScrollToTop/ScrollToTop';


const ObservationLayout = () => {
  return (
    <>
        <ScrollToTop />
        <Outlet />
    </>
  )
}

export default ObservationLayout; 