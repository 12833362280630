import React from 'react'

const PageTip = ({ imgSrc, imgAlt="image", text }) => {
    return (
        <div className='reports-programs'>
            <div className=" row align-items-center">
                <div className="col-md-auto">
                    <img src={imgSrc} alt={imgAlt} style={{ height: "140px" }} />
                </div>
                <div className="col-md">
                    <p className="mb-0">{text}</p>
                </div>
            </div>
        </div>
    )
}

export default PageTip;
