import React from 'react';

const AddUser = ({
    // Form data and handlers
    formData,
    handleChange,
    handleEmailChange,
    handleSubmit,
    handleClear,
    
    // States
    errors,
    loading,
    isLoading,
    successMessage,
    errorMessage,
    
    // Email suggestions
    suggestions,
    handleSuggestionClick,
    
    // Options
    roleOptions = [],
    
    // Add new props for edit mode
    isEditMode = false,
    adminStatus = '',
    
    // Action type (optional)
    action = "Add",
    isDisabled = true,
}) => {
    return (
        <div className="comntent-UserManagement p-3 mb-3">
            <div className="row">
                <div className="col-md-7 px-4">
                    {successMessage && <div className="text-success">{successMessage}</div>}
                    {errorMessage && <div className="text-danger">{errorMessage}</div>}
                    
                    <form onSubmit={handleSubmit}>
                        <div className="row">
                            <div className="col-md-12 mb-3">
                                <label htmlFor="emailId" className="form-label">
                                    Email Id <span className="text-danger">*</span>
                                </label>
                                <div className="d-flex">
                                    <input
                                        type="text"
                                        className="form-control input-field-ui"
                                        id="emailId"
                                        name="emailId"
                                        value={formData.emailId}
                                        onChange={handleEmailChange}
                                        placeholder="Enter Email Id"
                                        disabled={isEditMode} // Disable in edit mode
                                    />
                                    {!isEditMode && loading && <span>Loading...</span>}
                                </div>
                                {errors.emailId && <div className="text-danger">{errors.emailId}</div>}
                                
                                {/* Only show suggestions in Add mode */}
                                {!isEditMode && suggestions.length > 0 && (
                                    <ul className="suggestions-list">
                                        {suggestions.map((suggestion, index) => (
                                            <li key={index} onClick={() => handleSuggestionClick(suggestion)}>
                                                {suggestion.givenName} {suggestion.surname} ({suggestion.mail})
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="firstName" className="form-label">
                                    First Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control input-field-ui"
                                    id="firstName"
                                    name="firstName"
                                    value={formData.firstName}
                                    onChange={handleChange}
                                    placeholder="Enter First Name"
                                    readOnly
                                    disabled={isDisabled}
                                />
                                {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label htmlFor="lastName" className="form-label">
                                    Last Name <span className="text-danger">*</span>
                                </label>
                                <input
                                    type="text"
                                    className="form-control input-field-ui"
                                    id="lastName"
                                    name="lastName"
                                    value={formData.lastName}
                                    onChange={handleChange}
                                    placeholder="Enter Last Name"
                                    readOnly
                                    disabled={isDisabled}
                                />
                                {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                            </div>

                            <div className="col-md-12 mb-3">
                                <label htmlFor="roleId" className="form-label">
                                    Select Role <span className="text-danger">*</span>
                                </label>
                                <select
                                    className={`form-select input-field-ui ${errors.roleId ? 'is-invalid1' : ''}`}
                                    id="roleId"
                                    name="roleId"
                                    value={formData.roleId}
                                    onChange={handleChange}
                                >
                                    <option value="">Select Role</option>
                                    {roleOptions && roleOptions.length > 0 && roleOptions.map(role => (
                                        <option key={role.roleId} value={role.roleId}>
                                            {role.roleName}
                                        </option>
                                    ))}
                                </select>
                                {errors.roleId && <div className="text-danger">{errors.roleId}</div>}
                            </div>

                            {/* Add Status dropdown for edit mode */}
                            {isEditMode && (
                                <div className="col-md-12 mb-3">
                                    <label htmlFor="adminStatus" className="form-label">
                                        Status <span className="text-danger">*</span>
                                    </label>
                                    <select
                                        className={`form-select input-field-ui ${errors.adminStatus ? 'is-invalid1' : ''}`}
                                        id="adminStatus"
                                        name="adminStatus"
                                        value={formData.adminStatus}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Status</option>
                                        <option value="active">Active</option>
                                        <option value="inactive">Inactive</option>
                                    </select>
                                    {errors.adminStatus && <div className="text-danger">{errors.adminStatus}</div>}
                                </div>
                            )}

                            <div className="col-md-12 mb-3">
                                <button 
                                    type="submit" 
                                    className="btn btn-primary btn-primary program-save-btn me-2"
                                    disabled={isLoading}
                                >
                                    {isLoading 
                                        ? (isEditMode ? 'Updating...' : 'Adding...') 
                                        : (isEditMode ? 'Update User' : 'Add User')
                                    }
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-secondary clear-btn"
                                    onClick={handleClear}
                                >
                                    {isEditMode ? 'Cancel' : 'Clear'}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddUser;
