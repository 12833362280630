import { createSlice, createAsyncThunk, getDefaultMiddleware  } from "@reduxjs/toolkit";
import { soft_number_logger } from "../services/cn/Service";


export const initWebexCalling = createAsyncThunk(
    'webex/initWebexCalling',
    async (params, { dispatch }) => {
      // webex config
      const webexConfig = {
        config: {
          logger: {
            level: 'debug'
          },
          meetings: {
            reconnection: {
              enabled: true
            },
            enableRtx: true
          },
          encryption: {
            kmsInitialTimeout: 8000,
            kmsMaxTimeout: 40000,
            batcherMaxCalls: 30,
            caroots: null
          },
          dss: {}
        },
        credentials: {
          access_token: localStorage.getItem('call_client_id')
        }
      };
  
      const callingConfig = {
        clientConfig: {
          calling: true,
          contact: true,
          callHistory: true,
          callSettings: true,
          voicemail: true
        },
        callingClientConfig: {
          logger: {
            level: 'info'
          }
        },
        logger: {
          level: 'info'
        }
      };
  
      dispatch(setIsWebexRegistering(true));
      let calling = await window.Calling.init({ webexConfig, callingConfig });
      dispatch(setWebexCalling(calling));
  
      return new Promise((resolve, reject) => {
        calling.on('ready', async () => {
          try {
            await calling.register();
            dispatch(setIsWebexRegistering(false)); 
            const callingClient = calling.callingClient;
            const line = Object.values(callingClient.getLines())[0];
            line.register();
            dispatch(setWebexLine(line));
            dispatch(setIsWebexRegistered(true));
            // line.on('line:incoming_call', (callObj) => {
            //   dispatch(setIsIncoming(true)); 
            // }); 


            resolve(calling); 
          } catch (e) {
            reject(e);            
            
            let errorInfo = { 
              code: e.code || null,
              message: e.message || 'No message available',
              stack: e.stack || null,
              details: e.details || null,
              name: e.name || 'Unknown Error',
            }
            dispatch(setIsWebexRegistering(false)); 
            await soft_number_logger(errorInfo);
            dispatch(setIsErrorInWebex(true)); 
          }
        });
      });
    }
  );
  

const webexSlice = createSlice({
    name: "webex",
    initialState: {
        webexCalling: null, 
        webexCall: null,
        webexTransferCall: null, 
        webexLine: null,
        isIncommingCall: false, 
        callerInfo: null, 
        localAudioStream: null, 
        isErrorInWebex: false, 
        isWebexRegistering: false, 
        isWebexRegistered: false,
        callConferenceNumber: null, 
        callDisposition: false,
        cnchatnode: null
    },
    reducers: {
        setWebexCalling: (state, action) => {
            state.webexCalling = action.payload; 
        },

        setWebexCall: (state, action) => {
            state.webexCall = action.payload;
        },
        setWebexTransferCall: (state, action) => {
            state.webexTransferCall = action.payload;
        },
        setWebexLine: (state, action) => {
            state.webexLine = action.payload;
        },

        setIsIncoming: (state, action) => { 
          state.isIncommingCall = action.payload; 
        },

        setCallerInfo: (state, action)  => { 
          state.callerInfo = action.payload; 
        }, 

        setLocalAudiostream: (state, action) => { 
          state.localAudioStream = action.payload; 
        }, 

        setIsErrorInWebex: (state, action)  => { 
          state.isErrorInWebex  = action.payload; 
        },

        setIsWebexRegistering: (state, action) => { 
          state.isWebexRegistering = action.payload; 
        },
        setIsWebexRegistered: (state, action) => { 
          state.isWebexRegistered = action.payload; 
        }, 

        setCallConferenceNumber: (state, action) => { 
          state.callConferenceNumber = action.payload; 
        }, 

        setCallDisposition: (state, action) => { 
          state.callDisposition = action.payload; 
        },
        setCnChatNode: (state, action) => { 
          state.cnchatnode = action.payload; 
        },
    },
});

// exporting all actions
export const {
    setWebexCalling,  
    setWebexCall, 
    setWebexTransferCall, 
    setWebexLine, 
    setIsIncoming, 
    setCallerInfo,
    setLocalAudiostream, 
    setIsErrorInWebex,
    setIsWebexRegistering,
    setIsWebexRegistered,
    setCallConferenceNumber, 
    setCallDisposition,
    setCnChatNode
} = webexSlice.actions; 

// 
export default webexSlice.reducer; 