import React, { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { updateWebexSetting,fetch_admin_user_data,fetch_role_data } from '../../../services/medengage/Service';
import { Link } from 'react-router-dom'
import { LoaderLoader } from '../../utils/CommonLibrary';
import { exportTableData } from '../../utils/CommonLibrary';


function UserManagementTable(props) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedRole, setSelectedRole] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [adminUserData, setAdminUserData] = useState([]);
    const [roleData, setRoleData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    


useEffect(() => {
        fetchAdminUserData();
        fetchRoleData();
},[]);

//role master data
const fetchRoleData = async () => {
    try {
        let response = await fetch_role_data();
        setRoleData(response.data.data);
    } catch (error) {
      console.error(error);
    }
};

//get admin user data
const fetchAdminUserData = async () => {
    try {
            setIsLoading(true); // show loader
            let response = await fetch_admin_user_data();
            if(response.data.code == 200){
                setAdminUserData(response.data.data);
                props.setTotalAdminUsersData(response.data.totalAdminUsers);
                setIsLoading(false); // hide loader
            }
    } catch (error) {
      console.error(error);
    }
};

// const handleToggle = async (id) => {
//     try {
//         // setLoading(true);
//         let newWebExEnable;
//         const updatedData = data.map(row => {
//             if (row.adminId === id) {
//                 const newWebExNew = row.webexEnable === 'on' ? 'off' : 'on';
//                 newWebExEnable = newWebExNew;
//                 return { ...row, webexEnable: newWebExEnable };
//             }
//             return row;
//         });

//         setAdminUserData(updatedData);

        
//         updateWebexSetting(id,newWebExEnable)
//         .then((response) => {   
//             // setLoading(false);
//             // setMessage(response.data.data.msgData.showMsg);
//             // setType(response.data.data.msgData.msgType);
//             // showToast(response.data.data.msgData.showMsg, response.data.data.msgData.msgType,'top-right');
//         });
//     } catch (error) {
//         console.error('Error toggling visibility', error);
//     }
// };

//Edit Admin user 
const editAdminUser = async(editAdminId)=>{
    }

    const columns = [
        // {
        //     name: 'User Name',
        //     selector: row => row.userName,
        //     sortable: true,
        // },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Name',
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
        },
        {
            name: 'Last Login time',
            selector: row => row.lastLoginTime,
            sortable: true,
        },
        
        // {
        //     name: 'Webex Enable',
        //     selector: row => row.visibility,
        //     sortable: true,
        //     cell: row => (
        //         <div className="toggle-container-CareNavigator">
        //             <input
        //                 type="checkbox"
        //                 checked={row.webexEnable === 'on'}
        //                 onChange={() => handleToggle(row.adminId)}
        //             />
        //             <div className={`slider-CareNavigator round-CareNavigator ${row.webexEnable === 'on' ? 'checked' : ''}`}></div>
        //         </div>
        //     ),
        // },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <span style={{ color: row.status === 'active' ? '#01CD88' : '#FF0000' }}>
                    {row.status}
                </span>
            ),
        },
        {
            name: 'Inactivated By',
            selector: row => row.inactivatedBy,
            sortable: true,
        },
        
        {
            name: 'Inactivated Date',
            selector: row => row.inactiveDate,
            sortable: true,
        },
        {
            name: 'Edit',
            selector: row => row.action,
            sortable: false,
            cell: row => <Link to={`/edit-new-member-medengage/${row.adminId}`} style={{ fontSize: '20px'}} ><i className="bi bi-pen"></i></Link>,
        },
    ];
    
    const data = adminUserData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedRole('All');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery =
            user?.userName?.toLowerCase()?.includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.name.toLowerCase().includes(searchQuery.toLowerCase());

        const matchesRole = selectedRole === 'All' || user.role === selectedRole;
        const matchesStatus = selectedStatus === 'All' || user.status === selectedStatus;

        return matchesSearchQuery && matchesRole && matchesStatus;
    });

    const handleExport = () => {

        const modifiedData = filteredData.map(item => {
            return {
                'Email': item.email,
                'Name': item.name,
                'Role': item.role,
                'Last Login Time': item.lastLoginTime,
                'Status': item.status,
                'Inactivated By': item.inactivatedBy,
                'Inactivated Date': item.inactiveDate,
            };
        });
    
        modifiedData.forEach(function(v){ delete v.adminId; });

        exportTableData("admin_data",modifiedData);
    };
    

    return (
        <>
            <div className='container-fluid'>
                <LoaderLoader isLoading={isLoading} />
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{width: "100%"}}>
                        <input
                            type="text"
                            placeholder="Search By Name or Email"
                            value={searchQuery}
                            onChange={e => setSearchQuery(e.target.value)}
                            className="form-control input-field-ui ps-5"
                        />
                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedRole}
                            onChange={e => setSelectedRole(e.target.value)}
                            className='form-select input-field-ui'
                        >

                            <option value="All">Select Role</option>
                                {roleData.map(option => (
                                    <option key={option.roleId} value={option.roleName}>{option.roleName}</option>
                                ))}
                        </select>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                    {/* <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> EXPORT</button>
                    </div> */}
                    
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default UserManagementTable;
