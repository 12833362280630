import React, { useEffect, useState, useCallback } from 'react';
import { approve_or_reject_observation, 
  fetch_single_observation,
  fetch_observation_thread, 
  fetch_all_status_list, 
  change_observation_status,
  fetch_all_observation_history,
  resolve_observation,
  edit_observation_type_priority
} from '../../../../services/observations/Service';
import { useParams } from 'react-router-dom';
import { capitalizeFirstLetter, replaceUnderScoreWithSpace } from '../../../utils/Utils';
import CommentForm from '../../components/CommentForm';
import ConfirmationModal from '../../components/ConfirmationModal';
import useDashboard from '../../../../Hooks/useDashboard';
import { LoaderLoader } from '../../../utils/CommonLibrary';
import { Download, Image, Video, FileText, File } from 'lucide-react';
import axios from 'axios';


const ViewObservation = ({role}) => {
  const dashboardData = useDashboard();
  const [observationData, setObservationData] = useState({});
  const { observationId } = useParams();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalConfig, setModalConfig] = useState({
    title: '',
    message: '',
    confirmButtonText: '',
    confirmButtonVariant: '',
    onConfirm: () => {},
  });

  const [requestStatus, setRequestStatus] = useState({ 
    acceptRequest: false, 
    rejectRequest: false,   
    statusUpdate: false, 
    resolveRequest: false,
    reopenRequest: false
  });

  const [observationThread, setObservationThread] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [statusError, setStatusError] = useState('');
  const [apiError, setApiError] = useState('');
  const [observationHistory, setObservationHistory] = useState([]);
  const [historyLoading, setHistoryLoading] = useState(false);
  const [historyError, setHistoryError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [observationInfoEditMode, setObservationInfoEditMode] = useState({ 
    priority: false, 
    observationType: false
  });

  const [isRequestLoading, setIsRequestLoading] = useState({ 
    observationInfoUpdate: false, 
    type: false, 
    priority: false
  })
  

  useEffect(() => {
    fetchSingleObservation();
    fetchAllStatusList();
    fetchObservationHistory();

    if(!(observationData.status === 'Rejected' || observationData.status === 'Pending Review')){
        fetchObservationThread();
    }
  }, []);


  async function fetchSingleObservation() {
    try {
      setLoading(true);
      const response = await fetch_single_observation({ observationId: observationId });

      if (response.data.code === 200) {
        setObservationData(response.data.data?.observation || {});
      } else {

      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }



  // approve observation 
  const handleApproveObservation = async (observationId, action) => { 
    try{ 
    let resp =  await approve_or_reject_observation({observationId: observationId, action: action}); 

    if(action === 'approve'){
      setRequestStatus({...requestStatus, acceptRequest: true});
    }else if(action === 'reject'){
      setRequestStatus({...requestStatus, rejectRequest: true});
    }

    if(resp?.data?.code === 200){ 
          setObservationData({...observationData, status: resp?.data?.data?.status});
          fetchSingleObservation();
          fetchObservationHistory();
    }else{ 

    }

    }catch(e){ 
    }finally{
      setShowConfirmModal(false);
    }
}   


async function fetchAllStatusList(){ 
  try{ 
    let resp = await fetch_all_status_list({role: capitalizeFirstLetter(role)}); 

    if(resp?.data?.code === 200){ 
      setStatusList(resp?.data?.data?.statuses || []);
    }
  }catch(e){ 

  }
}



  const handleBackClick = () => {
    window.history.back();
  };

  const handleAcceptObservation = () => {
    setModalConfig({
      title: 'Accept Observation',
      message: 'Are you sure you want to accept this observation?',
      confirmButtonText: 'Accept',
      confirmButtonVariant: 'btn-primary program-save-btn',
      onConfirm: () => {
        // Add your accept logic here
        console.log('Observation accepted');
        handleApproveObservation(observationId, 'approve');
      }
    });
    setShowConfirmModal(true);
  };

  const handleRejectObservation = () => {
    setModalConfig({
      title: 'Reject Observation',
      message: 'Are you sure you want to reject this observation?',
      confirmButtonText: 'Reject',
      confirmButtonVariant: 'btn-primary program-save-btn',
      onConfirm: () => {
        handleApproveObservation(observationId, 'reject');
      }
    });
    setShowConfirmModal(true);
  };


  const fetchObservationThread = useCallback(async () => {
    try {
      const response = await fetch_observation_thread({
        observationId: observationId
      });
      
      if (response.data.code === 200) {
        setObservationThread(response.data?.data?.comments || []);
      } else {
        // setError(response.data.message);
      }
    } catch (error) {
      
    }
  }, [observationId]);

  const handleStatusChange = (e) => {
    setSelectedStatus(e.target.value);
    setStatusError('');
    setApiError('');
  };

  const handleStatusUpdate = async () => {
    // Validation
    if (!selectedStatus) {
      setStatusError('Please select a status');
      return;
    }
    setRequestStatus({...requestStatus, statusUpdate: true});
    try {
      const response = await change_observation_status({
        observationId: observationId,
        statusId: selectedStatus
      });

      if (response?.data?.code === 200) {
        setObservationData({...observationData, status: response?.data?.data?.status});
        setSelectedStatus('');
        fetchSingleObservation();
        fetchObservationHistory(); 
      } else {
        setApiError(response?.data?.message || 'Failed to update status');
      }
    } catch (error) {
      setApiError('Something went wrong while updating status');
    } finally {
      setRequestStatus({...requestStatus, statusUpdate: false});
    }
  };

  const fetchObservationHistory = useCallback(async () => {
    setHistoryLoading(true);
    setHistoryError(null);
    
    try {
      const response = await fetch_all_observation_history({
        observationId: observationId
      });

      if (response.data.code === 200) {
        setObservationHistory(response.data.data?.history || []);
      } else {
        setHistoryError(response.data.message || 'Failed to fetch history');
      }
    } catch (error) {
      setHistoryError('Something went wrong while fetching history');
    } finally {
      setHistoryLoading(false);
    }
  }, [observationId]);

  const verifyIssue = async (action) => {
    try {
        setLoading(true);
        const response = await resolve_observation({
            observationId: observationId,
            role: capitalizeFirstLetter(role), 
            action: action
        });
        
        if (response.data.code === 200) {
            fetchSingleObservation();
            fetchObservationHistory();
        } else {
        }
    } catch (error) {
        console.error('Error resolving observation:', error);
    } finally {
        setLoading(false);
    }
  };

  const handleDownload = async (e, url, filename) => {
    e.preventDefault();
    try {
      const response = await axios.get(url, { 
        responseType: 'blob'
      });
      const blob = await response.blob();
      const downloadUrl = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = filename || 'downloads';  // Use original filename or default to 'download'
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      console.error('Download failed:', error);
    }
  };

  async function handleObservationInfoChange(e, field){ 
    // setObservationInfoEditMode({...observationInfoEditMode, [field]: true});


    if(e.target.value === ''){      
      setApiError(`Please select ${field}`);
      return;
    }
    let key = field === 'observationType'? 'type': 'priority'; 
    setIsRequestLoading({...isRequestLoading, [key]: true});
    try{ 
      const response = await edit_observation_type_priority({
        observationId: observationId,
        field: field,
        value: e.target.value
      });

      if(response?.data?.code === 200){ 
        fetchSingleObservation();
        fetchObservationHistory();

        setObservationInfoEditMode({ 
          ...observationInfoEditMode, 
          [field]: false
        })
      }else{ 
        setApiError(response?.data?.message || 'Failed to update observation info');
      }

    }catch(e){ 
      setApiError('Something went wrong while updating observation info');
    }finally{ 
      setIsRequestLoading({...isRequestLoading, [key]: false});
    }
  }

  function handleObservationInfoModeChange(e,field){ 
      setObservationInfoEditMode({...observationInfoEditMode, [field]: true});
  }

  const getFileIcon = (fileType) => {
    if (fileType.match(/\.(jpg|jpeg|png|gif|webp)$/)) return <Image className="tw-w-6 tw-h-6" />;
    if (fileType.match(/\.(mp4|webm|mov|ogg)$/)) return <Video className="tw-w-6 tw-h-6" />;
    if (fileType.match(/\.pdf$/)) return <FileText className="tw-w-6 tw-h-6" />;
    return <File className="tw-w-6 tw-h-6" />;
  };

  const getFileExtension = (filename) => {
    return filename.split('.').pop().toUpperCase();
  };

  const getFileName = (filepath) => {
    return filepath.split('/').pop();
  };


  return (
    <div className="container-fluid">
      <LoaderLoader isLoading={loading} />
      <div className="row align-items-center pb-3 sticky-top-bar">
        <div className="col-md" style={{
          display: 'flex',
          alignItems: 'center'
        }}>
          <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackClick}><i class="bi bi-chevron-left me-1"></i> Back</button>
          <div className="col">
            <h4 className="mb-0 content-main-title">{observationData.title}</h4>
          </div>
        </div>

      </div>

      <div className='content-ProgramPerformance ' style={{marginBottom:"70px"}}>
        <div className='observation-wrapper'>
          {/* <h1 className='dashboard-graph-title'>{observationData.title}</h1> */}
          <div className='row text-center px-2'>

          <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Observation Id :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {observationData.id} 
                      </h1> 
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-md-2 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Observed By :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {capitalizeFirstLetter(observationData?.firstname) + ' ' + capitalizeFirstLetter(observationData?.lastname) + '(' + `${observationData?.observationBy === "User"?"Business":observationData.observationBy}` + ')'}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Status :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {observationData.status}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-2 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Current Responsibility :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {observationData.current_responsibility?.toLowerCase() === 'user' ? 'Business' : 
                       observationData.current_responsibility?.toLowerCase() === 'moderator' ? 'Moderator' :
                       observationData.current_responsibility?.toLowerCase() === 'goqii' ? 'GOQii' :
                       observationData.current_responsibility}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Application :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {capitalizeFirstLetter(observationData.dashboardName)}
                      </h1>
                    </div>  
                  </div>
                </div>
              </div>
            </div>  

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Priority :</h5>

                      { 
                        (observationInfoEditMode.priority)?  
                        <div className='tw-flex tw-flex-row tw-items-center tw-gap-1'>
                          <select className="form-select input-field-ui" id="roleId" aria-label="Default select example"
                            name='priority'
                            value={observationData.priority}
                            onChange={e => handleObservationInfoChange(e, "priority")}
                          >
                                      <option value="blocker">Blocker</option>
                                      <option value="major">Major</option>
                                      <option value="minor">Minor</option>
                                      <option value="good_to_have">Good to have</option>                                    
                                  </select>

                                  { 
                                    isRequestLoading.priority? 
                                    <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25"/><path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>:      
                                    <span onClick={e => setObservationInfoEditMode({...observationInfoEditMode, priority: false})} style={{cursor:"pointer"}}>
                                      <i className="bi bi-x-circle-fill ms-2"></i>
                                    </span>
                                    
                                  }
                        </div>
                        : 
                        <>
                        <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                        {replaceUnderScoreWithSpace(observationData.priority || "")}
                        &nbsp; 
                        { 
                          (role == "moderator" && observationData.status != "Closed" && observationData.status != "Rejected" )  ?
                          <span onClick={e => handleObservationInfoModeChange(e, "priority")} style={{cursor:"pointer"}}><i className="bi bi-pencil-square me-2"></i></span>: 
                          null
                        }
                        </h1>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Observation Type :</h5>
                      { 
                        (observationInfoEditMode.observationType) ?  
                        <div className='tw-flex tw-flex-row tw-items-center tw-gap-1'>
                            <select className="form-select input-field-ui" id="roleId" aria-label="Default select example" 
                              name='observationType'
                              value={observationData.observation_type}
                              onChange={e => handleObservationInfoChange(e, "observationType")}
                            >
                              <option value="new_requirement">New Requirement</option>
                              <option value="issue">Issue</option>
                              <option value="enhancement_request">Enhancement Request</option>
                              <option value="suggestion">Suggestion</option>
                            </select>
                            {
                               isRequestLoading.type?
                               <svg xmlns="http://www.w3.org/2000/svg" width="2em" height="2em" viewBox="0 0 24 24"><path fill="currentColor" d="M12,1A11,11,0,1,0,23,12,11,11,0,0,0,12,1Zm0,19a8,8,0,1,1,8-8A8,8,0,0,1,12,20Z" opacity="0.25"/><path fill="currentColor" d="M10.72,19.9a8,8,0,0,1-6.5-9.79A7.77,7.77,0,0,1,10.4,4.16a8,8,0,0,1,9.49,6.52A1.54,1.54,0,0,0,21.38,12h.13a1.37,1.37,0,0,0,1.38-1.54,11,11,0,1,0-12.7,12.39A1.54,1.54,0,0,0,12,21.34h0A1.47,1.47,0,0,0,10.72,19.9Z"><animateTransform attributeName="transform" dur="0.75s" repeatCount="indefinite" type="rotate" values="0 12 12;360 12 12"/></path></svg>
                               :    
                                <span onClick={e => setObservationInfoEditMode({...observationInfoEditMode, observationType: false})} style={{cursor:"pointer"}}>
                                  <i className="bi bi-x-circle-fill ms-2"></i>
                                </span>
                            }
                        </div>
                        : 
                        <>
                        <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                        {replaceUnderScoreWithSpace(observationData.observation_type || "")} &nbsp;
                          { 
                            (role == "moderator" && observationData.status != "Closed" && observationData.status != "Rejected" ) ? <span onClick={e => handleObservationInfoModeChange(e, "observationType")} style={{cursor:"pointer"}}><i className="bi bi-pencil-square me-2"></i></span>: null
                          }
                        </h1>
                        </>
                      }
                    </div>  
                  </div>
                </div>
              </div>
            </div>

           

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Observed On :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {observationData.createdDate}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-3 px-1 mb-2">
              <div
                className="card bg-grey"
                style={{ borderRadius: '14px', border: 'none', height: '100%' }}
              >
                <div className="card-body" style={{ padding: '14px' }}>
                  <div className="row">
                    <div className="col">
                      <h5 className="card-text1 mb-1">Last Action Date :</h5>
                      <h1 className="card-title1 mb-0" style={{ fontSize: '16px', fontWeight: 500 }}>
                      {observationData.lastActionDate}
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
         
            {/* <div className="row">
            <div className='col-md-12 mt-3 px-4'>
             <h5 className='dashboard-graph-title' style={{justifyContent:'flex-start'}}>Observation:</h5>          
             <p dir="auto" style={{wordWrap: 'break-word', width: '100%'}}>{observationData.description}</p>
              <div className='row mt-3'>
              { 
                 observationData.attachments?.length ? observationData.attachments.map((attachment, index) => (
                  <div className='col-md-4 my-3' key={index}>
                    {attachment.toLowerCase().endsWith('.pdf') ? (
                      <embed src={attachment} type="application/pdf" width="100%" height="600px" />
                    ) : attachment.toLowerCase().match(/\.(mp4|webm|ogg)$/) ? (
                      <>
                      <video controls className="img-fluid">
                        <source src={attachment} type={`video/${attachment.split('.').pop()}`} />
                        Your browser does not support the video tag.
                      </video>
                      </>
                     
                    ) : attachment.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) ? (
                      <>
                          <img src={attachment} alt="attachment" className='img-fluid' />
                      </>
                    ) : (
                      <a href={attachment} target="_blank" rel="noopener noreferrer">Download File</a>
                    )}
                  </div>
                )) : null 
              }
              </div>
             <hr className='my-4' />
          </div>   
               

         
            </div> */}

<div className="tw-w-full">
      <div className="tw-p-4">
        <div className="tw-mb-6">
          <h5 className="tw-text-lg tw-font-medium tw-mb-2">Observation:</h5>
          <p className="tw-text-gray-700 tw-break-words tw-w-full">
            {observationData.description}
          </p>
        </div>

        <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
          {observationData.attachments?.map((attachment, index) => {
            const isMedia = attachment.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp|mp4|webm|mov|ogg|pdf)$/);
            
            if (!isMedia) {
              return (
                <div key={index} className="tw-bg-gray-50 tw-rounded-lg tw-p-4 tw-shadow-sm hover:tw-shadow-md tw-transition-shadow">
                  <div className="tw-flex tw-items-center tw-gap-3">
                    {getFileIcon(attachment.toLowerCase())}
                    <div className="tw-flex-1 tw-min-w-0">
                      <p className="tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">
                        {getFileName(attachment)}
                      </p>
                      <p className="tw-text-xs tw-text-gray-500">
                        {getFileExtension(attachment)}
                      </p>
                    </div>
                    <a
                      href={attachment}
                      download
                      target="_blank"
                      className="tw-p-2 tw-rounded-full hover:tw-bg-gray-200 tw-transition-colors"
                    >
                      <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                    </a>
                  </div>
                </div>
              );
            }

            return (
              <div key={index} className="tw-relative tw-group">
                <div className="tw-bg-white tw-rounded-lg tw-shadow-sm tw-overflow-hidden">
                  {attachment.toLowerCase().endsWith('.pdf') ? (
                    <div className="tw-h-64">
                      <embed 
                        src={attachment} 
                        type="application/pdf" 
                        className="tw-w-full tw-h-full"
                      />
                    </div>
                  ) : attachment.toLowerCase().match(/\.(mp4|webm|mov|ogg)$/) ? (
                    <div className="tw-relative tw-pt-[56.25%] tw-bg-gray-100">
                      <video 
                        controls 
                        className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover"
                        playsInline
                      >
                        <source 
                          src={attachment} 
                          type="video/mp4" 
                        />
                        <source 
                          src={attachment} 
                          type="video/quicktime" 
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : attachment.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) ? (
                    <div className="tw-relative tw-pt-[75%] tw-bg-gray-100">
                      <img 
                        src={attachment} 
                        alt={`Attachment ${index + 1}`} 
                        className="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-object-cover"
                      />
                    </div>
                  ) : null}

                  <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                    <a
                      href={attachment}
                      download
                      target="_blank"
                      className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                    >
                      <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                    </a>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <hr className="tw-my-6 tw-border-gray-200" />
      </div>
    </div>
            
          

          {
            observationThread.length > 0 && 
            observationThread.map((item, index) => (
              <div className='observation-content w-100 px-3'>
                <div className="comment-wrapper">
                  <div className="comment-info">
                    <div className="comment-author mb-2">
                      <div className="avatar comment-avatar">
                        <img
                          src="https://storage.googleapis.com/ksabupatest/2024/10/30/v9wj7/74g44s3273.webp"
                          alt=""
                          className="user-avatar"
                        />
                      </div>

                      <div className="comment-meta">
                        <span title="Javed Ali">
                          <span>
                            {capitalizeFirstLetter(item.firstname) + ' ' + capitalizeFirstLetter(item.lastname) + ' (' + `${item.commentBy.toLowerCase() === "user"? 'Business': item.commentBy.toLowerCase() === "moderator"? 'Moderator': item.commentBy.toLowerCase() === "goqii"? 'GOQii': item.commentBy}` + ')'}
                          </span>
                        </span>

                        <ul className="meta-group">
                          <li className="meta-data">
                            <time datetime="2024-10-09T14:14:25Z" title="2024-10-09 19:44" data-datetime="relative">
                              {item.createdTime}
                            </time>
                          </li> 
                        </ul>
                      </div>
                    </div>

                    <section className="comment-body">
                      <div className="zd-comment ps-5" dir="auto">
                        <p dir="auto" style={{wordWrap: 'break-word', width: '100%'}}>{item.comment}</p>
                        {
                          item.attachments?.length ? (
                            <div className="tw-flex tw-flex-row tw-flex-wrap tw-gap-3 tw-mt-3">
                            {item?.attachments.map((attachment, index) => (
                              <div key={index} className="tw-max-w-[300px]">
                                {attachment.toLowerCase().endsWith('.pdf') ? (
                                  <div className="tw-relative tw-group">
                                    <embed 
                                      src={attachment} 
                                      type="application/pdf" 
                                      className="tw-w-full tw-h-[600px]"
                                    />
                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                      <a
                                        href={attachment}
                                        download={attachment.split('/').pop()}
                                        target='_blank'
                                        className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                      >
                                        <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                      </a>
                                    </div>
                                  </div>
                                ) : attachment.toLowerCase().match(/\.(mp4|webm|ogg)$/) ? (
                                  <div className="tw-relative tw-group">
                                    <video controls className="tw-w-full tw-h-auto">
                                      <source 
                                        src={attachment} 
                                        type={`video/${attachment.split('.').pop()}`} 
                                      />
                                      Your browser does not support the video tag.
                                    </video>
                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                      <a
                                        href={attachment}
                                        download={attachment.split('/').pop()}
                                        target='_blank'
                                        className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                      >
                                        <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                      </a>
                                    </div>
                                  </div>
                                ) : attachment.toLowerCase().match(/\.(jpg|jpeg|png|gif|webp)$/) ? (
                                  <div className="tw-relative tw-group">
                                    <img 
                                      src={attachment} 
                                      alt="attachment" 
                                      className="tw-w-full tw-h-[200px] tw-object-cover"
                                    />
                                    <div className="tw-absolute tw-top-2 tw-right-2 tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                                      <a
                                        href={attachment}
                                        download={attachment.split('/').pop()}
                                        target="_blank"
                                        className="tw-bg-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-gray-100 tw-transition-colors"
                                      >
                                        <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="tw-bg-gray-50 tw-rounded-lg tw-p-4 tw-shadow-sm tw-flex tw-items-center tw-gap-3">
                                    {getFileIcon(attachment.toLowerCase())}
                                    <div className="tw-flex-1 tw-min-w-0">
                                      <p className="tw-text-sm tw-font-medium tw-text-gray-900 tw-truncate">
                                        {getFileName(attachment)}
                                      </p>
                                    </div>
                                    <a
                                      href={attachment}
                                      download
                                      target='_blank'
                                      className="tw-p-2 tw-rounded-full hover:tw-bg-gray-200 tw-transition-colors"
                                    >
                                      <Download className="tw-w-5 tw-h-5 tw-text-gray-600" />
                                    </a>
                                  </div>
                                )}
                              </div>
                            ))}
                          </div>
                          ) : null
                        }
                      </div>
                    </section>
                  </div>
                </div>
                <hr />
              </div>  
            ))
          }

          {
             observationData.status === 'Pending Review' && role === 'moderator' &&
            <div className="d-flex gap-2 mb-4 px-2">
              <button 
                className="btn btn-primary btn-primary program-save-btn me-2"
                onClick={handleAcceptObservation}
              >
                {/* <i className="fa fa-check me-1"></i> */}
                Accept Observation
              </button>
              <button 
                className="btn clear-btn"
                onClick={handleRejectObservation}
              >
                {/* <i className="fa fa-times me-1"></i> */}
                Reject Observation
              </button>
            </div>
          }

          { 
            observationData.status === 'Rejected' || observationData.status === 'Closed'? 
            <h5 className='dashboard-graph-title px-3 text-dark mb-3'>
                {observationData.status === 'Rejected' ? 'Observation Rejected' : 'Observation has been closed'}
              </h5>
            : null
          }


          { 
            (observationData.status !== 'Closed' && observationData.status !== 'Rejected') &&
              <div className='col-md-12'>
                {
                   (observationData.status !== 'Pending Review' && observationData.status !== "Pending Acceptance" ||  (role == "moderator" || role == "goqii" )) &&  (
                    <>
                    <h5 className='dashboard-graph-title mb-3' style={{justifyContent:'flex-start'}}>Add Comment <span className="text-danger">*</span></h5>
                     <CommentForm observationId={observationId} commentBy={`${capitalizeFirstLetter(role)}`} fetchObservationThread={fetchObservationThread} fetchObservationHistory={fetchObservationHistory}/>
                     <hr className="my-4" />
                    </>
                   )
                }
              </div>
          }


            <div>
              { 
                (observationData.status !== 'Pending Review' && observationData.status !== 'Closed' && observationData.status !== 'Rejected') && (
                  
                  <div className='row mt-4 px-3'>
                      <>
                      {(role === 'moderator' || (capitalizeFirstLetter(observationData.current_responsibility) === capitalizeFirstLetter(role))) && (
                        <div className='col-md-12'>
                          <h5 className='dashboard-graph-title mb-3' style={{justifyContent:'flex-start'}}>Update Status <span className="text-danger">*</span></h5>
                          <div className="row align-items-center">
                            <div className="col-md-5 mb-3">
                              <select 
                                name="status"
                                className={`form-select control-input-ui ${statusError ? 'is-invalid' : ''}`}
                                value={selectedStatus}
                                onChange={handleStatusChange}
                              >
                                <option value="" disabled>Select Status</option>
                                { 
                                  statusList.map((item, index) => (
                                    <option key={item.id} value={item.id}>{item.status}</option>
                                  ))
                                }
                              </select>
                              {statusError && <div className="invalid-feedback">{statusError}</div>}
                            </div>

                            <div className="col-12 mb-3">
                              {apiError && <div className="alert alert-danger mb-3">{apiError}</div>}
                              <button 
                                type="button" 
                                className="btn btn-success uplaod-file-btn"
                                onClick={handleStatusUpdate}
                                disabled={requestStatus.statusUpdate}
                              >
                                Update Status
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      </>
                    
                  </div>
                )
              }

              { 
                observationData.status === 'Pending Acceptance' && (role === 'user' || role === 'moderator') &&
                <div className='row mt-4 px-3'>
                  <div className='col-md-12'>
                    <h5 className='dashboard-graph-title mb-3' style={{justifyContent:'flex-start'}}>Observation Pending Acceptance</h5>

                    <div className='d-flex gap-2 mb-4'>
                      <button 
                        className="btn btn-primary btn-primary program-save-btn me-2"
                        onClick={e => verifyIssue("resolve")}
                      >
                        Resolved
                      </button>


                      <button 
                        className="btn clear-btn"
                        onClick={e => verifyIssue("reopen")}
                      >
                        Reopen
                      </button>
                    </div>
                  </div>
                </div>
              }

              { 
                observationData.status === 'Pending Acceptance' && role === 'goqii' &&
                <div className='row mt-4 px-3'>
                  <div className='col-md-12'>
                    <h5 className='dashboard-graph-title mb-3' style={{justifyContent:'flex-start'}}>Observation Pending Acceptance</h5>
                  </div>
                </div>
              }
            </div>

          <div className='row mt-4 px-2'>
            <div className='col-md-12'>
              <h5 className='dashboard-graph-title mb-3' style={{justifyContent:'flex-start'}}>
                Observation History
              </h5>
              <div className="table-responsive mb-3">
                {historyLoading ? (
                  <div className="text-center py-4">
                    <div className="spinner-border text-primary" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  <table className="table table-striped mb-0">
                    <thead>
                      <tr>
                        <th style={{backgroundColor:"rgb(212, 230, 239)", fontSize:"14px", fontWeight:"500"}}>Date Modified</th>
                        <th style={{backgroundColor:"rgb(212, 230, 239)", fontSize:"14px", fontWeight:"500"}}>Username</th> 
                        <th style={{backgroundColor:"rgb(212, 230, 239)", fontSize:"14px", fontWeight:"500"}}>Comment</th>
                      </tr>
                    </thead>
                    <tbody>
                      {observationHistory.map((history, index) => (
                        <tr key={index}>
                          <td style={{fontSize:"13px"}}>{history.createdTime}</td>
                          <td style={{fontSize:"13px"}}>
                            {capitalizeFirstLetter(history.firstname)} {capitalizeFirstLetter(history.lastname) + ' (' + `${history.actionBy === "User"?"Business":history.actionBy}` + ')'}
                          </td>
                          <td style={{fontSize:"13px"}}>{history.comments}</td>
                        </tr>
                      ))}
                    </tbody>  
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        show={showConfirmModal}
        onHide={() => setShowConfirmModal(false)}
        title={modalConfig.title}
        message={modalConfig.message}
        confirmButtonText={modalConfig.confirmButtonText}
        confirmButtonVariant={modalConfig.confirmButtonVariant}
        onConfirm={modalConfig.onConfirm}
        acceptRequestDisabled={requestStatus.acceptRequest}
        rejectRequestDisabled={requestStatus.rejectRequest}
      />
    </div>
  )
}

export default ViewObservation; 