import React, { useState, useRef, useEffect } from 'react';
import { FaChevronDown, FaInfoCircle, FaUser, FaCheck, FaTimes, FaSearch } from 'react-icons/fa';
import AdvancedFilter from './AdvancedFilter';
import moment from 'moment';
import { fetchFilterMemberCampgain, fetchMemberContractorList } from '../../../services/mtp/Service';


const ChooseAudience = ({optionTags,selectedFilteredList,setSelectedFilteredList,totalCountUserList,setTotalCountUserList,setMemberFilterSelectQuery,memberFilterSelectQuery,filterQueries,setFilterQueries,optOutNCount,setOptOutNCount,optOutYCount,setOptOutYCount,setIsLoading,isLoading}) => {
    const [selectedTags, setSelectedTags] = useState([]);
    const [showTagDropdown, setShowTagDropdown] = useState(false);
    const [currentTag, setCurrentTag] = useState(null);
  const [showMoreTags, setShowMoreTags] = useState(false);
  const [showTagsModal, setShowTagsModal] = useState(false);
  const [showTraitsModal, setShowTraitsModal] = useState(false);
  const [checkedUsers, setCheckedUsers] = useState([]);
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [currentFilter, setCurrentFilter] = useState(null);
  const [showConditionDropdown, setShowConditionDropdown] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0 });
  const tagContainerRef = useRef(null);
  const [conditionOption, setConditionOption] = useState([]);
  const [contractList, setContractList] = useState([]);
  const [memberType, setMemberType] = useState([]);
  
  
  const enrollmentOption = [{'label':'Touched','value':'Touched'},{'label':'Untouched','value':'Untouched'}];


  const memberTypeOption = [
    { label: 'Enrolled', value: 'active' },
    { label: 'Not Enrolled', value: 'inactive' },
  ];
  
  const memberFun = ()=>{
  let data = memberTypeOption?.map((t) => ({
    label: t.label,
    value: t.value
  }));
  setMemberType(data);
  }
  //,{'label':'Not interested','value':'rejected'}
  
  // const [selectedFilteredList, setSelectedFilteredList] = useState([]);
  // const [totalCountUserList, setTotalCountUserList] = useState([]);
  
  const filterFields = [
    {
      value: "",
      display: "Select",
      type: "select",
      options: ""
    },
    {
      value: "conditionId",
      display: "Condition",
      type: "select",
      options: optionTags
    },
   
    // {
    //   value: "m.lastUpdatedTime",
    //   display: "Last contacted Date by WhatsApp",
    //   type: "date"
    // },

    // {
    //   value: "enrollmentStatus",
    //   display: "Member Reach Type",
    //   type: "select",
    //   options: enrollmentOption
    // },

    {
      value: "enrollmentStatus1",
      display: "Member Type",
      type: "select",
      options: memberType
    },
    {
      value: "contractNo",
      display: "Contract No",
      type: "select",
      options: contractList
    },
    {
      value: "policyEndDate",
      display: "Policy End Date",
      type: "date"
    },
    {
      value: "enrollmentTime",
      display: "Members Enrolled Date",
      type: "date"
    },
    // {
    //   value: "enrollmentTime",
    //   display: "Members Not Enrolled Date",
    //   type: "date"
    // }
  ];
  
  const initialQueries = [
    {
      field: "condition",
      operator: "=",
      value: "",
      condition: null,
      type: "select",
      startDate: moment().subtract(6, "days"),
      endDate: moment()
    }
  ];

  console.log("selectedFilteredList",selectedFilteredList)
  const users = selectedFilteredList?.length > 0 ? selectedFilteredList: [];

 

  const TableComponent = () => {
    return (
      <div className="tw-overflow-x-auto">
        <table className="tw-w-full tw-border-collapse">
          <thead>
            <tr className="tw-border-b tw-border-gray-300">
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">
                <div className="tw-relative tw-w-8 tw-h-8 tw-rounded-full tw-bg-gray-200 tw-flex tw-items-center tw-justify-center tw-overflow-hidden">
                  <FaUser className="tw-text-gray-600" />
                  <div className="tw-absolute tw-inset-0 tw-flex tw-flex-wrap">
                    {['tw-bg-blue-400', 'tw-bg-yellow-400', 'tw-bg-red-400', 'tw-bg-purple-400'].map((color, index) => (
                      <div key={index} className={`tw-w-1/2 tw-h-1/2 ${color} tw-opacity-50`}></div>
                    ))}
                  </div>
                </div>
              </th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Member ID</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Member Name</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Mobile Number</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Email</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Network Type</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Contract No.</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Contract Name</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Policy Start Date</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Policy End Date</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Contract Termination Date</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Status</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Requested Medication</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Gender</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Age</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Chronic Condition</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">City</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">HOF</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Enrollment Date</th>
              <th className="tw-py-2 tw-px-4 tw-text-left tw-font-semibold">Migrated on</th>
            </tr>
          </thead>
          <tbody>
            {users?.map((user) => (
              <tr key={user.id} className="tw-border-b tw-border-gray-200 hover:tw-bg-gray-50">
                <td className="tw-py-2 tw-px-4">
                  <div className="tw-relative tw-w-8 tw-h-8 tw-rounded-full tw-flex tw-items-center tw-justify-center tw-cursor-pointer tw-group" 
                       onClick={() => toggleUserCheck(user.id)}>
                    <div className={`tw-w-full tw-h-full tw-rounded-full ${user.color} group-hover:tw-opacity-0 tw-transition-opacity`}></div>
                    <div className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-opacity-0 group-hover:tw-opacity-100 tw-transition-opacity">
                      <div className={`tw-w-5 tw-h-5 tw-border-2 tw-border-gray-400 tw-rounded ${checkedUsers.includes(user.id) ? 'tw-bg-blue-500 tw-border-blue-500' : 'tw-bg-white'} tw-flex tw-items-center tw-justify-center`}>
                        {checkedUsers.includes(user.id) && <FaCheck className="tw-text-white tw-text-xs" />}
                      </div>
                    </div>
                  </div>
                </td>
                <td className="tw-py-2 tw-px-4">{user.membershipNo}</td>
                <td className="tw-py-2 tw-px-4">{user.memberName}</td>
                <td className="tw-py-2 tw-px-4">{user.mobile}</td>
                <td className="tw-py-2 tw-px-4">{user.email}</td>
                <td className="tw-py-2 tw-px-4">{user.memberNetwork}</td>
                <td className="tw-py-2 tw-px-4">{user.contractNo}</td>
                <td className="tw-py-2 tw-px-4">{user.contractName}</td>
                <td className="tw-py-2 tw-px-4">{user.policyStartDate}</td>
                <td className="tw-py-2 tw-px-4">{user.policyEndDate}</td>
                <td className="tw-py-2 tw-px-4">{user.contractTerminationDate}</td>
                <td className="tw-py-2 tw-px-4">{user.status}</td>
                <td className="tw-py-2 tw-px-4">{user.requestedMedication}</td>
                <td className="tw-py-2 tw-px-4">{user.gender}</td>
                <td className="tw-py-2 tw-px-4">{user.dob}</td>
                <td className="tw-py-2 tw-px-4">{user.chronicCondition}</td>
                <td className="tw-py-2 tw-px-4">{user.city}</td>
                <td className="tw-py-2 tw-px-4">{user.isHof}</td>
                <td className="tw-py-2 tw-px-4">{user.enrollmentTime}</td>
                <td className="tw-py-2 tw-px-4">{user.migratedOn}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const [filteredUsers, setFilteredUsers] = useState(users);

  const handleFilterApply = (queries) => {
    if(Object.values(queries).some(q=>q.value === '')){
      setMemberFilterSelectQuery('');
      return;
    } 
    setFilterQueries(JSON.stringify({ queries }));   
    // Example filter implementation with dummy logic
    const filtered = users.filter(user => {
      return queries.every(query => {
        switch (query.field) {
          case 'condition':
            return true;
          case 'enrollmentStatus':
              return true; // Dummy condition - always pass
          // case 'member_age':
          //   const age = Math.floor(Math.random() * 50) + 20; // Dummy age
          //   switch (query.operator) {
          //     case '=':
          //       return age === Number(query.value);
          //     case '>':
          //       return age > Number(query.value);
          //     case '<':
          //       return age < Number(query.value);
          //     default:
          //       return true;
          //   }
          case 'last_contacted_date':
          case 'policyEndDate':
          case 'members_enrolled_date':
          case 'members_not_enrolled_date':
            if (query.operator === 'between' && query.startDate && query.endDate) {
              const date = moment(user.createdOn, 'DD/MM/YYYY');
              return date.isBetween(query.startDate, query.endDate, 'day', '[]');
            }
            return true;
          default:
            return true;
        }
      });
    });
    setFilteredUsers(filtered);
    try{
          setIsLoading(true);
          fetchFilterMemberCampgain(JSON.stringify({ queries }))
          .then((response) => {   
            setSelectedFilteredList(response.data.data);
            setTotalCountUserList(response.data.totalMemberCount);
            setOptOutNCount(response.data.optOutNCount);
            setOptOutYCount(response.data.optOutYCount);
            setMemberFilterSelectQuery(response.data.memberSelectQuery);
            setIsLoading(false);
          })
     }catch(error) {
      console.error('Error=', error);
    }
  };

  const toggleUserCheck = (userId) => {
    setCheckedUsers(prev => 
      prev.includes(userId) 
        ? prev.filter(id => id !== userId)
        : [...prev, userId]
    );
  };





  useEffect(() => {
    if (showConditionDropdown && currentTag && tagContainerRef.current) {
      const tagElements = tagContainerRef.current.querySelectorAll('button');
      const tagElement = Array.from(tagElements).find(el => el.textContent === currentTag);
      if (tagElement) {
        const rect = tagElement.getBoundingClientRect();
        const containerRect = tagContainerRef.current.getBoundingClientRect();
        setDropdownPosition({
          top: rect.bottom - containerRect.top,
          left: rect.left - containerRect.left
        });
      }
    }
    fetchContractorData();
    memberFun();
  }, [showConditionDropdown, currentTag,optionTags]);

  const fetchContractorData = async()=>{
    try {
          let resp = await fetchMemberContractorList();
          if(resp.data.code === 200){
            const data = resp.data.data?.map((t) => ({
              label: t.contractName,
              value: t.contractNameId
            }));
            setContractList(data);
          }

    }catch (err) {
        console.error("api failed to load");
    }
  }

  // const handleConditionSelect = (condition) => {
  //   setSelectedTags([...selectedTags, { tag: currentTag, condition }]);
  //   setShowConditionDropdown(false);
  //   setCurrentTag(null);
  // };

  // const handleRemoveTag = (tag) => {
  //   setSelectedTags(selectedTags.filter(t => t.tag !== tag));
  // };

  // const TagsInfoModal = () => (
  //   <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
  //     <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-full tw-max-w-3xl">
  //       <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
  //         <h2 className="tw-text-xl tw-font-bold">How to add tags to users?</h2>
  //         <button onClick={() => setShowTagsModal(false)} className="tw-text-gray-500 hover:tw-text-gray-700">
  //           &times;
  //         </button>
  //       </div>
  //       <p className="tw-mb-4">
  //         Tags are identifiers for a user, which can be used to filter your campaign audience. For example: You could apply the tag 'Frequent Buyers' for all users who frequently purchase your products, and then, filter your campaign audience by this tag to send them a promotional offer.
  //       </p>
  //       <p className="tw-font-semibold tw-mb-2">There are 5 ways to apply tags to your users:</p>
  //       <ul className="tw-list-disc tw-pl-5 tw-space-y-2">
  //         <li>
  //           <span className="tw-font-semibold">Upload a CSV</span> - On uploading a CSV containing details of your users, a default tag (for example: Csv upload 5th Apr 11:36) is applied on all users in the CSV. You can choose to apply custom tags as well. <a href="#" className="tw-text-blue-600">Click here to upload CSV</a>
  //         </li>
  //         <li>
  //           <span className="tw-font-semibold">Bulk tag from the Users Page</span> - Apply audience filters in the Users page, or select the users individually and apply tags. <a href="#" className="tw-text-blue-600">Click here to go to the Users Page</a>
  //         </li>
  //         <li>
  //           <span className="tw-font-semibold">Tag users in the Inbox</span> - While speaking to a user in the inbox, you can easily add a tag from the right panel. <a href="#" className="tw-text-blue-600">Click here to go to Inbox</a>
  //         </li>
  //         <li>
  //           <span className="tw-font-semibold">Automated tags</span> - These are tags applied by us automatically basis certain actions or behavior of the users. <a href="#" className="tw-text-blue-600">Click here to configure rules for auto-tags</a>
  //         </li>
  //         <li>
  //           <span className="tw-font-semibold">Add tags via API</span> - Call Interakt's Track User API to add tags to a user. <a href="#" className="tw-text-blue-600">Click here to see API document</a>
  //         </li>
  //       </ul>
  //     </div>
  //   </div>
  // );

  const TraitsEventsInfoModal = ({ onClose }) => (
    <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
      <div className="tw-bg-white tw-shadow-xl tw-rounded-xl tw-overflow-hidden tw-h-[80%] tw-w-full tw-max-w-2xl">
        {/* Header */}
        <div className="tw-px-6 tw-py-4 tw-border-b">
          <div className="tw-flex tw-justify-between tw-items-center">
            <h2 className="tw-text-xl tw-font-bold tw-text-gray-800">Filter Members Guide</h2>
            <button 
              onClick={() => setShowTraitsModal(false)} 
              className="tw-text-gray-400 tw-border-none tw-bg-transparent hover:tw-text-gray-600 tw-transition-colors"
            >
              <svg className="tw-w-6 tw-h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
              </svg>
            </button>
          </div>
        </div>
  
        {/* Content */}
        <div className="tw-px-6 tw-py-4 tw-max-h-[calc(80vh-8rem)] tw-overflow-y-auto">
          <div className="tw-text-gray-600 tw-space-y-6">
            {/* Introduction */}
            <p className="tw-text-sm tw-leading-relaxed">
              To filter members efficiently, you can apply multiple filters based on various member traits, 
              using <span className="tw-font-semibold tw-text-gray-800">AND</span> and <span className="tw-font-semibold tw-text-gray-800">OR</span> conditions 
              for more refined results.
            </p>
  
            {/* Main Filters */}
            <div className="tw-bg-gray-50 tw-rounded-lg tw-p-4">
              <h3 className="tw-text-gray-800 tw-font-semibold tw-mb-3">Key Filter Options</h3>
              <ul className="tw-space-y-3">
                <li>
                  <span className="tw-text-gray-800 tw-font-bold">Condition:</span>
                  <p className="tw-text-sm">Filter members by specific health conditions.</p>
                </li>
                <li>
                  <span className="tw-text-gray-800 tw-font-bold">Last Contacted Date:</span>
                  <p className="tw-text-sm">Identify members by the most recent interaction date to follow up effectively.</p>
                </li>
                <li>
                  <span className="tw-text-gray-800 tw-font-bold">Member Age:</span>
                  <p className="tw-text-sm">Target age-specific cohorts to tailor engagement and services.</p>
                </li>
                <li>
                  <span className="tw-text-gray-800 tw-font-bold">Policy End Date:</span>
                  <p className="tw-text-sm">Filter by members nearing policy expiration to manage renewals proactively.</p>
                </li>
              </ul>
            </div>
  
            {/* Enrollment Status */}
            <div className="tw-bg-gray-50 tw-rounded-lg tw-p-4">
              <h3 className="tw-text-gray-800 tw-font-semibold tw-mb-3">Enrollment Status</h3>
              <ul className="tw-space-y-3">
                <li>
                  <span className="tw-text-gray-800 tw-italic">Members Enrolled Date:</span>
                  <p className="tw-text-sm">Select members based on when they joined, for tracking engagement from enrollment onward.</p>
                </li>
                <li>
                  <span className="tw-text-gray-800 tw-italic">Members Not Enrolled Date:</span>
                  <p className="tw-text-sm">Find individuals who were invited but have not yet enrolled.</p>
                </li>
              </ul>
            </div>
  
            {/* Combining Filters */}
            <div className="tw-bg-blue-50 tw-rounded-lg tw-p-4">
              <h3 className="tw-text-gray-800 tw-font-semibold tw-mb-3">Combining Filters</h3>
              <p className="tw-text-sm tw-mb-3">You can combine these filters using:</p>
              <div className="tw-grid tw-grid-cols-2 tw-gap-4">
                <div className="tw-bg-white tw-rounded-md tw-p-3">
                  <span className="tw-text-gray-800 tw-font-bold">AND:</span>
                  <p className="tw-text-sm">Narrow the search, ensuring members meet all selected criteria.</p>
                </div>
                <div className="tw-bg-white tw-rounded-md tw-p-3">
                  <span className="tw-text-gray-800 tw-font-bold">OR:</span>
                  <p className="tw-text-sm">Broaden the results, including members who meet any of the specified criteria.</p>
                </div>
              </div>
              <p className="tw-text-sm tw-mt-3">
                This flexibility enables detailed segmentation, perfect for customized outreach and program management.
              </p>
            </div>
          </div>
        </div>
  
        {/* Footer */}
        <div className=" tw-bg-gray-50 tw-border-t tw-flex tw-justify-center">
          <button 
            onClick={() => setShowTraitsModal(false)}
            className="btn btn-success uplaod-file-btn"
          >
            Got it
          </button>
        </div>
      </div>
    </div>
  );

  // const filterOptions = [
  //   'customer_id', 'User Id', 'Phone Number', 'Email', 'Name', 'Created On',
  //   'Country Code', 'row_number', 'null', 'marked_spam', 'City', 'Area'
  // ];

  // const conditionOptions = [
  //   'is', 'is not', 'contains', 'does not contain', 'is unknown', 'has any value'
  // ];

  // const handleAddFilter = () => {
  //   setShowFilterDropdown(true);
  // };

  // const handleSelectFilter = (filter) => {
  //   setCurrentFilter({ name: filter, condition: null });
  //   setShowFilterDropdown(false);
  //   setShowConditionDropdown(true);
  // };

  // const handleSelectCondition = (condition) => {
  //   setCurrentFilter({ ...currentFilter, condition });
  //   setShowConditionDropdown(false);
  //   setSelectedFilters([...selectedFilters, { ...currentFilter, condition }]);
  //   setCurrentFilter(null);
  // };

  // const handleRemoveFilter = (index) => {
  //   setSelectedFilters(selectedFilters.filter((_, i) => i !== index));
  // };

  return (
    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
      <h3 className="tw-text-xl tw-font-semibold tw-mb-4">Who will receive this message</h3>
      
      <div className="tw-border tw-border-gray-300 tw-rounded-lg tw-mb-4">
        <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-shadow-md">
          <h3 className="tw-text-xl tw-font-bold tw-mb-4">Filter Members <span className="tw-text-red-500 tw-text-base tw-font-medium">*</span></h3>
          
          <div className="tw-mb-2 tw-flex tw-items-center">
            <span className="tw-text-gray-700 tw-mr-2">FILTER BY MEMBERS TRAITS / EVENTS:</span>
              {/* <span 
              className="tw-bg-white border tw-border-black tw-px-1 tw-py-0.5 tw-text-xs hover:tw-bg-black hover:tw-text-white tw-rounded-lg tw-cursor-pointer tw-transition-all tw-duration-300"
              onClick={() => setShowTraitsModal(true)}
            >
             <i className="bi bi-info-circle tw-mr-1"></i> info
            </span> */}
            <span onClick={() => setShowTraitsModal(true)} className='tw-text-gray-500 '>Click <span className="tw-text-sky-700 tw-cursor-pointer">here</span> to know more about filters.</span>
          </div>
        <div className="">
            <AdvancedFilter 
              initialQueries={initialQueries}
              fields={filterFields}
              onFilter={handleFilterApply}
              maxQueries={4}
              customClassName="tw-mb-4"
              ytdStart={moment().startOf('year')}
              ytdEnd={moment()}
              totalCountUserList={totalCountUserList}
            />
          </div>
        {/* <div className="tw-flex tw-items-center">
          <label className="tw-flex tw-items-center">
            <input type="checkbox" className="tw-mr-2 form-check-input" />
            <span className="tw-text-sm tw-text-gray-700 tw-mt-1">Only include customers whose 'WhatsApp Opted' is true</span>
          </label>
        </div> */}
      
      
        </div>


        {/* <div className="tw-p-4 tw-border-t tw-border-gray-300 tw-bg-gray-50">
          <button className="tw-w-full tw-py-2 tw-bg-gray-200 tw-text-gray-700 tw-rounded-lg tw-font-semibold">
            Apply Filters
          </button>
        </div> */}
      </div>
      
      {users.length > 0 && (
        <>
         
        <p className="tw-text-lg tw-font-semibold tw-mb-4">{totalCountUserList} Members Filtered</p>
       {/* {TableComponent()} */}
      </>
      )}
      {/* {showTagsModal && <TagsInfoModal />} */}
      {showTraitsModal && <TraitsEventsInfoModal />}
    </div>
  );
};

export default ChooseAudience;