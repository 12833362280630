import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import 'alertifyjs/build/css/alertify.css';
import { LoaderLoader } from '../../../utils/CommonLibrary';
import Line from '../graphs/Line';
import Pie from '../graphs/Pie';
import MetricsFreeTextInsights from './MetricsFreeTextInsights';


const MetricsInsights = (props) => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [message, setMessage] = useState("");

    return (
        <>
            <LoaderLoader isLoading={loading} />
            <Modal id="metricsInsights" show={props.showMetricsInsightsModal} centered onHide={() => props.setShowMetricsInsightsModal(false)} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Insights - {props.metricsInsightsArr.conditionName}</h1>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        {
                            props.metricsInsightsArr.metrics.map((item, index) => {
                                return <>

                                    <Col lg="12" className=' mb-4'>
                                        <div className='dashboard-graph-box card-task p-2 ps-1'>
                                            {
                                                item.inputType == "numeric" &&
                                                <Line lineGraphTitle={item.metricsName} lineGraphType="metrics_insights_numeric_data" userId={props.userId} refreshVar={props.refreshVar} metricsId={item.metricsId} />
                                            }
                                            {
                                                item.inputType == "select" &&
                                                <Pie pieGraphTitle={item.metricsName} pieGraphType="metrics_insights_select_data" userId={props.userId} refreshVar={props.refreshVar} metricsId={item.metricsId} />
                                            }
                                            {
                                                item.inputType == "freetext" &&
                                                <MetricsFreeTextInsights userId={props.userId} metricsId={item.metricsId} metricsName={item.metricsName} />
                                            }
                                        </div>
                                    </Col>

                                </>
                            })
                        }
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MetricsInsights