import {configureStore} from '@reduxjs/toolkit';
import webexSlice from './webexSlice'; 
import memberSlice from './memberSlice';
import paginationSlice from './paginationSlice';
import filterSlice from './fitler/observationFitlerSlice'


const store = configureStore({
    reducer: {
        webex: webexSlice, 
        member: memberSlice, 
        pagination: paginationSlice, 
        filter: filterSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});


export default store; 