import React, { useState, useEffect, useRef } from 'react';
import DataTable from 'react-data-table-component';
import { fetch_call_history_list, getCareNavigators } from '../../../services/medengage/Service';
import { LoaderLoader, exportExcelTableData } from '../../utils/CommonLibrary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { formatDate } from '../../utils/CommonLibrary';
import { toast } from "react-toastify";
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import { NotificationToast } from '../../componenents/ContentCareNavigator/NotificationToast';
import CommunicationAudioPlayer from '../../met/membercommunication/CommunicationAudioPlayer';

function CNCallHistoryTable({ startDate, endDate }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedCN, setSelectedCN] = useState('');
    const [careList, setCareList] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [communicationData, setCommunicationData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [recordUrl, setRecordUrl] = useState('');
    const [cnCallName, setCNCallName] = useState('');
    const [memberCallName, setMemberCallName] = useState('');
    const [randomKey, setRandomKey] = useState(0);
    const [timestamp, setTimestamp] = useState(Date.now());

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("dateScheduled");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [stats, setStats] = useState({
        totalCalls: 0,
        completedCalls: 0,
        missedCalls: 0,
        memberNoShowCalls: 0,
        cancelledCalls: 0,
        futureCalls: 0,
        scheduledModeScheduledCount: 0,
        scheduledModeDirectCount: 0,
        newJoinerCount: 0,
        followUpCount: 0
    });

    const audioRef = useRef(null);

    useEffect(() => {
        fetchCareNavigatorData();
    }, [])

    useEffect(() => {
        fetchMemberCommunicationData();
    }, [page, perPage, sortColumn, sortDirection, searchQuery, startDate, endDate, selectedCN, selectedStatus, download]);


    useEffect(() => {
        if (audioRef.current && recordUrl) {
            audioRef.current.load();
        }
    }, [recordUrl])


    const handleStop = () => {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setRecordUrl('');
    };

    const fetchCareNavigatorData = async () => {
        try {
            getCareNavigators()
                .then((response) => {
                    setCareList(response.data.data.users);
                });
        } catch (error) {
            console.error(error);
        }
    };

    const fetchMemberCommunicationData = async () => {
        try {
            setIsLoading(true);
            let sdate = formatDate(startDate);
            let edate = formatDate(endDate);

            await fetch_call_history_list(searchQuery, selectedCN, selectedStatus, sdate, edate,
                page, perPage, sortColumn, sortDirection, download)
                .then((response) => {
                    if (response.data.code == 200) {
                        if (download == "N") {
                            setCommunicationData(response.data.data.logs);
                            setTotalRows(response.data.data.totalRows);
                            setStats(response.data.data.stats);
                        } else {
                            if (response.data.data.status == 1) {
                                toast.success(response.data.data.msg, {
                                    position: 'top-right'
                                })
                            } else {
                                toast.error(response.data.data.msg, {
                                    position: 'top-right'
                                })
                            }
                            setDownload('N');
                        }
                    }
                    setIsLoading(false);
                });
        } catch (error) {
            console.error(error);
        }
    };

    const handlePlay = (e, itemName) => {
        let allAudios = document.querySelectorAll('.call_recording_audio');

        // pause other audio's except current one 
        Array.from(allAudios).forEach((item) => {
            let name = item.getAttribute('name');
            if (name !== itemName) {
                item.pause();
            }
        });
    }

    const playAudioRecording = async (recordingUrl, cnName, memberName) => {
        try {
            setRecordUrl(recordingUrl);
            setRandomKey(recordingUrl);
            setCNCallName(cnName);
            setMemberCallName(memberName);
        } catch (error) {
            console.error('Error fetching audio data:', error);
        }
    };


    const renderMETP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Unique identifier for the membernoshow.
        </Tooltip>
    );
    const renderMETP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Main Membership No for the membernoshow.
        </Tooltip>
    );

    const renderMETP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the membernoshow.
        </Tooltip>
    );

    const renderMETP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the Agent.
        </Tooltip>
    );


    const renderMETP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The method used for communication
        </Tooltip>
    );

    const renderMETP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The date and time when the communication was sent or made.
        </Tooltip>
    );


    const renderMETP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            The method used for communication status
        </Tooltip>
    );

    const renderMETP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Click to listen to the recorded communication message.
        </Tooltip>
    );



    const columns = [
        {
            name: (
                <span>Member ID</span>
            ),
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: (
                <span>Main Membership No</span>
            ),
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: (
                <span>Member Name</span>
            ),
            selector: row => row.memberName,
            sortable: false,
        },
        {
            name: (
                <span>CareNavigator Name</span>
            ),
            selector: row => row.cnName,
            sortable: false,
        },
        {

            name: (
                <span>Supervisor Name</span>
            ),
            selector: row => row.supervisorName,
            sortable: false,
        },
        {

            name: (
                <span>Scheduled By</span>
            ),
            selector: row => row.scheduledBy,
            sortable: false,
        },
        {
            name: (
                <span>Call Scheduled Date</span>
            ),
            selector: row => row.dateScheduled,
            sortable: true,
        },
        {
            name: (
                <span>Call Made On</span>
            ),
            selector: row => row.callMadeOn,
            sortable: false,
        },
        {
            name: (
                <span>Call Mode</span>
            ),
            selector: row => row.callIs,
            sortable: true,
        },
        {
            name: (
                <span>Call <br />Status</span>
            ),
            selector: row => row.callCurrentStatus,
            sortable: true,
        },
        {
            name: (
                <span>Call <br />Type</span>
            ),
            selector: row => row.callReasonMain,
            sortable: false,
        },
        {
            name: (
                <span>Call <br />Sub-Type</span>
            ),
            selector: row => row.callReasonSub,
            sortable: false,
        },
        {
            name: (
                <span>Streams</span>
            ),
            selector: row => row.streams,
            sortable: false,
        },
        {
            name: (
                <span>Internal <br />Notes</span>
            ),
            selector: row => row.internalNotes,
            sortable: false,
        },
        {
            name: (
                <span>External <br />Notes</span>
            ),
            selector: row => row.externalNotes,
            sortable: false,
        },
        {
            name: (
                <span>Call Playback</span>
            ),
            //selector: row => row.recordingUrl,
            sortable: false,
            cell: row => (
                row.callDuration > 0 ?
                    <a data-bs-toggle="modal" data-bs-target="#audioPopupModal" style={{ cursor: "pointer" }} onClick={(e) => playAudioRecording(row.recordingUrl, row.cnName, row.memberName)}><i style={{ fontSize: '30px' }} class="bi bi-play-circle-fill"></i></a>
                    : "-"
            ),
        },
        {
            name: (
                <span>Call <br />Duration</span>
            ),
            selector: row => row.duration,
            sortable: false,
        },
        {
            name: (
                <span>Sentiment Analysis</span>
            ),
            selector: row => row.sentimentAnalysis,
            sortable: false,
            /*cell: row => (
                <span>
                    {row.sentimentAnalysis || "-"}
                </span>
            ),*/
        },
        {
            name: (
                <span>Call<br />MadeFrom</span>
            ),
            selector: row => row.callFrom,
            sortable: false,
            /*cell: row => (
                <span>
                    {row.sentimentAnalysis || "-"}
                </span>
            ),*/
        }
    ];
    const data = communicationData;

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedCN('');
        setSelectedStatus('');
    };

    /*const filteredData = data.filter(user => {
        const matchesSearchQuery =
            (typeof user.memberName === 'string' && user.memberName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (typeof user.membershipNo === 'string' && user.membershipNo.toString().includes(searchQuery));


        //const matchChannel = selectedChannel === 'All' || user.communicationType === selectedChannel;

        const matchStatus = selectedStatus === 'All' || user.status === selectedStatus;

        return matchesSearchQuery && matchChannel && matchStatus;
    });*/

    const handleExport = (downloadType) => {

    };

    return (
        <>
            <NotificationToast />


            <div className='container-fluid'>

                <div className='row mb-3'>

                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedCN}
                            onChange={e => {
                                setPage(1);
                                setSelectedCN(e.target.value);
                            }}
                            className='form-select input-field-ui'
                        >
                            <option value="">--Select Care Navigator--</option>
                            {careList.length > 0 && careList.map(option => (
                                <option key={option.id} value={option.id}>
                                    {option.userName}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className='col-md-3 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">--Select Status--</option>
                            <option value="completed">Completed</option>
                            <option value="missed">Missed</option>
                            <option value="membernoshow">Member No-Show</option>
                            <option value="scheduled">Scheduled</option>
                            <option value="cancelled">Cancelled</option>
                        </select>
                    </div>

                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <div className="dropdown">
                            <button className="btn btn-primary btn-primary clear-search-btn " type="button" onClick={() => setDownload('Y')}>
                                <i className="bi bi-download"></i> Export
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content-ProgramPerformance mb-3">
                <div className="row text-center px-2">
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.totalCalls}</h1>
                                        <h5 className="card-text1">Total Calls
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.completedCalls}</h1>
                                        <h5 className="card-text1">Completed
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.missedCalls}</h1>
                                        <h5 className="card-text1">Missed
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.memberNoShowCalls}</h1>
                                        <h5 className="card-text1">Member No-Show
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.cancelledCalls}</h1>
                                        <h5 className="card-text1">Cancelled
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.futureCalls}</h1>
                                        <h5 className="card-text1">Future
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.scheduledModeDirectCount}</h1>
                                        <h5 className="card-text1">Direct
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.scheduledModeScheduledCount}</h1>
                                        <h5 className="card-text1">Scheduled
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.newJoinerCount}</h1>
                                        <h5 className="card-text1">New Joiner
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col px-1" >
                        <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col">
                                        <h1 className="card-title1">{stats.followUpCount}</h1>
                                        <h5 className="card-text1">Follow up
                                        </h5>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                (isLoading === true) ?
                    <LoaderLoader isLoading={isLoading} />
                    :
                    <BackendDrivenDataTable
                        data={data}
                        loading={isLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchMemberCommunicationData}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }

            <div className="modal fade" id="audioPopupModal" tabindex="1" aria-labelledby="audioPopupModal" data-backdrop='static' aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div class="modal-header">
                            <h6 class="modal-subtitle mb-2" id="exampleModalLabel">Agent Name:  {memberCallName}</h6>
                            <h6 class="modal-subtitle" id="exampleModalLabel">Member Name:  {cnCallName}</h6>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={handleStop} ></button>
                        </div>
                        <div class="modal-body mt-2">
                            <audio ref={audioRef} controls style={{ width: '100%' }}>
                                <source src={recordUrl} type="audio/mpeg" />
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default CNCallHistoryTable