import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { FaCalendarAlt, FaClock } from 'react-icons/fa';

const ScheduleMessage = ({scheduling,setScheduling,dateTime,setDateTime}) => {
 // const [scheduling, setScheduling] = useState('immediately');
  //const [dateTime, setDateTime] = useState(new Date());

  const handleDateTimeChange = (date) => {
    setDateTime(date);
  };

  // Custom input component
  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div 
      className="tw-flex tw-items-center tw-border tw-border-gray-300 tw-rounded-md tw-px-4 tw-py-2 tw-cursor-pointer tw-bg-white tw-text-gray-700 hover:tw-border-blue-500 focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-blue-500"
      onClick={onClick}
      ref={ref}
    >
      <FaCalendarAlt className="tw-mr-3 tw-text-gray-400" />
      <span>{value}</span>
      <FaClock className="tw-ml-3 tw-text-gray-400" />
    </div>
  ));

  return (
    <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-md">
      <h3 className="tw-text-xl tw-font-semibold tw-mb-4 tw-text-gray-800">Scheduling <span className="tw-text-red-500 tw-text-base tw-font-medium">*</span></h3>
      <h4 className="tw-text-lg tw-font-medium tw-mb-3 tw-text-gray-700">Start sending</h4>
      
      <div className="tw-space-y-2 tw-mb-6">
        <label className="tw-flex tw-items-center tw-cursor-pointer">
          <input
            type="radio"
            name="scheduling"
            value="inprogress"
            checked={scheduling === 'inprogress'}
            onChange={() => setScheduling('inprogress')}
            className="form-check-input"
          />
          <span className="tw-text-gray-700 tw-mt-1">Immediately</span>
        </label>
        <label className="tw-flex tw-items-center tw-cursor-pointer">
          <input
            type="radio"
            name="scheduling"
            value="scheduled"
            checked={scheduling === 'scheduled'}
            onChange={() => setScheduling('scheduled')}
            className="form-check-input"
          />
          <span className="tw-text-gray-700 tw-mt-1">Custom date</span>
        </label>
      </div>
      
      {scheduling === 'scheduled' && (
        <div className="tw-mb-6 tw-relative">
          <style>
            {`
              .react-datepicker-wrapper {
                display: block;
                width: 50%;
                border: 1px solid #fff
              }
              .react-datepicker__input-container {
                display: block;
                width: 100%;
              }
              .react-datepicker {
                font-family: inherit;
                border: 1px solid #e5e7eb;
                border-radius: 0.375rem;
                background-color: white;
                box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
              }
              .react-datepicker-popper {
                z-index: 10;
              }
              .react-datepicker__time-container {
                border-left: 1px solid #e5e7eb;
              }
              .react-datepicker__header {
                background-color: #f9fafb;
                border-bottom: 1px solid #e5e7eb;
                padding: 8px;
              }
              .react-datepicker__current-month {
                font-weight: 600;
                font-size: 0.875rem;
              }
              .react-datepicker__day--selected {
                background-color: #2563eb;
                color: white;
              }
              .react-datepicker__day:hover {
                background-color: #e5e7eb;
              }
              .react-datepicker__time-list-item--selected {
                background-color: #2563eb !important;
                color: white !important;
              }
            `}
          </style>
          <DatePicker
            selected={dateTime}
            onChange={handleDateTimeChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="MMMM d, yyyy h:mm aa"
            customInput={<CustomInput />}
            portalId="root"
            popperClassName="date-picker-popper"
            wrapperClassName="date-picker-wrapper"
          />
        </div>
      )}
      
      <p className="tw-text-sm tw-text-yellow-600 tw-mt-2">
        The time shown above is based on local time zone
      </p>
    </div>
  );
};

export default ScheduleMessage;