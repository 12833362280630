import React, { useEffect, useState } from 'react';
import axios from "axios";
import DataTable from 'react-data-table-component';
import { carenavigatorAppointmentsOverviewListDetails, } from '../../../services/medengage/Service';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { formatDate } from '../../utils/CommonLibrary';
import { format } from 'date-fns';
import { CustomLoader } from '../../utils/CommonLibrary';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';

function CareNavigatorAppointmentsList(props) {

    const selectedCN = props.selectedCN;
    const selectedSupervisor = props.selectedSupervisor;
    const startDate = props.startDate;
    const endDate = props.endDate;

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('');
    const [selectedCondition, setSelectedCondition] = useState('');
    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [newMembersDisenrollList, setNewMembersDisenrollList] = useState([])
    const [data, setData] = useState([]);
    const [isNewLoading, setIsNewLoading] = useState(false);

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("logDate");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');


    useEffect(() => {
        fetchListDetails();
    }, [page, perPage, sortColumn, sortDirection, searchQuery, startDate, endDate, selectedCN, selectedSupervisor, download]);


    const fetchListDetails = async () => {
        try {
            setIsNewLoading(true);
            carenavigatorAppointmentsOverviewListDetails(searchQuery, startDate, endDate, selectedCN, selectedSupervisor,
                page, perPage, sortColumn, sortDirection, download)
                .then((response) => {
                    if (response.data.code == 200) {
                        if (download == "N") {
                            setData(response.data.data.logs);
                            setTotalRows(response.data.data.totalRows);
                        } else {
                            handleExport(response.data.data.logs);
                            setDownload('N');
                        }
                    }
                    setIsNewLoading(false);
                });
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    };
    const columns = [
        {
            name: 'Date',
            selector: row => row.logDate,
            format: row => row.logDate,
            sortable: true,
        },
        {
            name: 'CareNavigator Name',
            selector: row => row.cnName,
            sortable: true,
        },
        {
            name: 'Supervisor',
            selector: row => row.supervisorName,
            sortable: true,
        },
        {
            name: 'Total Slots',
            selector: row => row.totalSlots,
            sortable: false,
        },
        {
            name: 'Scheduled Appointments',
            selector: row => row.callsTotalScheduled,
            sortable: false,
        },
        {
            name: 'Direct Appointments',
            selector: row => row.callsTotalDirect,
            sortable: false,
        },
        {
            name: 'Completed',
            selector: row => row.callsTotalCompleted,
            sortable: false,
        },
        {
            name: 'Missed',
            selector: row => row.callsTotalMissed,
            sortable: false,
        },
        {
            name: 'Cancelled',
            selector: row => row.callsTotalCancelled,
            sortable: false,
        },
        {
            name: 'Member No-Show',
            selector: row => row.callsTotalMemberNoShow,
            sortable: false,
        },
        {
            name: 'Capacity Utilization',
            selector: row => row.capacityUtilization,
            sortable: false,
        },
        {
            name: 'Completion Rate',
            selector: row => row.completionRate,
            sortable: false,
        }
    ];




    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('');
        setSelectedCondition('');
    };

    const filteredData = newMembersDisenrollList.filter(user => {
        const matchesSearchQuery =
            user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) || user.membershipNo == searchQuery;

        const matchesProgram = selectedProgram === 'All' || user.carePrograms.toLowerCase() === selectedProgram.toLowerCase();
        const matchesCondition = selectedCondition === 'All' || user.chronicConditions.toLowerCase() === selectedCondition.toLowerCase();
        return matchesSearchQuery && matchesProgram && matchesCondition;
    });

    const handleExport = (data) => {

        const modifiedData = data.map(item => {
            return {
                'Date': item.logDate,
                'CareNavigator Name': item.cnName,
                'Supervisor': item.supervisorName,
                'Total Slots': item.totalSlots,
                'Total Appointment Scheduled': item.callsTotalScheduled,
                'Completed': item.callsTotalCompleted,
                'Missed': item.callsTotalMissed,
                'Cancelled': item.callsTotalCancelled,
                'Member No-Show': item.callsTotalMemberNoShow,
                'Capacity Utilization': item.capacityUtilization,
                'Completion Rate': item.completionRate
            };
        });


        modifiedData.forEach(function (v) { delete v.disEnrollBy; delete v.logId; delete v.membershipNo; delete v.mobile; delete v.userId; });

        exportTableData("AppointmentOverview", modifiedData);
    };

    return (
        <>
            <LoaderLoader isLoading={isNewLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by CareNavigator Name"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                    </div>
                    <div className='col-md px-1'>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={() => setDownload('Y')} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            {
                isNewLoading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={data}
                        loading={isNewLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchListDetails}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
        </>
    );
}

export default CareNavigatorAppointmentsList