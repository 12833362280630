import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import { fetchUsersList, fetchFamilyList, fetchFiltersList } from "../../components/services/Service";
import './UsersList.css'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import AddMember from '../addmember/AddMember';
import SearchExternalMember from '../members/SearchExternalMember';



const UsersList = ({ onUserClick }) => {
    const [defaultFilterObject, setDefaultFilterObject] = useState({
        "label": "All",
        "filterId": "",
        "filterType": "all",
        "count": 0
    })
    const [tasksView, setTasksView] = useState("allMembers");
    const [filtersList, setFiltersList] = useState([]);
    const [showFiltersList, setShowFiltersList] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState(defaultFilterObject);
    const [selectParentFilter, setSelectParentFilter] = useState("");
    const [appliedFilter, setAppliedFilter] = useState({});

    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [query, setQuery] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [users, setUsers] = useState([]);
    const [families, setFamilies] = useState([]);
    const [usercount, setUserCount] = useState(0);
    const [hasMore, setHasMore] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [callfetchuser, setCallfetchuser] = useState(false);

    const observer = useRef();
    const lastUserElementRef = useCallback(node => {
        if (loading) return
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMore) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
            }
        })
        if (node) observer.current.observe(node)
    }, [loading, hasMore]);

    useEffect(() => {
        setPageNumber(1);
        setUsers([])
        setFamilies([])
    }, [query, tasksView])

    useEffect(() => {
        setLoading(true);
        setError(false);
        if (tasksView == "allMembers") {
            getUsersList(appliedFilter, query, pageNumber)
        } else {
            getFamilyList(appliedFilter, query, pageNumber)
        }

    }, [query, appliedFilter, pageNumber, callfetchuser, tasksView]);

    const getUsersList = async (appliedFilter, query, pageNumber) => {
        const request = axios.CancelToken.source()
        let ct = {
            cancelToken: request.token
        }
        await fetchUsersList(appliedFilter, query, pageNumber, ct)
            .then((result) => {
                if (result.data.data.users) {
                    setUserCount(result.data.data.userCount)

                    /*if (Object.keys(appliedFilter).length === 0 && appliedFilter.constructor === Object) {
                        let dfo = { ...defaultFilterObject }
                        dfo.count = result.data.data.userCount
                        if (users.length === 0) {
                            setDefaultFilterObject(dfo)
                        }
                    }*/

                    /*setUsers(prevUsers => {
                        return [...prevUsers, ...result.data.data.users]
                    })*/

                    setUsers(prevUsers => {
                        const newUsers = (result.data.data.users).filter(user =>
                            !prevUsers.some(existingUser => existingUser.userId === user.userId)
                        );
                        return [...prevUsers, ...newUsers];
                    });

                    if (result.data.data.users.length > 0) setHasMore(true);
                    else setHasMore(false);
                    setLoading(false);
                }
            }).catch(function (error) {
                if (axios.isCancel(error)) return true;
                setError(true);
            });
        return () => request.cancel()
    }

    const getFamilyList = async (appliedFilter, query, pageNumber) => {
        const request = axios.CancelToken.source()
        let ct = {
            cancelToken: request.token
        }
        await fetchFamilyList(appliedFilter, query, pageNumber, ct)
            .then((result) => {
                if (result.data.data.users) {
                    setUserCount(result.data.data.userCount)

                    setFamilies(prevUsers => {
                        const newUsers = (result.data.data.users).filter(user =>
                            !prevUsers.some(existingUser => existingUser.userId === user.userId)
                        );
                        return [...prevUsers, ...newUsers];
                    });

                    if (result.data.data.users.length > 0) setHasMore(true);
                    else setHasMore(false);
                    setLoading(false);
                }
            }).catch(function (error) {
                if (axios.isCancel(error)) return true;
                setError(true);
            });
        return () => request.cancel()
    }

    function handleSearch(e) {
        setQuery(e.target.value);
        setPageNumber(1);
    }

    const handleFilterContainerClick = async () => {
        if (filtersList.length <= 0) {
            const request = axios.CancelToken.source()
            let ct = {
                cancelToken: request.token
            }
            await fetchFiltersList(ct)
                .then((response) => {
                    let fl = [];
                    fl.push(defaultFilterObject)
                    fl.push(...response.data.data.filters)
                    setFiltersList(fl)
                    setShowFiltersList(true);
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel()
        } else {
            setShowFiltersList(!showFiltersList)
        }
    }

    const handleParentFilter = (label) => {
        if (selectParentFilter === label) {
            setSelectParentFilter("")
        } else {
            setSelectParentFilter(label)
        }
    }

    const handleAppliedFilter = (filter) => {
        setPageNumber(1)
        setDefaultFilterObject(filter)
        setShowFiltersList(!showFiltersList)
        setAppliedFilter(filter);
        setUsers([])
    }


    const handleShowMemberProfileDetailsModal = () => {
        setShowModal(true);
    }

    const [showSearchExternalMemberModal, setShowSearchExternalMemberModal] = useState(false);
    const closeSearchExternalMemberModal = () => {
        setShowSearchExternalMemberModal(false);
    }


    return (
        <>
            <div className="userslist">
                <div>
                    <div className='row align-items-center d-flex'>
                        <div className='col-auto pe-2'>
                            <h2 className='card-member-title'>Members</h2>
                        </div>
                        <div className='col px-0'>
                            <Link class="badge rounded-pill btn btn-outline-secondary add-new-member-btn" onClick={handleShowMemberProfileDetailsModal}>
                                Add Member
                            </Link>
                        </div>
                        <div className='col text-end ps-0'>
                            <span class="badge rounded-pill btn btn-outline-secondary">
                                <span className='gray-small hand-cursor' onClick={() => {
                                    if (users.length > 0 && query !== "") setUsers([]);
                                    if (query !== "") setQuery("");
                                    if (pageNumber !== 1) setPageNumber(1);
                                }}>View All</span>
                                <span className='orange-small ps-2'>{usercount}</span>
                            </span>

                        </div>
                    </div>

                </div>
                <div className="row align-items-center">
                    <div className="col-12 px-1">
                        <div className="tabs">
                            <a
                                className={`tab ${tasksView === "allMembers" ? "active-tab" : ""}`}
                                onClick={() => {
                                    setQuery("");
                                    setTasksView("allMembers")
                                }}
                            >
                                All Members
                            </a>
                            <a
                                className={`tab ${tasksView === "family" ? "active-tab" : ""}`}
                                onClick={() => {
                                    setQuery("");
                                    setTasksView("family");
                                }}
                            >
                                Family
                            </a>
                        </div>
                    </div>
                </div>
                <div className='row align-items-center'>
                    {/* <div className='tw-w-1/4'>
                        <div className="search tw-p-0 mb-0">
                            <select className='tw-border-0 tw-bg-transparent tw-w-full tw-h-full tw-rounded-xl tw-px-1 tw-py-2.5'>
                                <option value="all">All</option>
                                <option value="read">Read</option>
                                <option value="unread">Unread</option>
                            </select>
                        </div>
                    </div> */}
                    <div className='col'>
                        <div className="search mb-0">
                            <i className="fa fa-search" aria-hidden="true"></i>
                            <input type="text" placeholder='Search' value={query} onChange={handleSearch}></input>
                        </div>
                    </div>
                    <div className='col-auto border-start'>
                        <span class="badge rounded-pill btn btn-outline-secondary" onClick={() => setShowSearchExternalMemberModal(true)}>
                            <i className="fa fa-search all-members gray-small hand-cursor ms-0" aria-hidden="true" ></i>
                        </span>
                    </div>
                </div>

                <div id="allMembers" className={`${tasksView === "allMembers" ? "" : "hide"}`}>
                    <div className='userlistname'>
                        {
                            users.map((user, index) => {
                                if (users.length === index + 1) {
                                    return <Row key={index} ref={lastUserElementRef} className='align-items-center mb-2'>
                                        <div className="col-4 col-md-auto">
                                            <Link to={'/members/' + encodeURIComponent(user.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: user.userId, name: user.memberName })}>
                                                <img src={user.imageUrl} alt={user.memberName} className="profile-img"></img>
                                            </Link>
                                        </div>
                                        <div className="col col-md ps-0 tw-relative">
                                            <Link to={'/members/' + encodeURIComponent(user.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: user.userId, name: user.memberName })}>{user.memberName}</Link>
                                            <h5 className='text-small'><span className=''>Main Membership: {user.mainMembershipNumber}</span></h5>
                                            <h5 className='text-small'><span className=''>Membership: {user.membershipNumber}</span></h5>
                                            <h5 className='text-small'><span className=''>{user.age}</span>, <span className='ps-1'>{user.gender}</span>, <span className='ps-1'>{user.city}</span></h5>
                                            {
                                                user.pathways.map((pathway, pIndex) => {
                                                    return <h5 className='text-small'><span className='pe-2'>{pathway.conditionName} / {pathway.pathwayName}</span></h5>
                                                })
                                            }
                                            {
                                                user.pathways.length <= 0 &&
                                                <h5 className='text-small'><span className='pe-2'>-</span></h5>
                                            }
                                            {/* <OverlayTrigger placement='left' overlay={<Tooltip>Unread Message</Tooltip>}>
                                            <span className='tw-absolute tw-right-4 tw-top-[40%] border tw-flex tw-items-center tw-text-white tw-bg-gray-500 tw-px-1.5 tw-py-0.5 tw-rounded-full tw-text-xs'>1</span>
                                            </OverlayTrigger> */}
                                        </div>
                                    </Row>
                                } else {
                                    return <Row key={index} className='align-items-center mb-2'>
                                        <div className="col-4 col-md-auto">
                                            <Link to={'/members/' + encodeURIComponent(user.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: user.userId, name: user.memberName })}>
                                                <img src={user.imageUrl} alt={user.memberName} className="profile-img"></img>
                                            </Link>
                                        </div>
                                        <div className="col col-md ps-0 tw-relative">
                                            <Link to={'/members/' + encodeURIComponent(user.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: user.userId, name: user.memberName })}>{user.memberName}</Link><br />
                                            <h5 className='text-small'><span className=''>Main Membership: {user.mainMembershipNumber}</span></h5>
                                            <h5 className='text-small'><span className=''>Membership: {user.membershipNumber}</span></h5>
                                            <h5 className='text-small'><span className=''>{user.age}</span>, <span className='ps-1'>{user.gender}</span>, <span className='ps-1'>{user.city}</span></h5>
                                            {
                                                user.pathways.map((pathway, pIndex) => {
                                                    return <h5 className='text-small'><span className='pe-2'>{pathway.conditionName} / {pathway.pathwayName}</span></h5>
                                                })
                                            }
                                            {
                                                user.pathways.length <= 0 &&
                                                <h5 className='text-small'><span className='pe-2'>-</span></h5>
                                            }
                                            {/* <OverlayTrigger placement='left' overlay={<Tooltip>Unread Message</Tooltip>}>
                                            <span className='tw-absolute tw-right-4 tw-top-[40%] border tw-flex tw-items-center tw-text-white tw-bg-gray-500 tw-px-1.5 tw-py-0.5 tw-rounded-full tw-text-xs'>1</span>
                                            </OverlayTrigger> */}
                                        </div>
                                    </Row>
                                }
                            })
                        }

                    </div>
                </div>

                <div id="family" className={`${tasksView === "family" ? "" : "hide"}`}>
                    <div className='userlistname'>
                        {
                            families.map((family, index) => {
                                if (families.length === index + 1) {
                                    return <Row ref={lastUserElementRef} key={index} className='align-items-center mb-2'>
                                        <div className="col-4 col-md-auto">
                                            <Link to={'/members/' + encodeURIComponent(family.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: family.userId, name: family.memberName })}>
                                                <img src={family.imageUrl} alt={family.memberName} className="profile-img"></img>
                                            </Link>
                                        </div>
                                        <div className="col col-md ps-0 tw-relative">
                                            <Link to={'/members/' + encodeURIComponent(family.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: family.userId, name: family.memberName })}>{family.memberName}</Link><br />
                                            <h5 className='text-small'><span className=''>Main Membership: {family.mainMembershipNumber}</span></h5>
                                            <h5 className='text-small'><span className=''>Membership: {family.membershipNumber}</span></h5>
                                            <h5 className='text-small'><span className=''>Membership Type: {family.policyMemberType}</span></h5>
                                            <h5 className='text-small'><span className=''>{family.age}</span>, <span className='ps-1'>{family.gender}</span>, <span className='ps-1'>{family.city}</span></h5>
                                            {
                                                family.pathways.map((pathway, pIndex) => {
                                                    return <h5 className='text-small'><span className='pe-2'>{pathway.conditionName} / {pathway.pathwayName}</span></h5>
                                                })
                                            }
                                            {
                                                family.pathways.length <= 0 &&
                                                <h5 className='text-small'><span className='pe-2'>-</span></h5>
                                            }
                                            {/* <OverlayTrigger placement='left' overlay={<Tooltip>Unread Message</Tooltip>}>
                                            <span className='tw-absolute tw-right-4 tw-top-[40%] border tw-flex tw-items-center tw-text-white tw-bg-gray-500 tw-px-1.5 tw-py-0.5 tw-rounded-full tw-text-xs'>1</span>
                                            </OverlayTrigger> */}
                                        </div>
                                        {
                                            family.family.map((familyMember, index) => {
                                                return <Row key={index} className='align-items-center mb-2 ms-5 member-connect-line' style={{ position: "relative" }}>
                                                    <div className="col-4 col-md-auto">
                                                        <Link to={'/members/' + encodeURIComponent(familyMember.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: familyMember.userId, name: familyMember.memberName })}>
                                                            <img src={familyMember.imageUrl} alt={familyMember.memberName} className="profile-img"></img>
                                                        </Link>
                                                    </div>
                                                    <div className="col col-md ps-0 tw-relative">
                                                        <Link to={'/members/' + encodeURIComponent(familyMember.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: familyMember.userId, name: familyMember.memberName })}>{familyMember.memberName} </Link><br />
                                                        <h5 className='text-small'><span className=''>Main Membership: {family.mainMembershipNumber}</span></h5>
                                                        <h5 className='text-small'><span className=''>Membership: {familyMember.membershipNumber}</span></h5>
                                                        <h5 className='text-small'><span className=''>Membership Type: {familyMember.policyMemberType}</span></h5>
                                                        <h5 className='text-small'><span className=''>{familyMember.age}</span>, <span className='ps-1'>{familyMember.gender}</span>, <span className='ps-1'>{familyMember.city}</span></h5>
                                                        {
                                                            familyMember.pathways.map((pathway, pIndex) => {
                                                                return <h5 className='text-small'><span className='pe-2'>{pathway.conditionName} / {pathway.pathwayName}</span></h5>
                                                            })
                                                        }
                                                        {
                                                            familyMember.pathways.length <= 0 &&
                                                            <h5 className='text-small'><span className='pe-2'>-</span></h5>
                                                        }
                                                        {/* <OverlayTrigger placement='left' overlay={<Tooltip>Unread Message</Tooltip>}>
                                                        <span className='tw-absolute tw-right-[61px] tw-top-[40%] border tw-flex tw-items-center tw-text-white tw-bg-gray-500 tw-px-1.5 tw-py-0.5 tw-rounded-full tw-text-xs'>1</span>
                                                        </OverlayTrigger> */}
                                                    </div>
                                                </Row>
                                            })
                                        }
                                    </Row>
                                } else {
                                    return <Row key={index} className='align-items-center mb-2'>
                                        <div className="col-4 col-md-auto">
                                            <Link to={'/members/' + encodeURIComponent(family.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: family.userId, name: family.memberName })}>
                                                <img src={family.imageUrl} alt={family.memberName} className="profile-img"></img>
                                            </Link>
                                        </div>
                                        <div className="col col-md ps-0">
                                            <Link to={'/members/' + encodeURIComponent(family.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: family.userId, name: family.memberName })}>{family.memberName}</Link><br />
                                            <h5 className='text-small'><span className=''>Main Membership: {family.mainMembershipNumber}</span></h5>
                                            <h5 className='text-small'><span className=''>Membership: {family.membershipNumber}</span></h5>
                                            <h5 className='text-small'><span className=''>Membership Type: {family.policyMemberType}</span></h5>
                                            <h5 className='text-small'><span className=''>{family.age}</span>, <span className='ps-1'>{family.gender}</span>, <span className='ps-1'>{family.city}</span></h5>
                                            {
                                                family.pathways.map((pathway, pIndex) => {
                                                    return <h5 className='text-small'><span className='pe-2'>{pathway.conditionName} / {pathway.pathwayName}</span></h5>
                                                })
                                            }
                                            {
                                                family.pathways.length <= 0 &&
                                                <h5 className='text-small'><span className='pe-2'>-</span></h5>
                                            }
                                        </div>
                                        {
                                            family?.family?.map((familyMember, index) => {
                                                return <Row key={index} className='align-items-center mb-2 ms-5 member-connect-line' style={{ position: "relative" }}>
                                                    <div className="col-4 col-md-auto">
                                                        <Link to={'/members/' + encodeURIComponent(familyMember.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: familyMember.userId, name: familyMember.memberName })}>
                                                            <img src={familyMember.imageUrl} alt={familyMember.memberName} className="profile-img"></img>
                                                        </Link>
                                                    </div>
                                                    <div className="col col-md ps-0">
                                                        <Link to={'/members/' + encodeURIComponent(familyMember.userId, "UTF-8")} className='name' onClick={() => onUserClick({ userId: familyMember.userId, name: familyMember.memberName })}>{familyMember.memberName} </Link><br />
                                                        <h5 className='text-small'><span className=''>Main Membership: {family.mainMembershipNumber}</span></h5>
                                                        <h5 className='text-small'><span className=''>Membership: {familyMember.membershipNumber}</span></h5>
                                                        <h5 className='text-small'><span className=''>Membership Type: {familyMember.policyMemberType}</span></h5>
                                                        <h5 className='text-small'><span className=''>{familyMember.age}</span>, <span className='ps-1'>{familyMember.gender}</span>, <span className='ps-1'>{familyMember.city}</span></h5>
                                                        {
                                                            familyMember.pathways.map((pathway, pIndex) => {
                                                                return <h5 className='text-small'><span className='pe-2'>{pathway.conditionName} / {pathway.pathwayName}</span></h5>
                                                            })
                                                        }
                                                        {
                                                            familyMember.pathways.length <= 0 &&
                                                            <h5 className='text-small'><span className='pe-2'>-</span></h5>
                                                        }
                                                    </div>
                                                </Row>
                                            })
                                        }
                                    </Row>
                                }
                            })
                        }
                    </div>
                </div>

            </div >

            {showModal && <AddMember status="add" heading="Add New Member" hideModal={() => {
                setShowModal(false);
                setCallfetchuser(callfetchuser + 1);
            }} />
            }

            {
                showSearchExternalMemberModal && <SearchExternalMember showSearchExternalMemberModal={showSearchExternalMemberModal} closeSearchExternalMemberModal={closeSearchExternalMemberModal} onUserClick={onUserClick} />
            }
        </>
    )
}

export default UsersList    