import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import moment from 'moment';
import 'alertifyjs/build/css/alertify.css';
import { LoaderLoader } from '../../../utils/CommonLibrary';
import DatePicker from 'react-datepicker';
import { addMetricsVals } from "../../components/services/Service";


const MetricsAdd = (props) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState(props.metricsArr);
    const [updateError, setUpdateError] = useState("");
    const [updateMessage, setUpdateMessage] = useState("");

    const handleMetricsValChange = (index, name, newValue, inputType) => {
        setUpdateError("");
        setUpdateMessage("");

        if (inputType === "numeric" && !/^\d*\.?\d*$/.test(newValue)) {
            return;  // Prevent invalid value from updating the state
        }

        setFormData((prevData) => {
            const updatedMetrics = prevData.metrics.map((metric, i) => {
                // Only update the specific index
                if (i === index) {
                    return {
                        ...metric,
                        [name]: newValue
                    };
                }
                return metric;
            });
            return {
                ...prevData,
                metrics: updatedMetrics
            };
        });
    };

    const handleUpdateForm = async () => {
        setUpdateError("");
        setUpdateMessage("");
        let valFound = 0;
        let errorFound = 0;
        for (let i = 0; i < formData.metrics.length; i++) {
            const item = formData.metrics[i];

            // Check if metricsVal exists and is not an empty string
            const metricsValExists = (item.metricsVal?.trim() || "") !== "";
            const metricsDateExists = item.metricsDate && item.metricsDate !== "";

            if (metricsValExists) {
                valFound++;
            }

            // Validate metricsVal and metricsDate
            if (metricsValExists && !metricsDateExists) {
                setUpdateError("Please enter date for " + item.metricsName);
                errorFound++;
                break; // Stop the loop if an error is found
            } else if (!metricsValExists && metricsDateExists) {
                setUpdateError("Please enter value for " + item.metricsName);
                errorFound++;
                break; // Stop the loop if an error is found
            }
        }


        if (valFound === 0 && errorFound == 0) {
            setUpdateError("Please enter at least one value");
            errorFound++;
        }

        if (errorFound === 0) {
            setLoading(true);
            let fd = structuredClone(formData);
            for (let i = 0; i < fd.metrics.length; i++) {
                if (fd.metrics[i].metricsDate) {
                    if ((fd.metrics[i].metricsDate) != "") {
                        fd.metrics[i].metricsDate = (moment(fd.metrics[i].metricsDate).isValid()) ? moment(fd.metrics[i].metricsDate).format('YYYY-MM-DD') : "";
                    }
                }
            }
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await addMetricsVals(fd, ct)
                .then((response) => {
                    setLoading(false);
                    if (response.data.code === 200) {
                        props.setShowMetricsModal(false);
                        props.setRefreshVar(new Date().getTime());
                        props.getMemberBasicInfo();
                    } else {
                        setUpdateError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }
    //console.log(formData);
    return (
        <>
            <LoaderLoader isLoading={loading} />
            <Modal id="metricsAdd" show={props.showMetricsModal} centered onHide={() => props.setShowMetricsModal(false)} size="lg" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>Add Medical Parameters - {props.metricsArr.conditionName}</h1>
                </Modal.Header>
                <Modal.Body>
                    {
                        formData.metrics.map((item, index) => {
                            return <>
                                <div className='row'>
                                    <div className='col-6 mb-3'>
                                        <label className="form-label">{item.metricsName}
                                            {
                                                item.inputType == "numeric" && " (" + item.unit + ")"
                                            }
                                        </label>
                                        {
                                            item.inputType == "numeric" &&
                                            <input type='text' name={`metricsVal_${index}`} value={item.metricsVal} className='form-control control-input-ui' placeholder={`Enter Value`} onChange={(e) => handleMetricsValChange(index, "metricsVal", e.target.value, "numeric")} />


                                        }
                                        {
                                            item.inputType == "freetext" &&
                                            <textarea name={`metricsVal_${index}`} value={item.metricsVal} className='form-control control-input-ui' onChange={(e) => handleMetricsValChange(index, "metricsVal", e.target.value, "freetext")}></textarea>
                                        }
                                        {
                                            item.inputType == "select" &&
                                            <select name={`metricsVal_${index}`} value={item.metricsVal} className='form-control control-input-ui' onChange={(e) => handleMetricsValChange(index, "metricsVal", e.target.value, "select")}>
                                                <option value="">Select</option>
                                                {
                                                    item.possibleValues.map((pval, pindex) => {
                                                        return <option key={pindex} value={pval.value}>{pval.label}</option>
                                                    })
                                                }
                                            </select>
                                        }
                                    </div>
                                    <div className='col-6 mb-3'>
                                        <div className="col-auto">
                                            <label className="form-label">Date</label>
                                        </div>
                                        <div className="col">
                                            <DatePicker
                                                dateFormat="dd-MM-yyyy"
                                                className='form-control control-input-ui'
                                                selected={item.metricsDate || null} // Ensure null when no date is selected
                                                placeholderText="Enter Date" // Use placeholderText for DatePicker
                                                maxDate={new Date()}
                                                onChange={(date) => handleMetricsValChange(index, "metricsDate", date, "date")}
                                                onKeyDown={(e) => e.preventDefault()}
                                                onPaste={(e) => e.preventDefault()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        })
                    }

                    <div className='col-12 text-center'>
                        <div className={`${updateError != "" ? "errorDiv" : "hide"} `}>{updateError}</div>
                        <div className={`${updateMessage != "" ? "messageDiv" : "hide"}`}>{updateMessage}</div>
                        <button type="button" className="btn btn-primary save-btn me-3" onClick={handleUpdateForm}>Save</button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default MetricsAdd