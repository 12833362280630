import React,{useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import TableAddNewServices from './TableAddNewServices';
import { fetchCategoryData, addService, fetchAllDataService,fetchServiceRequestTypeList } from '../../../services/mtp/Service';
import { validateFormServiceAdd } from '../../utils/validationUtils';
import { CustomLoader } from '../../utils/CommonLibrary';

function AddNewServices() {

    const [selectedData, setSelectedData] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [showModal, setShowModal] = useState(false);
    const handleClose = () =>{  setShowModal(false); setErrorMessage(''); setErrors({
        selectedValue: '',
        serviceName: '',
        serviceRequestType: '',
        description: ''
    })}
    const [finalData, setFinalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [serviceRequestData, setServiceRequestData] = useState([]);
    

    const [formData, setFormData] = useState({
        selectedValue: '',
        serviceName: '',
        serviceRequestType: '',
        description: ''
    })

    const [errors, setErrors] = useState({
        selectedValue: '',
        serviceName: '',
        serviceRequestType: '',
        description: ''
    })

    useEffect(() => {
        fetchData();
        fetchAllData();
       fetchServiceRequestTypeData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        fetchCategoryData()
        .then((response) => {   
            setSelectedData(response.data.data);
            setLoading(false);
        });
    }

    const fetchAllData = async () => {
        fetchAllDataService()
        .then((response) => {   
            setFinalData(response.data.data);
        });
    }

    const fetchServiceRequestTypeData = async () => {
        fetchServiceRequestTypeList()
        .then((response) => {   
            setServiceRequestData(response.data.data);
        });
    }

  
    

    const handleChange = (event) => {
        setFormData((prevData) => ({ ...prevData, ['selectedValue']: event.target.value}));
        
    };
   const handleChangeRequest = (event) =>{
    setFormData((prevData) => ({ ...prevData, ['serviceRequestType']: event.target.value}));

   }

    const addNewService = async (e) => {
        e.preventDefault();
        setErrors('')

        const validationErrors = validateFormServiceAdd(formData);
        if (Object.keys(validationErrors).length > 0) {
            setErrors(validationErrors);
        }else{
            addService(formData)
            .then((response) => {   
                if(response.data.code == 200){
                    setShowModal(false);
                    fetchAllData();
                }else{
                    setErrorMessage(response.data.data.message);
                }
            });
        }
    }

    const onValueChange = (e) =>{    
        setFormData({...formData, [e.target.name]: e.target.value});
    }

    const setShowModalOpen = (e) => {
        setFormData('');
        setShowModal(true);
        
    }


  return (
    <>
        <section className='ContentPrograms'>
            <div className="container-fluid px-0 mb-3">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md"> 
                        <h4 className="mb-0 content-main-title">Services</h4>
                    </div>
                    <div className="col-md text-end">
                        <button onClick={setShowModalOpen} className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-card-checklist me-2"></i> Add New Service
                        </button>
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className=" row align-items-center">
                    <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/g8b1m/xlq0d0v48a.jpg" alt="Invited Members icon" style={{height:"140px"}}/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The services module enables you to add and manage new services, which can be included in the pathway plan or assigned by CareNavigators as additional services for members.</p>
                        </div>
                    </div>
                </div>
            <div className='content-programs mb-3'>
            <div className="row">
                <div className="col-md-12">
                    <div className="table-responsive addNewServiceTable">
                        <TableAddNewServices data={finalData} key={finalData.length} serviceRequestData={serviceRequestData} />  
                    </div>
                </div>
            </div>
            </div>       
            
            </div>
            { loading && <CustomLoader /> }
        </section>

{showModal && 
         <div className="modal fade show" id="addNewServiceModal" tabIndex="-1" aria-hidden="false" style={{ display: 'block' }}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="main-title">Add New Service</h4>
                        <button
                            type="button"
                            className="btn-close"
                            onClick={handleClose}
                        ></button>
                    </div>
                    {errorMessage ?(<p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>):null}
                    <div class="modal-body pt-1 mb-2">
                        <form className='' onSubmit={addNewService}> 
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label htmlFor='Condition' class="form-label">Category</label>
                                    <span style={{color:'red'}}> *</span>
                                        <select class="form-select input-field-ui" aria-label="Default select example" value={formData['selectedValue']} onChange={handleChange}>
                                            <option value="Condition">Select Category</option>
                                                {selectedData.length > 0 && selectedData.map((option) => (
                                                    <option key={option.categoryId} value={option.categoryId}>
                                                        {option.categoryName}
                                                    </option>
                                                ))}
                                        </select>
                                        {(errors['selectedValue'] ) && <div className="text-danger">{errors['selectedValue']}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='Condition' class="form-label">Service Name</label>
                                        <span style={{color:'red'}}> *</span>
                                        <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Service Name"
                                        rows="3"
                                        name="serviceName"
                                        onChange={(e) => onValueChange(e)} value={formData['serviceName']}
                                        />
                                        {errors['serviceName'] && <div className="text-danger">{errors['serviceName']}</div>}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <label htmlFor='Description' class="form-label">Description</label>
                                        <textarea
                                        className="form-control input-field-ui"
                                        placeholder="Description"
                                        rows="3"
                                        name="description"
                                        onChange={(e) => onValueChange(e)} value={formData['description']}
                                        />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                    <label htmlFor='request' class="form-label">Service Request Type</label>
                                    <span style={{color:'red'}}> *</span>
                                        <select class="form-select input-field-ui" aria-label="Default select example" name="serviceRequestType" value={formData['serviceRequestType']} onChange={handleChangeRequest}>
                                            <option value="0">Select Service Request Type</option>
                                                {serviceRequestData.length > 0 && serviceRequestData.map((option) => (
                                                    <option key={option.serviceRequestTypeId} value={option.serviceRequestTypeId}>
                                                        {option.serviceRequestTypeName}
                                                    </option>
                                                ))}
                                        </select>
                                        {(errors['serviceRequestType'] ) && <div className="text-danger">{errors['serviceRequestType']}</div>}
                                    </div>
                                    <div className="col-md-12 my-2 text-center">
                                        <button
                                        type="submit"
                                        className="btn btn-primary program-save-btn me-2"
                                        >
                                        Add Service
                                        </button>
                                        {/* <button
                                        type="button"
                                        className="btn btn-secondary clear-btn"
                                        onClick={handleClose}
                                        >
                                        Cancel
                                        </button> */}
                                    </div>
                                </div>
                                </form>
                            </div>
                           
                        </div>
                    </div>
                    <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
                </div> }


                

        
    </>
  );
}

export default AddNewServices;
