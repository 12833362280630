import React, { useState, useEffect } from 'react';
import '../ContentMemberTransferPage.css';
import { useParams } from "react-router-dom";
import { fetchCareTranferDataV2, transferMembers } from '../../../services/medengage/Service';
import { CustomLoader, ModalBoxButton } from '../../utils/CommonLibrary';
import DatePicker from "react-datepicker";
import { useNavigate } from 'react-router-dom';
import MemberTransferTablev2 from './MemberTransferTablev2';

function ContentMemberTransferPage() {

    const { id } = useParams();
    const navigate = useNavigate();
    const [careUserName, setCareUserName] = useState('');
    const [careData, setCareData] = useState([]);
    const [checked, setChecked] = useState([]);
    const [dropdownValues, setDropdownValues] = useState({});
    const [reasonSelect, setReasonSelect] = useState('All');
    const [typeSelect, setTypeSelect] = useState('All');
    const [reasonText, setReasonText] = useState('');
    const [selectError, setSelectError] = useState('');
    const [careError, setCareError] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [modalClass, setModalClass] = useState('md');
    const [modalBody, setModalBody] = useState('');
    const [showModalButton, setShowModalButton] = useState(false);
    const [showSubmit, setShowSubmit] = useState(false);
    const [reloadKey, setReloadKey] = useState(0);
    const [showHide, setShowHide] = useState(false);
    const [filterText, setFilterText] = useState('');

    //Changes related to backend driven data table 
    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("userId");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');

    useEffect(() => {
        if (id != '' && id !== undefined) {
            loadUserData();
        }
    }, [page, perPage, sortColumn, sortDirection, filterText]);
    //=============================================

    const handleClose = () => {
        setShowModal(false);

        //setLoading(true);
        loadUserData();
        setReloadKey(prevKey => prevKey + 1);
    }

    const getNextWeekRange = () => {
        const end = new Date();
        const start = new Date();
        end.setDate(end.getDate() + 7);
        return [start, end];
    };

    const [dateRange, setDateRange] = useState(getNextWeekRange);
    const [startDate, endDate] = dateRange;
    const [dateStartNew, setDateStartNew] = useState(new Date());

    const [errors, setErrors] = useState({
        reasonSe: '',
        reason: '',
        type: '',
        members: ''
    });


    useEffect(() => {

        if (id != '' && id !== undefined) {
            //setLoading(true);
            loadUserData();
        } else {
            //setLoading(false);
            setModalBody('Something Went Wrong!Please Try again later')
            setModalTitle('Alert!');
            setShowModalButton(true);
            setShowModal(true);
        }

    }, []);

    const handleButtonAction = () => {
        navigate('/care-navigator?tab=1', { replace: true });
    };

    const loadUserData = async () => {
        try {
            setCareData([]);
            setLoading(true);
            fetchCareTranferDataV2(id, filterText, page, perPage, sortColumn, sortDirection)
                .then((response) => {
                    if (response.data.data.status == 1) {
                        setCareUserName(response.data.data.careName);
                        setCareData(response.data.data.members);
                        setTotalRows(response.data.data.totalRows)
                        setShowSubmit(true);
                    } else {
                        /*setModalBody(response.data.data.msg)
                        setModalTitle('Alert!');
                        setShowModalButton(true);
                        setShowModal(true);*/
                    }
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000)

                });
        } catch (error) {
            console.error('Error=', error);
        }
    }

    // function formatDate(dateString) {
    //     const date = new Date(dateString);
    //     const year = date.getFullYear();
    //     const month = String(date.getMonth() + 1).padStart(2, '0');
    //     const day = String(date.getDate()).padStart(2, '0');
    //     return `${day}/${month}/${year}`;
    // }

    function formatDateYmd(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleCheckboxChange = (checkedIds) => {
        setChecked(checkedIds);
        if (checkedIds.length > 0) {
            setSelectError('');
        }

    };

    const handleDropdownChange = (id, value) => {

        setDropdownValues(prevValues => ({
            ...prevValues,
            [id]: value,
        }));
    };

    const handleSubmit = async () => {
        // const selectedData = checked.map(id => {
        //     const row = careData.find(item => item.id === id);
        //     const dropdownValue = dropdownValues[id] || new DOMParser().parseFromString(row.select, 'text/html').querySelector('option').value;
        //     console.log('step 1',dropdownValue);
        //     return {
        //         id,
        //         value: dropdownValue,
        //     };
        // });

        let selectedData = [];
        let selectErrorTemp = false;

        for (let id of checked) {
            const row = careData.find(item => item.id === id);
            const dropdownValue = dropdownValues[id] || new DOMParser().parseFromString(row.select, 'text/html').querySelector('option').value;

            if (dropdownValue == 0) {
                selectErrorTemp = true;
                break;
            }

            selectedData.push({
                id,
                value: dropdownValue,
            });
        }

        if (selectErrorTemp) {
            setCareError('Select Care Navigator for all selected members in list');
            return;
        } else {
            setCareError('');
        }

        try {

            if (reasonSelect == 'All') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    reasonSe: 'Select reason'
                }));
                return;
            } else if (reasonSelect == 'Others' && reasonText == '') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    reason: 'Mention reason'
                }));
                return;
            } else if (typeSelect == 'All') {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    type: 'Select transfer type'
                }));
                return;
            } else if (selectedData.length == 0) {
                setCareError('');
                setSelectError('Select member list')
                return;
            }

            setShowModalButton(false);
            setShowModal(true);
            setModalTitle('Alert!');
            setModalBody('In Progress, Wait!!!')
            setShowSubmit(false);

            transferMembers(JSON.stringify(selectedData), reasonSelect, reasonText, typeSelect, formatDateYmd(startDate), formatDateYmd(endDate), id, formatDateYmd(dateStartNew))
                .then((response) => {
                    if (response.data.code == 200) {
                        const status = response.data.data.status;
                        setModalBody(response.data.data.msg)
                        if (status == 1) {
                            setErrors('')
                            setDateRange(getNextWeekRange);
                            setDateStartNew(new Date());
                            setReasonSelect('All');
                            setTypeSelect('All');
                            setModalTitle('Success');
                            setShowModalButton(true);
                            setShowHide(true);

                        } else {
                            setModalTitle('Alert');
                            setShowModalButton(false);
                            setShowSubmit(true);
                            setShowHide(false);
                        }
                    }
                });

        } catch (error) {
            console.error('Error submitting data', error);
        }
    };


    const handleBackButton = () => {
        navigate(-1);
    };

    return (
        <>
            <section className='ContentMemberTransfer'>
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md" style={{
                        display: 'flex',
                        alignItems: 'center'
                    }}>
                        {/* <IconButton onClick={handleBackButton}>
                        <ArrowBackIcon />
                    </IconButton> */}
                        <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button>
                        <h1 className="main-title mb-0 ">Member Transfer</h1>
                    </div>
                    <div className="col-md text-end">

                    </div>
                </div>
                <div className="container-fluid">
                    <div className="row align-items-center pb-3">
                        <div className='comntent-MemberTransfer mb-3 p-3'>
                            <div className="row">

                                <div className="col-md-2 mb-3">
                                    <div className="row align-items-center">
                                        <div className="col">
                                            <label htmlFor='transferFrom' className="col-form-label" style={{ fontSize: "14px" }}>Transfer From:</label><br />
                                            <span>{careUserName}</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-3 mb-3">
                                    <div className="row">
                                        <div className="col">
                                            <label htmlFor='reasonFrom' className="col-form-label" style={{ fontSize: "14px" }}>Transfer Reason:</label>
                                            <select
                                                value={reasonSelect}
                                                onChange={e => {
                                                    setReasonSelect(e.target.value);
                                                    setErrors(prevErrors => ({
                                                        ...prevErrors,
                                                        reason: '',
                                                    }));
                                                }}
                                                className='form-select input-field-ui'
                                            >
                                                <option value="All">--Select Reason--</option>
                                                <option value="Medical Leave">Care Navigator on Medical Leave</option>
                                                <option value="Extended Vacation">Care Navigator on Extended Vacation</option>
                                                <option value="Temporary Assignment">Care Navigator on Temporary Assignment</option>
                                                <option value="Training Development">Care Navigator on Training/Professional Development</option>
                                                <option value="Performance Issues">Care Navigator's Performance Issues</option>
                                                <option value="Preference Change">Member's Preference Change</option>
                                                <option value="Reassignment">Operational Reassignment</option>
                                                <option value="Unforeseen Circumstances">Coverage for Unforeseen Circumstances</option>
                                                <option value="Others">Others</option>
                                            </select>
                                            {(errors['reasonSe'] && reasonSelect == 'All') && <div className="text-danger">{errors['reasonSe']}</div>}
                                        </div>

                                        {reasonSelect == 'Others' &&
                                            <>
                                                <div className="col-auto" style={{ paddingTop: "35px" }}>
                                                    {/* <label htmlFor='reasonFrom' className="col-form-label"></label> */}
                                                    <input type="text" className="form-control input-field-ui" id="reasonFrom" value={reasonText} onChange={e => setReasonText(e.target.value)} placeholder="Enter Reason" />
                                                    {(errors['reason'] && reasonText == '') && <div className="text-danger">{errors['reason']}</div>}
                                                </div>
                                            </>
                                        }


                                    </div>
                                </div>

                                <div className="col-md-7 mb-3">
                                    <div className="row align-items-center">
                                        <div className="col-4 ps-0">
                                            <label htmlFor='reasonFrom' className="col-form-label" style={{ fontSize: "14px" }}>Transfer Type:</label>
                                            <select value={typeSelect} onChange={e => setTypeSelect(e.target.value)} className='form-select input-field-ui' >
                                                <option value="All">--Select Type--</option>
                                                <option value="temporary">Temporary Transfer</option>
                                                <option value="permanant">Permanent Transfer</option>
                                            </select>
                                            {(errors['type'] && typeSelect == 'All') && <div className="text-danger">{errors['type']}</div>}
                                        </div>


                                        {(() => {
                                            if (typeSelect == 'temporary') {
                                                return (
                                                    <>
                                                        <div className="col ps-0">
                                                            <label htmlFor='reasonFrom' className="col-form-label" style={{ fontSize: "14px" }}>Transfer Plan-Back Date:</label>
                                                            <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                                                <DatePicker
                                                                    selectsRange={true}
                                                                    startDate={startDate}
                                                                    endDate={endDate}
                                                                    className="form-control input-field-ui"
                                                                    onChange={(update) => {
                                                                        setDateRange(update);
                                                                    }}
                                                                    isClearable={false}
                                                                    dateFormat={
                                                                        "dd/MM/yyyy"
                                                                    }
                                                                    icon="bx bx-calendar"
                                                                    minDate={new Date()}
                                                                    placeholderText="Select Date"
                                                                />
                                                                <i className='bx bx-calendar'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '10px',
                                                                        top: '50%',
                                                                        transform: 'translateY(-50%)',
                                                                        pointerEvents: 'none',
                                                                        fontSize: '20px',
                                                                        color: '#666'
                                                                    }}></i>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-3">
                                                            <span>{formatDate(startDate)} - {formatDate(endDate)}</span>
                                                        </div> */}
                                                        {/* <div className="col-md-2 pt-4">
                                                            <span>{formatDate(startDate)}  {formatDate(endDate)}</span>
                                                        </div> */}
                                                    </>
                                                )
                                            } else if (typeSelect == 'permanant') {
                                                return (
                                                    <>
                                                        <div className="col ps-0">
                                                            <label htmlFor='reasonFrom' className="col-form-label" style={{ fontSize: "14px" }}>Transfer Plan Date:</label>
                                                            <div className="date-picker-wrapper" style={{ position: 'relative' }}>
                                                                <DatePicker
                                                                    selectsRange={false}
                                                                    selected={dateStartNew}
                                                                    className="form-control input-field-ui"
                                                                    onChange={(date) => setDateStartNew(date)}
                                                                    isClearable={false}
                                                                    dateFormat="dd/MM/yyyy"
                                                                    icon="bx bx-calendar"
                                                                    minDate={new Date()}
                                                                    placeholderText="Select Date"
                                                                />
                                                                <i className='bx bx-calendar'
                                                                    style={{
                                                                        position: 'absolute',
                                                                        right: '10px',
                                                                        top: '50%',
                                                                        transform: 'translateY(-50%)',
                                                                        pointerEvents: 'none',
                                                                        fontSize: '20px',
                                                                        color: '#666'
                                                                    }}></i>
                                                            </div>
                                                        </div>
                                                        {/* <div className="col-md-3">
                                                        <div className="col-md-auto pt-2">
                                                            <span>{formatDate(dateStartNew)}</span>
                                                        </div> */}
                                                    </>
                                                )
                                            } else {
                                                return (
                                                    <></>
                                                )
                                            }
                                        })
                                            ()}

                                        <div className='col-md d-flex ps-0' style={{ paddingTop: "35px" }}>

                                            <div className="form-group" style={{ width: "100%" }}>
                                                <input
                                                    type="text"
                                                    placeholder="Search by Member ID"
                                                    value={filterText}
                                                    onChange={e => {
                                                        setPage(1);
                                                        setFilterText(e.target.value);
                                                    }}
                                                    className="form-control input-field-ui ps-5"
                                                />
                                                <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wjm48/fh6lw97sch.png" className="input-icon-seacrh" alt="" />
                                            </div>
                                        </div>

                                    </div>
                                </div>

                                <div className="col-md-12">
                                    <div className="table-responsive MemberTransferTable">
                                        <div className="text-danger">{selectError}</div>
                                        <div className="text-danger">{careError}</div>
                                        {
                                            console.log("here = loading ", loading)
                                        }
                                        {careData.length > 0 &&
                                            <MemberTransferTablev2
                                                key={reloadKey}
                                                data={careData}
                                                onCheckboxChange={handleCheckboxChange}
                                                onDropdownChange={handleDropdownChange}
                                                loading={loading}
                                                setLoading={setLoading}
                                                totalRows={totalRows}
                                                page={page}
                                                perPage={perPage}
                                                sortColumn={sortColumn}
                                                sortDirection={sortDirection}
                                                loadUserData={loadUserData}
                                                setSortColumn={setSortColumn}
                                                setSortDirection={setSortDirection}
                                                setPage={setPage}
                                                setPerPage={setPerPage}
                                            />
                                        }
                                        {
                                            careData.length === 0 && loading == false &&
                                            <p>
                                                <center>There are no records to display
                                                </center>
                                            </p>
                                        }
                                    </div>
                                </div>

                                {(showSubmit && careData.length > 0) && <div className="col-md-12 text-center mb-3">
                                    <button className='btn program-save-btn me-2' onClick={handleSubmit}>Transfer</button>
                                </div>}

                            </div>
                        </div>
                    </div>
                </div>

                {showModal ? <ModalBoxButton handleClose={handleClose} showModaln={showModal} titlen={modalTitle} bodyn={modalBody} type={modalClass} button={showModalButton ? handleButtonAction : undefined} hideButton={showHide} /> : null}
                {/*loading && <CustomLoader />*/}
            </section>

        </>
    );
}

export default ContentMemberTransferPage;
