import { createSlice } from "@reduxjs/toolkit";
import { set } from "firebase/database";


const filterSlice = createSlice({
    name: "filter",
    initialState: {
        filter: { 
            searchQuery: '',
            status: '',
            priority: ''
        }, 
        sort: { 
            field: null,
            direction: null
        }
    },
    reducers: {
        setSortFilterField(state, action) { state.sort.field = action.payload; },
        setSortFilterDirection(state, action) { state.sort.direction = action.payload; },

        setFilter(state, action) { state.filter = action.payload; }
    }
});

export const {setSortFilterDirection, setSortFilterField, setFilter} = filterSlice.actions;
export default filterSlice.reducer;