import React, { useState, useEffect } from 'react';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';
import DataTable from 'react-data-table-component';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { getCareNavigatorsDetails } from '../../../services/medengage/Service';

function DetailedCareNavigatorPerformanceTable(props) {

    const [careNavigatorList, setCareNavigatorList] = useState([])
    const [isNewLoading, setIsNewLoading] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedStatus, setSelectedStatus] = useState('All');

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("firstName");
    const [sortDirection, setSortDirection] = useState('asc');
    const [download, setDownload] = useState('N');

    useEffect(() => {
        fetchCarenavigatorList();
    }, [props.startDate, props.endDate, props.programId, props.conditionId, page, perPage, sortColumn, sortDirection, searchQuery, download]);


    const fetchCarenavigatorList = async () => {
        setIsNewLoading(true);
        try {
            await getCareNavigatorsDetails(searchQuery, props.startDate, props.endDate, props.programId, props.conditionId, page, perPage, sortColumn, sortDirection, download).then((response) => {
                setCareNavigatorList(response.data.data.users);
                setTotalRows(response.data.data.totalRows);
            });
            setIsNewLoading(false);
        } catch (error) {
            setIsNewLoading(false);
        }
    }

    const renderCNP8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Name of the care navigator.
        </Tooltip>
    );

    const renderCNP9Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Supervisor
        </Tooltip>
    );

    const renderCNP10Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Members Enrolled.
        </Tooltip>
    );

    const renderCNP39Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Onboarded Onboarded.
        </Tooltip>
    );

    const renderCNP11Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total Appointments Booked.
        </Tooltip>
    );

    const renderCNP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Succesful Appointments.
        </Tooltip>
    );

    const renderCNP13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Missed Appointments.
        </Tooltip>
    );

    const renderCNP14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average Call Duration.
        </Tooltip>
    );

    const renderCNP15Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            NPS Score.
        </Tooltip>
    );

    const renderCNP16Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Appointments Ratings.
        </Tooltip>
    );

    const renderCNP17Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Transactions.
        </Tooltip>
    );

    const renderCNP18Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Lab Referrals.
        </Tooltip>
    );

    const renderCNP19Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Home Care Referrals.
        </Tooltip>
    );

    const renderCNP20Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Medicatio Referrals.
        </Tooltip>
    );

    const renderCNP21Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Telehealth Referrals.
        </Tooltip>
    );

    const renderCNP22Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Member No Show.
        </Tooltip>
    );

    const renderCNP23Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Lab Referrals Completed.
        </Tooltip>
    );

    const renderCNP24Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Home Care Referrals Completed.
        </Tooltip>
    );

    const renderCNP25Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Medicatio Referrals Completed.
        </Tooltip>
    );

    const renderCNP26Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Telehealth Referrals Completed.
        </Tooltip>
    );


    const renderCNP27Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Well Controlled.
        </Tooltip>
    );

    const renderCNP28Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Well to Well Controlled.
        </Tooltip>
    );

    const renderCNP29Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Well to Intermittent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP30Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Well to Persistent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP31Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Intermittent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP32Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Intermittent to Well Controlled.
        </Tooltip>
    );

    const renderCNP33Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Intermittent to Intermittent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP34Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Intermittent to Persistent Poorly Controlled.
        </Tooltip>
    );


    const renderCNP35Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Persistent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP36Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Persistent to Well Controlled.
        </Tooltip>
    );

    const renderCNP37Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Current Intermittent Poorly Controlled.
        </Tooltip>
    );

    const renderCNP38Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Initial Persistent to Persistent Poorly Controlled.
        </Tooltip>
    );

    const columns = [
        {
            name: (
                <span>CareNavigator Name</span>
            ),
            selector: row => row.userName,
            sortable: true,
        },
        {
            name: (
                <span>Supervisor</span>
            ),
            selector: row => row.supervisorName,
            sortable: true,
        },
        {
            name: (
                <span>Members Onboarded</span>
            ),
            selector: row => row.totalMembers,
            sortable: false,
        },
        {
            name: (
                <span>Members Enrolled</span>
            ),
            selector: row => row.enrollMembers,
            sortable: false,
        },
        {
            name: (
                <span>Pathways Assigned</span>
            ),
            selector: row => row.pathwaysAssigned,
            sortable: false,
        },
        {
            name: (
                <span>Health Improved</span>
            ),
            selector: row => row.improvedPathways,
            sortable: false,
        },
        {
            name: (
                <span>Health Deteriorated</span>
            ),
            selector: row => row.deterioratedPathways,
            sortable: false,
        },
        {
            name: (
                <span>Total Appointments Booked</span>
            ),
            selector: row => row.totalAppointments,
            sortable: false,
        },
        {
            name: (
                <span>Succesful Appointments</span>
            ),
            selector: row => row.succesfulAppointments,
            sortable: false,
        },
        {
            name: (
                <span>Missed Appointments</span>
            ),
            selector: row => row.missedAppointments,
            sortable: false,
        },
        {
            name: (
                <span>Member No Show</span>
            ),
            selector: row => row.noShow,
            sortable: false,
        },
        {
            name: (
                <span>Average Call Duration</span>
            ),
            selector: row => row.callDuration,
            sortable: false,
        },
        {
            name: (
                <span>NPS Score</span>
            ),
            selector: row => row.npsScore,
            sortable: false,
        },
        {
            name: (
                <span>Appointments Ratings</span>
            ),
            selector: row => row.appointmentsRatings,
            sortable: false,
        },
        {
            name: (
                <span>Transactions</span>
            ),
            selector: row => row.transactions,
            sortable: false,
        },
        {
            name: (
                <span>Lab Referrals</span>
            ),
            selector: row => row.lab,
            sortable: false,
        },
        {
            name: (
                <span>Lab Referrals Completed</span>
            ),
            selector: row => row.labComp,
            sortable: false,
        },
        {
            name: (
                <span>Home Care Referrals</span>
            ),
            selector: row => row.homeCare,
            sortable: false,
        },
        {
            name: (
                <span>Home Care Referrals Completed</span>
            ),
            selector: row => row.homeCareComp,
            sortable: false,
        },
        {
            name: (
                <span>Medication Referrals</span>
            ),
            selector: row => row.medication,
            sortable: false,
        },
        {
            name: (
                <span>Medication Referrals Completed</span>
            ),
            selector: row => row.medicationComp,
            sortable: false,
        },
        {
            name: (
                <span>Telehealth Referrals</span>
            ),
            selector: row => row.telehealth,
            sortable: false,
        },
        {
            name: (
                <span>Telehealth Referrals Completed</span>
            ),
            selector: row => row.telehealthComp,
            sortable: false,
        }
    ];




    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedStatus('All');
    };

    const filteredData = careNavigatorList.filter(user => {
        const matchesSearchQuery =
            (typeof user.userName === 'string' && user.userName.toLowerCase().includes(searchQuery.toLowerCase())) ||
            (typeof user.email === 'string' && user.email.toString().includes(searchQuery));
        return matchesSearchQuery;
    });


    const handleExport = () => {

        const modifiedData = filteredData.map(item => {
            return {
                'Care Navigator Name': item.userName,
                'Supervisor': item.supervisorName,
                'Members Onboarded': item.totalMembers,
                'Members Enrolled': item.enrollMembers,
                'Pathways Assigned': item.pathwaysAssigned,
                'Health Improved': item.improvedPathways,
                'Health Deteriorated': item.deterioratedPathways,
                'Total Appointments Booked': item.totalAppointments,
                'Succesful Appointments': item.succesfulAppointments,
                'Missed Appointments': item.missedAppointments,
                'Member No Show': item.noShow,
                'Average Call Duration': item.callDuration,
                'NPS Score': item.npsScore,
                'Appointments Ratings': item.appointmentsRatings,
                'Transactions': item.transactions,
                'Lab Referrals': item.lab,
                'Lab Referrals Completed': item.labComp,
                'Home Care Referrals': item.homeCare,
                'Home Care Referrals Completed': item.homeCareComp,
                'Medication Referrals': item.medication,
                'Medication Referrals Completed': item.medicationComp,
                'Telehealth Referrals': item.telehealth,
                'Telehealth Referrals Completed': item.telehealthComp
            }
        });

        modifiedData.forEach(function (v) { delete v.id; });

        exportTableData("CareNavigatorDetails", modifiedData);
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className="col-3 mt-2"><h1 className='dashboard-graph-title'>Detailed CareNavigator Performance</h1></div>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by CareNavigator Name or Email"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        {
                            filteredData && <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                        }

                    </div>
                </div>
            </div>
            {
                isNewLoading ? <LoaderLoader /> :
                    <BackendDrivenDataTable
                        data={careNavigatorList}
                        loading={isNewLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchCarenavigatorList}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
        </>
    );
}

export default DetailedCareNavigatorPerformanceTable;
