import React, { useCallback } from 'react';
import { Modal, Button } from 'react-bootstrap';

const ConfirmationModal = ({
  show,
  onHide,
  onConfirm,
  title = 'Confirm Action',
  message = 'Are you sure you want to proceed?',
  confirmButtonText = 'Confirm',
  cancelButtonText = 'Cancel',
  confirmButtonVariant = 'btn-primary program-save-btn',
  cancelButtonVariant = 'btn clear-btn',
  acceptRequestDisabled = false,
  rejectRequestDisabled = false
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      id="observationModule"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="text-center">
        {message}
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-center" style={{ borderTop: 'none' }}>
        <Button variant={confirmButtonVariant} onClick={onConfirm}
          disabled={acceptRequestDisabled || rejectRequestDisabled}
        >
          {confirmButtonText}
        </Button>
        <Button variant={cancelButtonVariant} onClick={onHide}>
          {cancelButtonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;
