import React, { useState, useCallback, useEffect } from 'react';
import axios from "axios";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Modal, Tab, Tabs, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { fetchWorkingPlansAndBreaks, saveAppointmentBreak, saveAppointmentSlots, removeAppointmentBreak, saveAppointmentEditBreak, fetchBreakReasons } from "../../../../services/cn/Service"
import { formatDateWithOrdinal } from '../../components/common/Utils';
import alertify from 'alertifyjs';
import './WorkingPlansAndBreaks.css'
import moment from 'moment';
import { LoaderLoader } from '../../../utils/CommonLibrary';

const WorkingPlansAndBreaks = ({ showWPModal, handleShowWPModal, handleCloseWPModal }) => {
    const [loading, setLoading] = useState(false);
    const [breakDate, setBreakDate] = useState(null);
    const [breakStartTime, setBreakStartTime] = useState(null);
    const [breakEndTime, setBreakEndTime] = useState(null);
    const [breakReasonStr, setBreakReasonStr] = useState('');
    const [workingPlansAndBreaksInfo, setWorkingPlansAndBreaksInfo] = useState([]);
    const [upcomingBreaks, setUpcomingBreaks] = useState([]);
    const [pastBreaks, setPastBreaks] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [origSelectedSlots, setOrigSelectedSlots] = useState([]);
    const [selectedDay, setSelectedDay] = useState("Monday");
    const [copyFrom, setCopyFrom] = useState("");
    const [message, setMessage] = useState("");
    const [error, setError] = useState("");
    const [breakMessage, setBreakMessage] = useState("");
    const [breakError, setBreakError] = useState("");
    const [breakEditError, setBreakEditError] = useState("");
    const [breakEditEndTime, setBreakEditEndTime] = useState({ breakId: '', breakEndTime: null });
    const [breakReasonsList, setBreakReasonsList] = useState([]);

    const currentTime = new Date();
    const today = new Date();
    const nextMonth = new Date(today.getFullYear(), today.getMonth() + 2, 0);

    const slotValidityText = "These slots are valid from " + formatDateWithOrdinal(today) + " till " + formatDateWithOrdinal(nextMonth);

    useEffect(() => {
        getWorkingPlansAndBreaks();
        getBreakReasons();
    }, [])

    const getWorkingPlansAndBreaks = async () => {
        setLoading(true);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchWorkingPlansAndBreaks(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setWorkingPlansAndBreaksInfo(response.data.data);
                    setSelectedSlots(response.data.data.daywiseselectedslots);
                    setOrigSelectedSlots(response.data.data.daywiseselectedslots);
                    setUpcomingBreaks(response.data.data.breaks)
                    setPastBreaks(response.data.data.pastbreaks)
                }
                setLoading(false);
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const getBreakReasons = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchBreakReasons(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setBreakReasonsList(response.data.data.reasons)
                }

            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleBreakReason = (event) => {
        setBreakReasonStr(event.target.value);
    };

    const handleDelUpcomingBreak = (breakId) => {
        alertify.confirm(`Confirm Delete`, `Are you sure you want to delete this break?`, () => {
            removeUpcomingBreak(breakId);
        }, () => {
        });
    }

    const removeUpcomingBreak = async (breakId) => {
        const request = axios.CancelToken.source();
        let cancelToken = {
            cancelToken: request.token
        }
        await removeAppointmentBreak(breakId, cancelToken)
            .then((response) => {
                if (response.data.code === 200) {
                    getWorkingPlansAndBreaks();
                    setBreakMessage(response.data.data.message);
                } else {
                    setBreakError(response.data.data.message)
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const handleBreakSubmit = async (e) => {
        e.preventDefault();
        setBreakError("");
        setBreakMessage("");
        if (!breakStartTime) {
            setBreakError("Please enter the start time");
        } else if (!breakEndTime) {
            setBreakError("Please enter the end time");
        } else if (breakReasonStr == "") {
            setBreakError("Please select the reason");
        } else if (new Date(breakEndTime) <= new Date(breakStartTime)) {
            setBreakError('Break end time should be after break start time');
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await saveAppointmentBreak(moment(breakStartTime).format('YYYY-MM-DD HH:mm:ss'), moment(breakEndTime).format('YYYY-MM-DD HH:mm:ss'), breakReasonStr, ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        //setBreakDate(null);
                        getWorkingPlansAndBreaks()
                        setBreakStartTime(null);
                        setBreakEndTime(null);
                        setBreakReasonStr('');
                        setBreakMessage(response.data.data.message);
                    } else {
                        setBreakError(response.data.data.message)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    };

    const saveBreakEditTime = async () => {
        //e.preventDefault();
        setBreakEditError("");
        if (breakEditEndTime.breakId === "") {
            setBreakEditError("Please enter the end time");
        } else if (breakEditEndTime.breakEndTime === null || breakEditEndTime.breakEndTime === "") {
            setBreakEditError("Please enter the end time");
        } else {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await saveAppointmentEditBreak(breakEditEndTime.breakId, moment(breakEditEndTime.breakEndTime).format('YYYY-MM-DD HH:mm:ss'), ct)
                .then((response) => {
                    if (response.data.code === 200) {
                        setBreakEditEndTime({ breakId: '', breakEndTime: null });
                        getWorkingPlansAndBreaks()
                    } else {
                        setBreakEditError(response.data.data.message)
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        }
    }

    const handleSlotClick = (day, time) => {
        setSelectedSlots(prevState => {
            const daySlots = prevState[day];
            const updatedDaySlots = daySlots.includes(time) ? daySlots.filter(slot => slot !== time) : [...daySlots, time];
            return {
                ...prevState, [day]: updatedDaySlots
            };
        });
    };

    const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
    const TimeCapsule = ({ startTime, day }) => {
        const times = [];
        for (let i = 0; i < 12; i++) {
            const time1 = new Date(startTime.getTime() + i * 30 * 60000); // 1-hour interval for the first sub-column
            const timeString = time1.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true }).toUpperCase();
            times.push(
                <div key={i} className={`${day} d-flex time-capsule ${selectedSlots[day] && selectedSlots[day].includes(timeString) ? 'selectedSlot' : ''}`} onClick={() => handleSlotClick(day, timeString)}>
                    <div className="flex-fill">{timeString} </div>
                </div>
            );
        }
        return <div>{times}</div>;
    };

    const DayColumn = ({ startTime, day }) => (
        <div className="d-flex" style={{ justifyContent: 'center' }}>
            <div className="flex-column">
                <TimeCapsule startTime={startTime} day={day} />
            </div>
            <div className="flex-column pad-left">
                <TimeCapsule startTime={new Date(startTime.getTime() + 15 * 60000)} day={day} />
            </div>
        </div>
    );

    const handleCopyFrom = (e) => {
        const { options, selectedIndex } = e.target;
        const cf = options[selectedIndex].text;
        setCopyFrom(cf);
    }

    const handleCopy = () => {
        if (copyFrom != "") {
            setSelectedSlots(selectedSlots => ({
                ...selectedSlots,
                [selectedDay]: selectedSlots[copyFrom]
            }));
            setCopyFrom("");
        }

    }

    const handleDayChange = (day) => {
        setSelectedDay(day)
    }

    const handleWPSave = async () => {
        setError("");
        setMessage("");
        /*if (selectedSlots[selectedDay].length <= 0) {
            setError("No slot is selected");
        } else {*/
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await saveAppointmentSlots(selectedDay, selectedSlots[selectedDay], selectedSlots[selectedDay].length, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    getWorkingPlansAndBreaks();
                    setMessage(response.data.data.message);
                } else {
                    setError(response.data.data.message);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
        //}
    }
    const WorkingPlans = () => {
        const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
        const columns = ['Night', 'Morning', 'Afternoon', 'Evening'];
        const startTimes = [
            new Date('1970-01-01T00:00:00'),
            new Date('1970-01-01T06:00:00'),
            new Date('1970-01-01T12:00:00'),
            new Date('1970-01-01T18:00:00'),
        ];

        return (
            <div className='WorkingPlansTab'>
                <div className='row'>
                    <div className="col p-4 pb-0">
                        <p>This will be set as your availability for every day of the week (every Monday, Tuesday..... Sunday). The slots are displayed as per your time-zone.
                            You can set your unavailability of any particular date in 'Breaks' section.</p>
                        {/*<p>{slotValidityText}</p>*/}
                    </div>
                </div>
                <div className={`${error != "" ? "errorDiv" : "hide"}`}>{error}</div>
                <div className={`${message != "" ? "messageDiv" : "hide"}`}>{message}</div>
                <Tabs activeKey={selectedDay} defaultActiveKey={selectedDay} onSelect={handleDayChange}>
                    {days.map((day) => (
                        <Tab eventKey={day} title={day} key={day}>
                            <div className="d-flex">
                                {columns.map((col, index) => (
                                    <div className="p-2 flex-fill" key={col}>
                                        <h5 className='day-hour-h5'>{col}</h5>
                                        <DayColumn startTime={startTimes[index]} day={day} />
                                    </div>
                                ))}
                            </div>
                        </Tab>
                    ))}
                </Tabs>
                <br />
                <Row>
                    <Col className='copy-cell ps-4'>
                        <select className='form-select control-input-uibox' value={copyFrom} onChange={(e) => handleCopyFrom(e)}>
                            <option value="">Same as</option>
                            {days.map((day, index) => (
                                <option value={day} key={index}>{day}</option>
                            ))}
                        </select>
                        <Button variant="primary" className='copy-save-btn' onClick={() => handleCopy()}>
                            Copy
                        </Button>
                    </Col>
                    <Col className='align-right pr-20'>
                        <Button className='close-reset-btn' variant="secondary" onClick={handleCloseWPModal}>
                            Close
                        </Button>
                        <Button className='mx-2 close-reset-btn' variant="warning" onClick={() => setSelectedSlots(origSelectedSlots)}>Reset</Button>
                        <Button variant="primary" className='copy-save-btn' onClick={handleWPSave}>Save for {selectedDay}</Button>
                    </Col>
                </Row>
                <br />
            </div>
        );
    };

    const Breaks = () => {
        console.log(workingPlansAndBreaksInfo.breaks)
        return (
            <>
                <div className='WorkingBreaksTab pad-10'>
                    <Row>
                        <Col>
                            <div className={`${breakError != "" ? "errorDiv" : "hide"}`}>{breakError}</div>
                            <div className={`${breakMessage != "" ? "messageDiv" : "hide"}`}>{breakMessage}</div>
                            <form onSubmit={handleBreakSubmit}>
                                <div className='row'>
                                    {/*<div className='col-3'>
                                        <label className="form-label">Date:</label>
                                        <DatePicker
                                            selected={breakDate}
                                            onChange={(date) => setBreakDate(date)}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="Select a date"
                                            className='control-input-uibox'
                                        />
                                    </div>*/}
                                    <div className='col-4'>
                                        <label className="form-label">Break Starts from:</label>
                                        <DatePicker
                                            selected={breakStartTime}
                                            onChange={updatedDate => setBreakStartTime(updatedDate)}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeInputLabel="Time:"
                                            dateFormat="dd-MM-yyyy h:mm aa"
                                            placeholderText="Select Date and Time"
                                            className='form-select input-field-ui'
                                            onKeyDown={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <label className="form-label">Break Ends at:</label>
                                        <DatePicker
                                            selected={breakEndTime}
                                            onChange={updatedDate => setBreakEndTime(updatedDate)}
                                            showTimeSelect
                                            timeIntervals={15}
                                            timeInputLabel="Time:"
                                            dateFormat="dd-MM-yyyy h:mm aa"
                                            placeholderText="Select Date and Time"
                                            className='form-select input-field-ui'
                                            onKeyDown={(e) => e.preventDefault()}
                                            onPaste={(e) => e.preventDefault()}
                                        />
                                    </div>
                                    <div className='col-4'>
                                        <label className="form-label">Reason:</label>
                                        {/*<input type="text" className='control-input-uibox' placeholder='Reason' value={breakReasonStr} onChange={handleBreakReason} />
                                        <select className="form-select select-input-ui" aria-label="Reason" value={breakReasonStr} onChange={handleBreakReason}>
                                            <option value="">Select</option>
                                            <option value="Personal Health Issues">Personal Health Issues</option>
                                            <option value="Family or Caregiving Responsibilities">Family or Caregiving Responsibilities</option>
                                            <option value="Vacation or Personal Time">Vacation or Personal Time</option>
                                            <option value="Bereavement">Bereavement</option>
                                            <option value="Maternity or Paternity Leave">Maternity or Paternity Leave</option>
                                            <option value="Education or Training">Education or Training</option>
                                            <option value="Religious or Cultural Observances">Religious or Cultural Observances</option>
                                            <option value="Marriage or Significant Life Events">Marriage or Significant Life Events</option>
                                            <option value="Other">Other</option>
                                        </select>*/}

                                        <select className="form-select select-input-ui" aria-label="Reason" value={breakReasonStr} onChange={handleBreakReason}>
                                            <option value="">Select</option>
                                            {
                                                breakReasonsList.map((item, index) => {
                                                    return <option value={item.reason}>{item.reason}</option>
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className='col-12 text-end mt-2'>
                                        <Button className='close-reset-btn mr-2' variant="secondary" onClick={handleCloseWPModal}>
                                            Close
                                        </Button>
                                        <Button variant="primary" className='copy-save-btn' type="submit">Save</Button>
                                    </div>
                                </div>
                            </form>
                        </Col>
                    </Row>
                    <Row className={`${upcomingBreaks.length > 0 ? "" : "hide"}`}>
                        <Col>
                            <br />
                            <b>Upcoming Breaks</b>
                            <Row className='mt-2'>
                                <Col>Start</Col>
                                <Col>End</Col>
                                <Col>Reason</Col>
                                <Col md={1}></Col>
                            </Row>
                            {
                                upcomingBreaks.map((wpBreak, index) => {
                                    return (
                                        <Row className='border-top py-2 mt-1'>
                                            <Col>{wpBreak.start}</Col>
                                            <Col>{wpBreak.end}</Col>
                                            <Col>{wpBreak.reason}</Col>
                                            <Col md={1}><i className="fa fa-trash" aria-hidden="true" onClick={() => handleDelUpcomingBreak(wpBreak.id)} style={{ cursor: 'pointer' }}></i>
                                            </Col>
                                        </Row>)
                                })
                            }
                        </Col >
                    </Row >
                    <Row className={`${pastBreaks.length > 0 ? "" : "hide"}`}>
                        <Col>
                            <br />
                            <b>Past Breaks</b>
                            <Row>
                                <Col>Start</Col>
                                <Col>End</Col>
                                <Col>Reason</Col>
                                <Col md={1}></Col>
                            </Row>
                            {
                                pastBreaks.map((wpBreak, index) => {
                                    return (< Row >
                                        <Col>{wpBreak.start}</Col>
                                        <Col>
                                            {
                                                (breakEditEndTime.breakId === wpBreak.id) ?
                                                    <>
                                                        <DatePicker
                                                            selected={breakEditEndTime.breakEndTime}
                                                            onChange={updatedDate => setBreakEditEndTime({ breakId: wpBreak.id, breakEndTime: updatedDate })}
                                                            showTimeSelect
                                                            timeIntervals={15}
                                                            timeInputLabel="Time:"
                                                            dateFormat="dd-MM-yyyy h:mm aa"
                                                            placeholderText="Select Date and Time"
                                                            className='form-select input-field-ui'
                                                            onKeyDown={(e) => e.preventDefault()}
                                                            onPaste={(e) => e.preventDefault()}
                                                        />
                                                        <Button type="button" className='close-reset-btn mr-2' variant="secondary" onClick={() => {
                                                            setBreakEditEndTime({ breakId: '', breakEndTime: null })
                                                        }}>Close</Button>
                                                        <Button type="button" variant="primary" className='copy-save-btn' onClick={() => saveBreakEditTime()}>Save</Button>
                                                        <div className={`${breakEditError != "" ? "errorDiv" : "hide"}`}>{breakEditError}</div>
                                                    </>
                                                    :
                                                    <>
                                                        {wpBreak.end}{' '}
                                                        <i className="fa fa-pencil" aria-hidden="true" onClick={() => {
                                                            setBreakEditEndTime({ breakId: wpBreak.id, breakEndTime: null })
                                                        }} style={{ cursor: 'pointer' }}></i>
                                                    </>
                                            }
                                        </Col>
                                        <Col>{wpBreak.reason}</Col>
                                        <Col md={1}></Col>
                                    </Row>
                                    )
                                })
                            }
                        </Col>
                    </Row >
                </div >
            </>
        )
    }

    return (
        <>
            <LoaderLoader isLoading={loading} />
            <Modal id="wpnb" show={showWPModal} onHide={handleCloseWPModal} size="xl" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title>Working Plans and Breaks</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs defaultActiveKey="workingPlans" id="uncontrolled-tab-example" className="workingPlans">
                        <Tab eventKey="workingPlans" title="Working Plans">
                            {
                                loading === false &&
                                <WorkingPlans />
                            }
                        </Tab>
                        <Tab eventKey="breaks" title="Breaks">
                            <Breaks />
                        </Tab>
                    </Tabs>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default WorkingPlansAndBreaks