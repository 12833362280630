import React, { useState, useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { add_new_services, addNewProgram, draft_program_data, fetch_pathway_frequency_data, fetch_program_data, fetch_service_data } from '../../../services/mtp/Service';
import { Commingsoon, LoaderLoader, ProgramDots,PathwayDots } from "../../utils/CommonLibrary";
import 'react-dropdown-tree-select/dist/styles.css';
import DropDownAction from "./DropDownAction";
import { validateFormNewProgramAdd } from "../../utils/validationUtils";
import ContentDraftProgramsList from "./ContentDraftProgramsList";



function ContentProgramsList() {
    const [pathwayName, setPathwayName] = useState("");
    const [pathwayPopupData, setPathwayPopupData] = useState([]);
    const [servicePopupData, setServicePopupData] = useState([]);
    const [programData, setProgramData] = useState([]);
    const [serviceData, setServiceData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [pathwayId, setPathWayId] = useState(null);
    const [showhide, setShowHide] = useState(true);
    const [selectedTab, setSelectedTab] = useState(0);
    const [programDraftData, setProgramDraftData] = useState([]);
    

    const navigate = useNavigate(); // Get the navigate function

    // add program
    const [showAddProgramModal, setShowAddProgramModal] = useState(false);
    const handleClose = () =>{ setFormData({
        programName: '',
        programDescription: '',
        descriptionForMember: '',
    }); 
    setErrors({
        programName: '',
        programDescription: '',
        descriptionForMember: '',
    })
    setShowAddProgramModal(false);}
    
    const [formData, setFormData] = useState({
        programName: '',
        programDescription: '',
        descriptionForMember: '',
    })

    const [errors, setErrors] = useState({
        programName: '',
        programDescription: '',
        descriptionForMember: '',
    })


    useEffect(() => {
        if (showAddProgramModal) {
            document.body.style.overflow = 'hidden';
            // Add this class to your root element or main content wrapper
            document.querySelector('.container-fluid').style.pointerEvents = 'none';
        } else {
            document.body.style.overflow = 'unset';
            // Remove the pointer-events restriction
            document.querySelector('.container-fluid').style.pointerEvents = 'auto';
        }
        
        return () => {
            document.body.style.overflow = 'unset';
            document.querySelector('.container-fluid').style.pointerEvents = 'auto';
        };
    }, [showAddProgramModal]);
    
 // This function will be passed as a callback to the child component
 const handleCallback = (tab) => {
    fetchProgramData();
    console.log("tab",tab)
    setSelectedTab(tab);
  };

    const pathwayModelPopup = async (pathwayId) => {
        try {
            setIsLoading(true); //to show loader
            let response = await fetch_pathway_frequency_data(pathwayId);
            if (response.data.code === 200) {
                console.log(response.data.data);         
                setPathwayName(response.data.pathwayName);
                setPathWayId(pathwayId);
                setPathwayPopupData(response.data.data);
                setServicePopupData(response.data.data[0].serviceData);
                setShowHide(false);  
            }
            setIsLoading(false); //to hide loader
           
        } catch (error) {
            console.error(error);
        }
    }

    // const servicesModelPopup = async (frequencyId) => {
    //     try {
    //         fetchServiceData();
    //     } catch (error) {
    //         console.error(error);
    //     }
    // }

    useEffect(() => {
        fetchProgramData();
        fetchServiceData();
    }, [selectedTab]);

    //To get program data list
    const fetchProgramData = async () => {
        try {
            let resp = await fetch_program_data();
            setIsLoading(true); //to show loader
            if (resp.data.code === 200) {
                setProgramData(resp.data.data);
                
            }
            setIsLoading(false); //hide loader
        } catch (error) {
            console.error(error);
        }
    }

    //To get program data list
    const fetchProgramDraftData = async () => {
        try {
            let resp = await draft_program_data();
            setIsLoading(true); //to show loader
            if (resp.data.code === 200) {
                console.log("active",resp.data.data)
                setProgramDraftData(resp.data.data);
                
            }
            setIsLoading(false); //hide loader
        } catch (error) {
            console.error(error);
        }
    }

    //To get service data list
    const fetchServiceData = async () => {
        try {
            setIsLoading(true); //to show loader
            let result = await fetch_service_data();
            if (result.data.code === 200) {
                setServiceData(result.data.data);
            }
            setIsLoading(false); //hide loader
        } catch (error) {
            console.error(error);
        }
    }

    // To add new service 
    const AddNewServices = async ()=>{
        try {

            setIsLoading(true); //to show loader
            let result = await add_new_services();
            if (result.data.code === 200) {
                setServiceData(result.data.data);
            }
            setIsLoading(false); //hide loader
        } catch (error) {
            console.error(error);
        }
    }
    const pageReverse = () =>{
        setShowHide(true); 
    }
//Add new program popup
const handleAddNewProgram = () =>{
    setShowAddProgramModal(true);
}
//onchange
const onValueChange = (e) =>{    
    setFormData({...formData, [e.target.name]: e.target.value});
}


const submitAddNewProgram = async (e) => {
    e.preventDefault();
    setErrors('')
    setIsLoading(true);
    const validationErrors = validateFormNewProgramAdd(formData);
    if (Object.keys(validationErrors).length > 0) {
        setErrors(validationErrors);
        setIsLoading(false);
    }else{
        addNewProgram(formData)
        .then((response) => {   
            if(response.data.code == 200){
                fetchProgramData();
                setShowAddProgramModal(false);
                setFormData({
                    programName: '',
                    programDescription: '',
                    descriptionForMember: '',
                }); 
                fetchProgramDraftData();
                setSelectedTab(1);
            }
            setIsLoading(false);
        });
    }
}




    return (
        <>
            <div className="container-fluid px-0 mb-3">
                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md"> 
                        <h4 className="mb-0 content-main-title">Programs</h4>
                    </div>
                    <div className="col-md text-end">


                        <Link onClick={() => handleAddNewProgram()} aria-label="Add New Program" title="Add New Program" className="btn btn-primary add-new-program-btn">
                            <i className="bi bi-card-checklist me-2"></i> Add new Program
                        </Link>
                    </div>
                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/Program.png" alt="Invited Members icon" style={{height:"140px"}} className="img-fluid mb-0"/>
                        </div>
                        <div className="col-md">
                            <p className="mb-3">The Care Program module helps you effortlessly create new Care programs, add conditions, pathways, and services to the pathway. This is essential for enabling our Super Care service.</p>
                            <p>
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/csc4v/fhk1m844g8.png" alt="Invited Members icon" style={{height:"100px"}}/>
                            </p>
                        </div>
                    </div>
                </div>
                {showhide ? (
                <div className='content-programs mb-3'>
                    <div className="row">
                        <div className="col-md-12">
                        <Tabs selectedIndex={selectedTab} onSelect={index => setSelectedTab(index)}>
                        <TabList>
                          <Tab>Active Programs</Tab>
                          <Tab>Draft Programs</Tab>
                        </TabList>

                        <TabPanel>
                        <div className="col-md-12">
                        <div className="body-tree-view">
                        <LoaderLoader isLoading={isLoading} />
                        {/* Program 1 */}
                        {programData.map((itemFirst, index) => (
                            <>
                        <details close key={index}>
                            <summary className="d-flex align-items-center">
                                {itemFirst.programName}
                                <ProgramDots  programId= {itemFirst.programId} tab={1} onCallBackFromChild={handleCallback}/>
                            </summary>

                      
                            
                            {itemFirst.children ? ( 
                                        <>
                            {itemFirst.children.map((itemSecond, index) => (
                            <details open>
                                <summary className="d-flex align-items-center">
                                    {itemSecond.programName}
                                    <DropDownAction conditionId = {itemSecond.programId} programId= {itemFirst.programId} tab={1} onCallBackFromChild={handleCallback}/>
                                </summary>
                                <ul>
                                    {itemSecond.children ? ( 
                                        <>
                                        {itemSecond.children.map((itemThird, index) => (
                                                <>
                                                {itemThird.pathwayCount ? (
                                                    <>
                                                     <li className="d-flex align-items-center"><Link to ={`/add-service/${itemThird.programId}`} style={{color:'#000000'}}><span>{itemThird.programName}</span></Link> <PathwayDots pathway = {itemThird.programId} tab={1} onCallBackFromChild={handleCallback}/></li>
                                                    </>

                                                ): (

                                                    <>
                                                    <li className="d-flex align-items-center"><Link to ={`/add-service/${itemThird.programId}`} style={{color:'#000000'}}><span>{itemThird.programName}</span></Link> <PathwayDots pathway = {itemThird.programId} tab={1} onCallBackFromChild={handleCallback}/></li>
                                                    </>

                                                )}
                                                </>
                                                
                                        ))}
                                        </> 
                                        ) : ('') }
                                </ul>
                            </details>
                            ))}
</>
                            ) : ("") }
                            
                            </details>
                            </>
                        )
                    )}
                    
                    </div>
                        
                        </div>
                        </TabPanel>
                        <TabPanel>
                          <ContentDraftProgramsList draftRefreshpage ={handleCallback} draftProgramData={programDraftData}/>
                        </TabPanel>
                    </Tabs>
                        </div>
                        
                    </div>
                </div>
                ):("")}
                { pathwayName && !showhide ? (<>
                <div className='content-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-auto mt-2 px-4">
                            <h3 className="btn btn-primary back-btn mb-0" onClick={pageReverse}><i className="bi bi-chevron-left me-1"></i> Back</h3>
                        </div>
                        <div className="col mt-2 px-0 align-items-center">
                            <h3 className="main-title mb-0">{pathwayName}</h3>
                        </div>
                        <div className="col-auto mt-2 px-4">
                        {/* <a data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal" className="btn btn-primary devision-add-btn" href="/programs"><i className="bi bi-plus-circle me-2"></i> Add</a> */}
                        <Link to={`/add-service/${pathwayId}`} className="btn btn-primary devision-add-btn" ><i className="bi bi-plus-circle me-2"></i> Edit</Link>
                        </div>

                        <div className="col-md-12 mt-2 px-4">
                            <div className="table-responsive pathways-new-table">
                            <table className="table table-striped mb-0 mt-2">
                                            <tbody>    
                                            {pathwayPopupData.map((item, indexth) => (
                                                    <tr>
                                                        <td key={indexth}>
                                                            <h5 className="main-pathways-title">{item.month} Month</h5>
                                                            </td>
                                                            <td></td>
                                                            <td></td>
                                                            <td>
                                                             {item.servicesName}   
                                                            {/* {item.serviceData.map((option, index) => ( 
                                                            
                                                                <span  key={index}>{option.serviceName}</span>
                                                                
                                                            
                                                            ))} */}
                                                            </td>
                                                        {/* <td className="text-end">
                                                            <font data-bs-toggle="modal" data-bs-target="#pathwaysProgramsModal" className="btn btn-primary program-add-btn" onClick={() => servicesModelPopup(pathwayData.frequencyId)}><i className="bi bi-plus-circle me-2" style={{textDecoration:'none'}}></i> Add</font>
                                                        </td> */}
                                                    </tr>
                                            ))}
                                                    
                                            </tbody>
                                        </table>
                            </div>
                        </div>
                       

                    </div>
                </div>
                </>) : ("")}
            </div>


            <div className="modal fade" id="pathwaysModal" tabindex="1" aria-labelledby="pathwaysModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-subtitle">Duration</h4>
                            <h1 className="modal-title">{pathwayName}</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    {pathwayPopupData.map((item, index) => (
                                        <>
                                            <h3 className='text-start pathways-box' key={index}>{item.month} Month</h3>
                                            <div className="row px-2 mb-3">
                                                {/* {item.serviceData.map((option, index) => (
                                                    <>
                                                        <div className="col-md-6 my-2" key={index}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/tsslj/j461v4ma4e.png" className="table-icon" alt="" />{option.serviceName}</div>
                                                    </>
                                                ))} */}
                                                <div className="col-md-6 my-2" key={index}><img src="https://storage.googleapis.com/ksabupatest/2024/09/24/tsslj/j461v4ma4e.png" className="table-icon" alt="" />{item.serviceName}</div>
                                            </div>
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Commingsoon />
            
            <div className="modal fade" id="pathwaysProgramsModal" tabindex="1" aria-labelledby="pathwaysProgramsModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title">Add Services</h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12 px-4">
                                {serviceData.map((service, index) => (
                                    <div className="form-check mb-3">
                                        <input className="form-check-input" type="checkbox" value="" id="ServicesCheck1"/>
                                        <label className="form-check-label" htmlFor="ServicesCheck1">
                                            {service.serviceName}
                                        </label>
                                    </div>
                                ))}
                                </div>
                                <div className="col-md-12 px-4 mb-3">
                                <textarea className="form-control input-field-ui mb-1" id="exampleFormControlTextarea1" rows="2"></textarea>
                                <font className="btn btn-primary services-add-btn"><i className="bi bi-plus-circle me-2" onClick={AddNewServices} ></i> Add Service</font>
                                </div>

                                <div className="col-md-12 px-4 my-2">
                                 <button type="submit" className="btn btn-primary program-save-btn">Save</button> <button type="submit" className="btn btn-primary clear-btn">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal 
            showModal={showAddProgramModal}
            handleClose={handleClose}
            submitAddNewProgram={submitAddNewProgram}
            formData={formData}
            onValueChange={onValueChange}
            errors={errors}
        />
        </>
    );
};

const Modal = ({ showModal, handleClose, submitAddNewProgram, formData, onValueChange, errors }) => {
    useEffect(() => {
      if (showModal) {
        document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'unset';
      }
      return () => {
        document.body.style.overflow = 'unset';
      };
    }, [showModal]);
  
    if (!showModal) return null;
  
    return (
      <div className="modal-wrapper" style={{ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 9999 }}>
        {/* Single Backdrop */}
        <div 
          className="modal-backdrop fade show"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          }}
          onClick={handleClose}
        />
        
        {/* Modal Dialog */}
        <div 
          className="modal fade show d-block" 
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            outline: 0,
            display: 'flex !important',
            alignItems: 'center',
            justifyContent: 'center'
          }}
          id="showAddProgramModalLabelNew"
        >
          <div 
            className="modal-dialog modal-dialog-centered modal-md"
            onClick={e => e.stopPropagation()}
          >
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="modal-title mb-0">Add New Program</h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={handleClose}
                ></button>
              </div>
              <div className="modal-body pt-1 mb-2">
                <form onSubmit={submitAddNewProgram}> 
                  <div className="row">
                    <div className="col-md-12 mb-3">
                      <label htmlFor='programName' className="form-label">
                        Program Name<span className="text-danger"> *</span>
                      </label>
                      <input 
                        type="text"
                        id="programName"
                        className="form-control input-field-ui"
                        placeholder="Enter program name"
                        name="programName"
                        onChange={onValueChange} 
                        value={formData['programName']}
                      />
                      {errors['programName'] && (
                        <div className="text-danger">{errors['programName']}</div>
                      )}
                    </div>
                    <div className="col-md-12 mb-3">
                      <label htmlFor='programDescription' className="form-label">
                        Description<span className="text-danger"> *</span>
                      </label>
                      <textarea
                        id="programDescription"
                        className="form-control input-field-ui"
                        placeholder="Enter description"
                        rows="3"
                        name="programDescription"
                        onChange={onValueChange} 
                        value={formData['programDescription']}
                      />
                      {errors['programDescription'] && (
                        <div className="text-danger">{errors['programDescription']}</div>
                      )}
                    </div>
                    <div className="col-md-12 my-2 text-center">
                      <button
                        type="submit"
                        className="btn btn-primary program-save-btn me-2"
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
};

export default ContentProgramsList;
