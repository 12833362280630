import React, { useEffect, useState } from 'react';
import axios from "axios";
import DataTable from 'react-data-table-component';
import { fetchMembersDisenroll, fetchProgramList, reEnrollMember, fetchProgramConditionPathway, getCareNavigators, fetchAvailableAppointmentSlots, fetchMembersReenrolled } from '../../../services/medengage/Service';
import { exportTableData, LoaderLoader } from '../../utils/CommonLibrary';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { formatDate } from '../../utils/CommonLibrary';
import { format } from 'date-fns';
import { CustomLoader } from '../../utils/CommonLibrary';
import BackendDrivenDataTable from '../../cn/components/common/BackendDrivenDataTable';

function ReenrolledTable() {
    const formObj = {
        userId: "",
        cnId: "",
        programId: "",
        conditionId: "",
        pathwayId: "",
        callDate: "",
        callTime: "",
        pathwayMonth: 1,
        remarks: ''
    }
    const [formData, setFormData] = useState(formObj);
    const [programConditionPathways, setProgramConditionPathways] = useState([]);
    const [careNavigatorsList, setCareNavigatorsList] = useState([]);
    const [timeSlots, setTimeSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [reEnrollmentError, setReEnrollmentError] = useState("");
    const [showReEnrollmentModal, setShowReEnrollmentModal] = useState(false);

    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('');
    const [selectedCondition, setSelectedCondition] = useState('');
    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [newMembersDisenrollList, setNewMembersDisenrollList] = useState([])
    const [data, setData] = useState([]);
    const [isNewLoading, setIsNewLoading] = useState(false);

    const [totalRows, setTotalRows] = useState(0);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortColumn, setSortColumn] = useState("dateReenrolled");
    const [sortDirection, setSortDirection] = useState('desc');
    const [download, setDownload] = useState('N');

    useEffect(() => {
        fetchNewMembersDisenrollList();
    }, [page, perPage, sortColumn, sortDirection, searchQuery, selectedProgram, selectedCondition, download]);

    useEffect(() => {
        fetchDropDownList();
    }, []);

    const fetchDropDownList = async () => {
        await fetchProgramList()
            .then((response) => {
                setProgramList(response.data.data.programList);
                setConditionList(response.data.data.conditionList);
            });
    }

    const fetchNewMembersDisenrollList = async () => {
        try {
            setIsNewLoading(true);
            fetchMembersReenrolled(searchQuery, selectedProgram, selectedCondition,
                page, perPage, sortColumn, sortDirection, download)
                .then((response) => {
                    if (response.data.code == 200) {
                        if (download == "N") {
                            setData(response.data.data.logs);
                            setTotalRows(response.data.data.totalRows);
                        } else {
                            handleExport(response.data.data.logs);
                            setDownload('N');
                        }
                    }
                    setIsNewLoading(false);
                });
        } catch (error) {
            console.error(error);
        }
        finally {
        }
    };

    /*const fetchNewMembersDisenrollList = async () => {
        setIsNewLoading(true);
        try {
            const response = await fetchMembersDisenroll();

            if (response.data.status === 'success') {
                setNewMembersDisenrollList(response.data.data.filter(data => data.requestStatus !== 'Pending'));
            } else {
                setNewMembersDisenrollList([]);
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsNewLoading(false);
        }
    }*/

    const columns = [
        {
            name: 'Request Date',
            selector: row => row.disEnrollRequestedDate,
            format: row => row.disEnrollRequestedDate,
            sortable: true,
        },
        {
            name: 'Member ID',
            selector: row => row.membershipNo,
            sortable: true,
        },
        {
            name: 'Main Membership ID',
            selector: row => row.mainMembershipNo,
            sortable: true,
        },
        {
            name: 'Member Name',
            selector: row => row.memberName,
            sortable: false,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: false,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile,
            sortable: false,
        },
        {
            name: 'Program',
            selector: row => row.carePrograms,
            sortable: false,
        },
        {
            name: 'Condition',
            selector: row => row.chronicConditions,
            sortable: false,
        },
        {
            name: 'Enrollment Date',
            selector: row => row.enrollmentTime,
            format: row => row.enrollmentTime,
            sortable: true,
        },
        {
            name: 'CareNavigator Name',
            selector: row => row.careName,
            sortable: false,
        },
        {
            name: 'Supervisor',
            selector: row => row.supervisorName,
            sortable: false,
        },
        {
            name: 'Reason',
            selector: row => row.reason,
            sortable: false,
        },
        {
            name: 'CareNavigator Remarks',
            selector: row => row.disEnrollmentNote,
            sortable: false,
            cell: row => (
                <div dangerouslySetInnerHTML={{ __html: row.disEnrollmentNote }} />
            ),
        },
        {
            name: 'Action Taken By',
            selector: row => row.approvedBy,
            sortable: false,
        },
        {
            name: 'Date Actioned',
            id: 'approvedDate',
            selector: row => row.approvedDate,
            format: row => row.approvedDate,
            sortable: false,
        },
        {
            name: 'Dis-EnrollmentType',
            id: 'disEnrollmentType',
            selector: row => row.disEnrollmentType,
            format: row => row.disEnrollmentType,
            sortable: false,
        },
        {
            name: 'CareCoordinator Remarks',
            id: 'aprrovedNote',
            selector: row => row.aprrovedNote,
            format: row => row.aprrovedNote,
            sortable: false,
        },
        {
            name: 'Re-enrolled By',
            sortable: false,
            selector: row => row.reenrolledBy
        },
        {
            name: 'Re-enrolled Date',
            sortable: false,
            selector: row => row.reenrollmentDate
        },
        {
            name: 'Re-enrollment Remarks',
            sortable: false,
            selector: row => row.reenrollmentRemarks
        }
    ];

    const openReenrollmentModal = async (userId) => {
        setIsNewLoading(true);
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchProgramConditionPathway(userId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    getCareNavigators()
                        .then((response) => {
                            setCareNavigatorsList(response.data.data.users);
                        });
                    setProgramConditionPathways(response.data.data.programs);
                    setFormData({
                        ...formData,
                        userId: userId,
                    });
                    setIsNewLoading(false);
                    setShowReEnrollmentModal(true);
                } else {
                    setIsNewLoading(false);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();

    }

    const getSlots = async (cnId) => {
        if (selectedDate) {

            setIsNewLoading(true);
            try {
                let response = await fetchAvailableAppointmentSlots(format(selectedDate, 'yyyy-MM-dd HH:mm'), cnId);
                if (response.data.code === 200) {
                    setTimeSlots(response?.data?.data);
                }

            } catch (error) {
                console.log(error);
            } finally {
                setIsNewLoading(false);
            }
        } else {
            alert('Please select both date and time.');
        }
    };

    const reenrollUser = async () => {
        setIsNewLoading(true);
        try {
            const request = axios.CancelToken.source();
            let ct = {
                cancelToken: request.token
            }
            await reEnrollMember(formData.userId, formData.cnId, formData.programId, formData.conditionId, formData.pathwayId, formData.callDate, formData.callTime, formData.pathwayMonth, formData.remarks, ct)
                .then((response) => {
                    setIsNewLoading(false);
                    if (response.data.code === 200) {
                        fetchNewMembersDisenrollList();
                        setShowReEnrollmentModal(false);
                        setFormData(formObj);
                        setIsNewLoading(false);
                    } else {
                        setReEnrollmentError(response.data.data.message);
                    }
                }).catch(function (error) {
                    console.log(error);
                });
            return () => request.cancel();
        } catch (error) {
            console.log(error);
            setIsNewLoading(false);
        }
    }


    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('');
        setSelectedCondition('');
    };

    const filteredData = newMembersDisenrollList.filter(user => {
        const matchesSearchQuery =
            user.memberName.toLowerCase().includes(searchQuery.toLowerCase()) || user.membershipNo == searchQuery;

        const matchesProgram = selectedProgram === 'All' || user.carePrograms.toLowerCase() === selectedProgram.toLowerCase();
        const matchesCondition = selectedCondition === 'All' || user.chronicConditions.toLowerCase() === selectedCondition.toLowerCase();
        return matchesSearchQuery && matchesProgram && matchesCondition;
    });

    const handleExport = (data) => {

        const modifiedData = data.map(item => {
            return {
                'Request Date': item.disEnrollRequestedDate,
                'Member ID': item.membershipNo,
                'Main Membership ID': item.mainMembershipNo,
                'Member Name': item.memberName,
                'Email': item.email,
                'Mobile No': item.mobile,
                'Program': item.carePrograms,
                'Condition': item.chronicConditions,
                'Enrollment Date': item.enrollmentTime,
                'CareNavigator Name': item.careName,
                'Supervisor Name': item.supervisorName,
                'Reason': item.reason,
                'CareNavigator Remarks': item.disEnrollmentNote,
                'Action Taken By': item.approvedBy,
                'Date Actioned': item.approvedDate,
                'Dis-EnrollmentType': item.disEnrollmentType,
                'CareCoordinator Remarks': item.aprrovedNote,
                'Re-enrolled By': item.reenrolledBy,
                'Re-enrollment Date': item.reenrollmentDate,
                'Re-enrollment Remarks': item.reenrollmentRemarks
            };
        });


        modifiedData.forEach(function (v) { delete v.disEnrollBy; delete v.logId; delete v.membershipNo; delete v.mobile; delete v.userId; });

        exportTableData("ReenrolledMembers", modifiedData);
    };

    console.log(formData);

    return (
        <>
            <LoaderLoader isLoading={isNewLoading} />
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md-4 d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search by Member ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">All Programs</option>
                            {programList.map(option => (
                                <option key={option.programId} value={option.programId}>{option.programName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md px-1'>
                        <select
                            value={selectedCondition}
                            onChange={e => setSelectedCondition(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="">All Conditions</option>
                            {conditionList.map(option => (
                                <option key={option.conditionId} value={option.conditionId}>{option.conditionName}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={() => setDownload('Y')} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            {
                isNewLoading ? <CustomLoader /> :
                    <BackendDrivenDataTable
                        data={data}
                        loading={isNewLoading}
                        totalRows={totalRows}
                        page={page}
                        perPage={perPage}
                        sortColumn={sortColumn}
                        sortDirection={sortDirection}
                        filterText={searchQuery}
                        columns={columns}
                        fetchTableData={fetchNewMembersDisenrollList}
                        setPage={setPage}
                        setSortColumn={setSortColumn}
                        setSortDirection={setSortDirection}
                        setPerPage={setPerPage}
                    />
            }
        </>
    );
}

export default ReenrolledTable;
