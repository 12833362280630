import React, { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './MemberServices.css';
import { metricsInsightsFreetextData } from "../../../../services/cn/Service";


const MetricsFreeTextInsights = forwardRef((props, ref) => {
    const qsParams = useParams();
    const userId = props.userId;
    const [logs, setLogs] = useState([]);

    useEffect(() => {
        getMetricsInsightsFreetextData();
    }, [props.metricsId, props.userId])

    const getMetricsInsightsFreetextData = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await metricsInsightsFreetextData(props.userId, props.metricsId, ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setLogs(response.data.data.logs);
                }
            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }


    return (
        <>
            {
                logs.length > 0 &&
                <div className='col-md-12 mt-3'>
                    <div style={{ marginLeft: '10px', marginRight: '10px' }}>
                        <div className='card-body'>
                            <div className='card-text'>
                                <div className='row align-items-center' >
                                    <div className='col'>
                                        <h5 className='card-title'>{props.metricsName}</h5>
                                        <div className='table-responsive mt-2' style={{ maxHeight: "380px", overflowX: "hidden" }}>
                                            <table className="table table-striped table-bordered mb-0">
                                                <thead style={{ position: "sticky", top: "0" }}>
                                                    <tr>
                                                        <th scope="col" style={{ width: "15%" }}>Date</th>
                                                        <th scope="col">Remarks</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        logs.map((item, index) => {
                                                            return <>
                                                                <tr style={{ verticalAlign: 'middle' }}>
                                                                    <td className='bg-td-green'>{item.paramDate}</td>
                                                                    <td className='bg-td-green'>{item.paramVal}</td>
                                                                </tr>
                                                            </>
                                                        })
                                                    }

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
});

export default MetricsFreeTextInsights