import React, { useState, useMemo, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import NewCampaignModal from "./TableModal";
import { fetchCampaignCreatedList, fetchSearchCampaignCreatedList } from "../../services/mtp/Service";
import CalendarDatePicker from "../componenents/CalendarDatePicker";
import moment from "moment";
import { formatDate, LoaderLoader } from "../utils/CommonLibrary";


const Notification = () => {
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  // const [startDate, endDate] = dateRange;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [campaignList, setCampaignList] = useState([]);
  const [startDate, setStartDate] = useState(moment().subtract(0, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [sortConfig, setSortConfig] = useState({
    key: null,
    direction: 'asc'
  });
  const [recordMessage, setRecordMessage] = useState('');

  //pagination
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [currentRecordsRange, setCurrentRecordsRange] = useState('');
  const [totalRows, setTotalRows] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  const goToFirstPage = () => setPage(1);
  const goToPreviousPage = () => setPage(prev => Math.max(prev - 1, 1));
  const goToNextPage = () => setPage(prev => Math.min(prev + 1, totalPages));
  const goToLastPage = () => setPage(totalPages);
  const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const pages = Math.ceil(totalRows / perPage);
    setTotalPages(pages);
}, [perPage,page,totalRows,startDate,endDate]);

useEffect(() => {
    const start = (page - 1) * perPage + 1;
    const end = Math.min(page * perPage, totalRows);
    if (start > 0 && end > 0) {
        setCurrentRecordsRange(`${start}-${end}`);
    }
    fetchCampaignList();
}, [page, perPage, totalRows,startDate,endDate]);

  //Fetch campaign list data
  const fetchCampaignList = async () =>{
    try{
      setIsLoading(true); // Show loader at the start of the fetch
      let response;
      if(searchTerm){
         response = await fetchSearchCampaignCreatedList(page,perPage,formatDate(startDate),formatDate(endDate),searchTerm);
      }else{
         response = await fetchCampaignCreatedList(page,perPage,formatDate(startDate),formatDate(endDate));
        console.log("response.data.data1111",response.data.totalRows); 
      }
      if(response.data.code == 200){  
        setCampaignList(response.data.data);
        setTotalRows(response.data.totalRows);
        setRecordMessage('');
      }else{
        setCampaignList([]);
        setTotalRows(0);
        setRecordMessage(response.data.data.message);
      }
    }catch(error){
      console.log(error);
    }finally {
      setIsLoading(false); // Ensure loader hides in both success and error cases
    }
  }

  const handleClear = () =>{
    setSelectedCategory("");
    setSelectedState("");
    setSearchTerm("");
  }
  // Dummy data for the One Time tab
  /*const campaignData = [
    {
      id: 1,
      name: "no user story 08",
      createdBy: "Parwage Alam",
      category: "MARKETING",
      state: "Completed",
      attempted: 67,
      sent: 65,
      delivered: 61,
      read: 44,
      replied: 1
    },
    {
      id: 2,
      name: "Sunny 08",
      createdBy: "Parwage Alam",
      category: "MARKETING",
      state: "Completed",
      attempted: 1555,
      sent: 1346,
      delivered: 1346,
      read: 915,
      replied: 0
    }
  ];*/
   

  const filteredData = campaignList.filter(item => {
    const matchesSearch = (
      item.campaignName?.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) ||
      item.createdBy?.toLowerCase().trim().includes(searchTerm.toLowerCase().trim()) ||
      item.category?.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
    );
    const matchesCategory = !selectedCategory || item.category === selectedCategory;
    const matchesState= !selectedState || item.scheduleStatus === selectedState;
    return matchesSearch && matchesCategory && matchesState;
  });

  

  const handleCampaignClick = (campaignId) => {
    navigate(`/campaign-details/${campaignId}`);
  };

  const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
    <div
      className="tw-min-w-[200px] tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md tw-text-gray-600 tw-cursor-pointer hover:tw-bg-gray-50"
      onClick={onClick}
      ref={ref}
    >
      {value}
    </div>
  ));

  const handleDateChange = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };


  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key) {
      direction = sortConfig.direction === 'asc' ? 'desc' : 'asc';
    }
    setSortConfig({ key, direction });
  };

  const getSortedData = (data) => {
    if (!sortConfig.key) return data;

    return [...data].sort((a, b) => {
      if (a[sortConfig.key] < b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? -1 : 1;
      }
      if (a[sortConfig.key] > b[sortConfig.key]) {
        return sortConfig.direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  };

  const getSortIcon = (columnName) => {
    if (sortConfig.key !== columnName) {
      return <i className="fa fa-sort tw-ml-2 tw-text-gray-400"></i>;
    }
    return sortConfig.direction === 'asc' ? 
      <i className="fa fa-sort-up tw-ml-2"></i> : 
      <i className="fa fa-sort-down tw-ml-2"></i>;
  };

  // Get sorted and filtered data
  const sortedAndFilteredData = getSortedData(filteredData);

  const handleSearch =async()=>{
    try{
        if(searchTerm.length >= 3){
          setIsLoading(true); // Show loader at the start of the fetch
          let response = await fetchSearchCampaignCreatedList(page,perPage,formatDate(startDate),formatDate(endDate),searchTerm);
          console.log("response.data.search",response.data.totalRows); 
          if(response.data.code == 200){  
            setCampaignList(response.data.data);
            setTotalRows(response.data.totalRows);
            setRecordMessage('');
          }else{
            setCampaignList([]);
            setTotalRows(0);
            //setRecordMessage(response.data.data.message);
          }
        }
      }catch(error){
        console.log(error);
      }finally {
        setIsLoading(false); // Ensure loader hides in both success and error cases
      }
  }

  return (
    <div className="container-fluid tw-mb-16">
      {/* <div className="tw-flex tw-flex-col sm:tw-flex-row tw-justify-between tw-items-center tw-mb-4">
        <div className="tw-text-2xl sm:tw-text-3xl tw-font-semibold tw-flex tw-items-center tw-gap-4 sm:tw-gap-6 sm:tw-mb-0">
          
          <h4 className="tw-text-gray-500">Campaign Manager</h4>
        </div>
      </div> */}
       <div className="tw-sticky tw-top-12 tw-mb-2 tw-bg-[#f1f6fc] tw-z-10 tw-border-b">
  <div className="tw-w-full tw-pb-3">
    <div className="tw-flex tw-items-center tw-justify-between">
      <h4 className="tw-text-gray-600 tw-m-0 tw-font- tw-font-semibold">Campaign Manager</h4>
      <div className="tw-w-[300px]">
        <CalendarDatePicker
          startDate={startDate}
          endDate={endDate}
          onDateChange={handleDateChange}
        />
      </div>
    </div>
  </div>
</div>
      <div class="row align-items-center tw-bg-white tw-p-4 tw-mx-0.5 tw-rounded-xl tw-mb-4">
        <div class="col-md-auto">
          <img
            src="https://appcdn.goqii.com/storeimg/39537_1730179155.png"
            alt="Invited Members icon"
            style={{ height: "140px" }}
          />
        </div>
        <div class="col-md">
          <p class="mb-0">
          The Campaign Manager is a versatile tool within HealthEngage, designed to empower users to create and manage large-scale outreach campaigns. With this tool, users can effectively connect with members by selecting target audiences, choosing specific WhatsApp messages, and scheduling campaigns for optimal engagement.
          </p>
        </div>
      </div>
      
      <div className="tw-w-full">
      {/* Filters Row */}
      <div className="tw-flex tw-flex-row tw-items-center tw-gap-4 tw-mb-6">
<div className="form-group" style={{width: "100%"}}>
                            <input
                                type="text"
                                placeholder="Search By Campaign Name or Creator"
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="form-control input-field-ui ps-5"
                                onKeyDown={handleSearch} 
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
        <select
          className="form-select input-field-ui tw-max-w-64"
          value={selectedState}
          onChange={(e) => setSelectedState(e.target.value)}
        >
          <option value="">--Select Status--</option>
          <option value="Completed">Completed</option>
          <option value="Inprogress">In-Progress</option>
          <option value="Scheduled">Scheduled</option>
        </select>
        <select
          className="form-select input-field-ui tw-max-w-64"
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          <option value="">--Select Category--</option>
          <option value="marketing">Marketing</option>
          <option value="utility">Utility</option>
        </select>
        {/* <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            customInput={<CustomInput />}
            dateFormat="dd MMM, yyyy"
            isClearable={true}
            className="tw-min-w-[200px]"
          /> */}


      <button
        className=" add-new-program-btn tw-min-w-24"
        onClick={handleClear}
      >
         Clear
      </button>
      <button
        onClick={() => navigate('/create-campaign-whatsapp')}
        className=" add-new-program-btn tw-min-w-48"
      >
        + New Campaign
      </button>
      </div>

      {/* Table */}
      <div className="tw-overflow-x-auto tw-rounded-xl">
        <table className="tw-w-full tw-min-w-max">
        <thead>
            <tr className="tw-bg-[#D4E6EF]">
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('campaignName')}
              >
                <div className="tw-flex tw-items-center">
                  Name {getSortIcon('campaignName')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('createdBy')}
              >
                <div className="tw-flex tw-items-center">
                  Created By {getSortIcon('createdBy')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('category')}
              >
                <div className="tw-flex tw-items-center">
                  Category {getSortIcon('category')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('scheduleStatus')}
              >
                <div className="tw-flex tw-items-center">
                  Status {getSortIcon('scheduleStatus')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('scheduleTimeDate')}
              >
                <div className="tw-flex tw-items-center">
                  Scheduled Date {getSortIcon('scheduleTimeDate')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('totalUserAttempted')}
              >
                <div className="tw-flex tw-items-center">
                  Attempted {getSortIcon('totalUserAttempted')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('totalUserSent')}
              >
                <div className="tw-flex tw-items-center">
                  Sent {getSortIcon('totalUserSent')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('totalUserDelivered')}
              >
                <div className="tw-flex tw-items-center">
                  Delivered {getSortIcon('totalUserDelivered')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('totalUserRead')}
              >
                <div className="tw-flex tw-items-center">
                  Read {getSortIcon('totalUserRead')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('totalUserUndelivered')}
              >
                <div className="tw-flex tw-items-center">
                  Undelivered {getSortIcon('totalUserUndelivered')}
                </div>
              </th>
              <th 
                className="tw-px-5 tw-py-3 tw-text-left tw-text-sm tw-font-medium tw-capitalize tw-cursor-pointer hover:tw-bg-[#c4dae6] tw-transition-colors"
                onClick={() => handleSort('createdTime')}
              >
                <div className="tw-flex tw-items-center">
                  Campaign Create Date {getSortIcon('createdTime')}
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="tw-bg-white tw-divide-y tw-divide-gray-200">
            {sortedAndFilteredData.length > 0 ? (
              <>
              <LoaderLoader isLoading={isLoading} />
            {sortedAndFilteredData.map((item, index) => (
              <tr
                key={item.id}
                className={`${index % 2 === 0 ? 'tw-bg-white' : 'tw-bg-gray-100'} tw-cursor-pointer tw-text-xs`}
                onClick={() => handleCampaignClick(item.recordId)}
              >
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.campaignName}</td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.createdBy}</td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap tw-capitalize">{item.category}</td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">
                  <span className={`tw-inline-flex tw-text-sm tw-leading-5 ${item.scheduleStatus === "Scheduled" ? 'tw-text-sky-600' : item.scheduleStatus === "Completed" ? 'tw-text-green-500' : 'tw-text-yellow-500'}`}>
                    {item.scheduleStatus === "Inprogress" ? "In-Progress" : item.scheduleStatus}
                  </span>
                </td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.scheduleTimeDate}</td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.totalUserAttempted}</td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.totalUserSent}</td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.totalUserDelivered}</td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.totalUserRead}</td>
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.totalUserUndelivered}</td>
                {/* <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">{`${item.totalUserDelivered} (${Math.round((item.totalUserDelivered / item.totalUserAttempted) * 100)}%)`}</td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">{`${item.totalUserRead} (${Math.round((item.totalUserRead / item.totalUserAttempted) * 100)}%)`}</td>
                <td className="tw-px-6 tw-py-4 tw-whitespace-nowrap">{`${item.totalUserUndelivered} (${Math.round((item.totalUserUndelivered / item.totalUserAttempted) * 100)}%)`}</td> */}
                <td className="tw-px-5 tw-py-4 tw-whitespace-nowrap">{item.createdTime}</td>
              </tr>
            ))}
            </>
            ) : (<tr><td colSpan={11} className="tw-text-center tw-py-6 tw-text-gray-500">{recordMessage ? <>{recordMessage}</> : 'No Record found'}</td></tr>)}
          </tbody>
        </table>
      </div>
      {sortedAndFilteredData.length > 0 ? (
      <div className="pagination-container">
          <div className="rows-per-page">
            <label>Rows per page: </label>
            <select value={perPage} onChange={(e) => setPerPage(Number(e.target.value))}>
              <option value={10}>10</option>
              <option value={15}>15</option>
              <option value={20}>20</option>
              <option value={25}>25</option>
            </select>
          </div>
          {totalRows > 0 && (
            <>
          <div className="records-info">
          {currentRecordsRange && totalRows > 0 && `${currentRecordsRange} of ${totalRows} records`}
          </div>
         
          <div className="pagination-controls tw-mb-3.5">
          <button onClick={goToFirstPage} disabled={page === 1}>
                            <i className='bx bx-first-page'></i>
                        </button>
                        <button onClick={goToPreviousPage} disabled={page === 1}>
                            <i className='bx bx-chevron-left'></i>
                        </button>
                        <button onClick={goToNextPage} disabled={page === totalPages}>
                            <i className='bx bx-chevron-right'></i>
                        </button>
                        <button onClick={goToLastPage} disabled={page === totalPages}>
                            <i className='bx bx-last-page'></i>
                        </button>
          </div>
          </>
          )}
        </div>):null}
    </div>
      
      {/* <NewCampaignModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      /> */}
    </div>
  );
};

export default Notification;