import React, { useState, useEffect } from 'react'
import PageTip from '../../../../componenents/PageTip/PageTip'
import ManageUsers from '../../../../componenents/UserManagement/ManageUsers'
import { Link } from 'react-router-dom'
import { fetch_admin_user_data, fetch_role_data } from '../../../../../services/hcc/Service'


const MemberManagement = () => {
  const [data, setData] = useState([])
  const [columns, setColumns] = useState([])
  const [roleData, setRoleData] = useState([])
  const [editUrlPrefix, setEditUrlPrefix] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const fetchUserData = async () => {
    try {
      setIsLoading(true)
      const response = await fetch_admin_user_data()
      const columns = [
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Name', 
            selector: row => row.name,
            sortable: true,
        },
        {
            name: 'Role',
            selector: row => row.role,
            sortable: true,
        },
        {
            name: 'Last Login time',
            selector: row => row.lastLoginTime,
            sortable: true,
        },
       
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <span style={{ color: row.status === 'active' ? '#01CD88' : '#FF0000' }}>
                    {row.status}
                </span>
            ),
        },
        {
            name: 'Inactivated By',
            selector: row => row.inactivatedBy,
            sortable: true,
        },
        {
            name: 'Inactive Date',
            selector: row => row.inactiveDate,
            sortable: true,
        },
        {
            name: 'Edit',
            selector: row => row.action,
            sortable: false,
            cell: row => <Link to={`/edit-new-member-hcc/${row.adminId}`} style={{ fontSize: '20px'}} ><i className="bi bi-pen"></i></Link>,
        },
      ]
      setColumns(columns)
      if (response.data.code === 200) {
        setData(response.data.data || [])
      } else {
        throw new Error(`Failed to fetch data. Status: ${response.status}`)
      }
    } catch (error) {
      console.error('Error fetching user data:', error)
      // You might want to set some error state here to show to the user
      setData([])
      setRoleData([])
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    fetchUserData();
    fetchRoleData(); 
  }, [])


  // fetch role data
  const fetchRoleData = async () => {
    try {
      const response = await fetch_role_data();     
      if(response.data.code === 200) {
        setRoleData(response.data.data || [])
      }
    } catch (error) {
      console.error('Error fetching role data:', error)
    }
  }

  return (
    <div className="container-fluid">
        <div className="row align-items-center pb-3 sticky-top-bar">
          <div className="col-md"> 
              <h4 className="mb-0 content-main-title">User Management</h4>
          </div>
          <div className="col-md">
              <div className="row g-3 align-items-center">
                  {/* <div className="col-auto">
                      <h1 class="main-title mb-0">Total Users: {totalAdminUsersData}</h1>
                  </div> */}
              </div>
          </div>

          <div className="col-md text-end">
              <Link to="/add-new-member-hcc" className="btn btn-primary add-new-program-btn">
                  <i className="bi bi-person-plus-fill me-2"></i> Add New User
              </Link>
          </div>
        </div>


        <PageTip imgSrc="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/usermanagement.png" imgAlt="image" text="The User Management module allows admins to easily add or remove users of the apps and provide them with access to the application. This is essential for maintaining and controlling user access." />


        <div className="row align-items-center pb-3 mt-3">
            <div className='comntent-UserManagement mb-3'>
                <div className="row">
                    <div className="col-md-12">
                        <div className="table-responsive tableUserManagement">
                            <ManageUsers
                                data={data}
                                columns={columns}
                                roleData={roleData}
                                editUrlPrefix={editUrlPrefix}
                                isLoading={isLoading}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default MemberManagement
